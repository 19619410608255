.settings-billing__wrapper .Polaris-Frame {
    background-color: #ffffff;
    width: 100%;
}


.settings-billing {
    /* max-width: 500px; */
    margin: 0 auto;
}
.settings-billing__basic-info {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.settings-billing__section-label {
    width: 100%;
    background-color: #f3f4f7;
    padding: .3rem 1.5rem;
    color: #7e8f9f;
}

.settings-billing__section-title {
    text-transform: uppercase;
}

.settings-billing__icon{
    padding-top: 12px;
    margin-left: 30px;
    padding-bottom: 12px;
}

.settings-billing__display {
    border: none;
    font-size: 1em;
    text-align: right;
    margin-right: 2em;
    padding: 0.4rem;
    border: 1px solid transparent;
    border-top: none;
    border-bottom: none;
    height: 3rem;
}

.settings-billing__input {
    border: 1px solid #7e8f9f;
    font-size: 1em;
    text-align: right;
    margin-right: 2em;
    padding: 0.4rem;
    border-radius: 3px;
    max-width: 30rem;
    height: 3rem;
}

/* .settings-billing__input-align {
    display: flex;
    justify-content: flex-end;
    max-width: 200px;
} */

/* .settings-billing--input{
    margin-top: 8px;
    padding-bottom: 12px;
    width: 200px;
    margin-left: 30px;
} */

.settings-billing__advertiser-address {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.settings-billing__input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1.5rem;
    border-bottom: 1px solid #EFEFEF;
}

.settings-billing__edit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.settings-billing__edit-text {
    font-size: 1.5rem;
    color: #ff2754;
    margin-left: 1rem;
    cursor: pointer;
    width: 3rem;
    text-align: center;
}

.settings-billing__save {
    font-size: .75rem;
    color: #fff;
    cursor: pointer;
    background: #ff2754;
    border-radius: 999px;
    width: 3rem;
}

@media screen and (max-width: 599px) {

    .settings-billing__input-row {
        padding: 1rem 1.25rem;
    }

    .settings-billing__section-title {
        font-size: 1.2rem;
    }

    .settings-billing--input-label {
        font-size: 1.2rem;
    }

    .settings-billing__input {
        font-size: 1.2rem;
    }

    .settings-billing__display {
        font-size: 1.2rem;
        margin-right: .5rem;
    }

    .settings-billing__edit-text {
        font-size: 1.2rem;
    }

    .settings-billing__save {
        width: 2rem;
    }
}