.offer-details__sidebar .smartlinks-rank h2{
    padding-left: 2.9rem;
}
.offer-details__sidebar .smartlinks-rank h2::before{
    background: url(../../assets/smartlinks-icon-black.svg) no-repeat;
    width: 18px;
}

.offer-details__sidebar .smartlinks-rank p:last-child{
    margin-bottom: 0;
}

.offer-details__sidebar .smartlinks-rank button{
    background-repeat: no-repeat;
    background-position: 1rem .5rem;
    background-size: 1.6rem 1.6rem;
    padding-left: calc(1rem + 1.6rem + 1rem);
    font-weight: bold;
}

.offer-details__sidebar .smartlinks-rank .move{
    background-image: url(../../assets/up-arrow-in-circle.svg);
}

.offer-details__sidebar .smartlinks-rank .add{
    background-image: url(../../assets/plus-in-circle.svg);
}

.add-to-smartlinks-modal-paragraph{
    margin-bottom: 2rem;
}