.AchievementNotificationItem {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    padding: 1rem 0;
}

.AchievementNotificationItem[data-unread="true"]{
    background: #f2f3f7;
    border-left: 4px solid #b3b8ca;
    padding-left: 1.4rem;
}

.badge-background {
    margin-right: 1rem;
    border-radius: 5px;
    width: 5rem;
    height: 5rem;
    padding: .8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AchievementNotificationItem .badge-background img {
    width: 4rem;
    height: 4rem;
}

.AchievementNotificationItem .badge-title {
    font-weight: bold;
}

.AchievementNotificationItem .date {
    color: rgba(0, 0, 0, .6);
    display: block;
    font-size: 1.2rem;
}

.social {
    background: #29ABE2;
}
.money {
    background: #39B54A;
}
.offer {
    background: #FF2754;
}
.complete {
    background: #7445bd;
}