.settings-general__tabs-mobile {
    display: none;
}

.settings-general__tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    border-bottom: 2px solid #EFEFEF;
}

.settings__tab {
    padding: 2rem 0;
    width: 20%;
    /* max-width: 12rem; */

    margin-right: 1rem;

    text-align: center;
    color: #BEBEBE;

    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    font-size: 1.25rem;
}

.settings-general__tab--active {
    color: #000000;
    border-bottom: 4px solid #ff2754;
}

@media screen and (max-width: 599px) {
    .settings-general__tabs {
        display: none;
    }

    .settings-general__tabs-dropdown {
        height: 30vh;
        transition: all 300ms ease-in-out;
    }

    .settings-general__tabs-collapsed {
        height: 0;
        transition: all 300ms ease-in-out;
    }

    .settings-general__tab-current {
        width: 100%;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 4px solid #ff2754;
    }

    .settings-general__tab-label {
        color: #000;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer;
        font-size: 1.25rem;
    }

    .settings-general__dropdown-arrow {
        height: 10px;
        transition: all 300ms ease-in-out;
    }

    .settings-general__dropdown-up {
        height: 10px;
        transform: rotate(180deg);
        transition: all 300ms ease-in-out;
    }

    .settings__tab {
        width: 100%;
        padding: 1rem;
        text-align: left;
        border-bottom: 1px solid #EFEFEF;
    }

    .settings-general__tabs-mobile {
        display: flex;
        flex-direction: column;
    }

    .settings__tab:last-child {
        border-bottom: 1px solid transparent;
    }

    /* .settings-general__tab--active {
        background-color: #ff2754;
        color: #fff;
    } */
}