/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

 .youtube-integration__account-select {
   width: 100%;
   padding: 2rem;
   /* margin-bottom: 3rem; */
}

.youtube-integration__button {
   color: #fff;
   background: #ff0000 url(../../../assets/youtube_white.svg) no-repeat 1rem center;
   background-size: auto calc(100% - 1.8rem);
   cursor: pointer;
   padding: 0.7rem 1rem .7rem 4.8rem;
   margin: 2rem 0 1rem 0;
   font-weight: bold;
   border-radius: 3px;
   display: flex;
   align-items: center;
   justify-content: center;
   border: none;
   position: relative;
}

.youtube-integration__button::after {
   content: '';
   position: absolute;
   top: .6rem;
   left: 3.7rem;
   width: 1px;
   height: calc(100% - 1.2rem);
   background: rgba(255, 255, 255, .3);
}

.youtube-integration__list-accounts {
   padding-left: 0;
   list-style: none;
}

.youtube-integration__list-accounts li{
   display: flex;
   align-items: center;
   padding: 1.5rem 0;
   border-bottom: 1px solid #E0E0E0;
}

.youtube-integration__list-accounts img{
   width: 40px;
   height: 40px;
   border-radius: 100%;
   margin-right: 1.2rem;
}

.youtube-integration__list-accounts .account-info{
   display: flex;
   align-items: center;
   font-size: 1.3rem;
   color: #637381;
   line-height: 1.4em;
   margin-right: auto;
}

.youtube-integration__list-accounts .account-info b{
   display: block;
   font-size: 1.15em;
   color: #212B36;
}

.youtube-integration__list-accounts .account-info span > .Polaris-Badge{
   font-size: 1rem;
   line-height: 1.3rem;
   padding: .1rem 1rem;
}

.youtube-integration__list-accounts .account-info + button{
   margin-left: 3rem;
}

.youtube-integration__list-accounts button{
   white-space: nowrap;
   font-size: 1.2rem;
   font-weight: bold;
   padding-left: 3.7rem;
}

.youtube-integration__list-accounts button.unlink{
   background: #000 url(../../../assets/unlink-white.svg) no-repeat 1.5rem .5rem;
   border-color: #000;
}

.youtube-integration__list-accounts button.edit{
   background:#FD2856 url(../../../assets/edit-white.svg) no-repeat 1.5rem .5rem;
   background-size: 12px 12px;
   padding-left: 3.6rem;
}

.youtube-integration__list-account {
   display: flex;
   justify-content: space-between;
}

.youtube-integration__header {
   font-weight: bold;
   font-size: 1.75rem;
   margin-bottom: 0.6rem;
}

.youtube-integration__top-videos{
   background: #F2F2F2;
   padding: 2rem;
}

.youtube-integration__top-videos .channel-select{
   display: flex;
   border-bottom: 1px solid #C8C8C8;
   padding-bottom: 2rem;
   margin-bottom: 2rem;
}

.youtube-integration__top-videos .channel-select img{
   width: 4rem;
   height: 4rem;
   margin-right: 1rem;
   border-radius: 100%;
   vertical-align: middle;
}

.youtube-integration__top-videos .channel-select h3{
   font-weight: bold;
   font-size: 1.7rem;
   margin-bottom: 0.3rem;
}

.youtube-integration__top-videos .channel-select h3 > div{
   margin-left: 0.4rem;
   display: inline-block;
}

.youtube-integration__top-videos .channel-select .Polaris-Select__Backdrop,
.youtube-integration__top-videos .channel-select .Polaris-Icon{
   display: none;
}

.youtube-integration__top-videos .channel-select .Polaris-Select__Content{
   background: none;
   border: none;
   padding: 0;
   min-height: auto;
   font-size: .95em;
}

.youtube-integration__top-videos .channel-select .Polaris-Select__SelectedOption{
   font-weight: bold;
}

.youtube-integration__top-videos .channel-select .Polaris-Select__Icon{
   position: relative;
   width: 2rem;
   height: 2rem;
}

.youtube-integration__top-videos .channel-select .Polaris-Select__Icon::after{
   content: '';
   position: absolute;
   border-right: 2px solid #4d4d4d;
   border-bottom: 2px solid #4d4d4d;
   width: 8px;
   height: 8px;
   top: calc(50% - 5px);
   left: calc(50% - 3px);
   transform: rotate(45deg);
}

.youtube-integration__top-videos h4{
   font-weight: bold;
   font-size: 1.6rem;
   margin-bottom: 0.3rem;
}

.youtube-integration__top-videos ul{
   padding-left: 0;
   list-style: none;
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   column-gap: 1.5rem;
   row-gap: 1.5rem;
   font-size: 1.1rem;
   margin-bottom: 3rem;
}

.youtube-integration__top-videos li:last-child{
   display: none;
}

.youtube-integration__top-videos ul img{
   width: 100%;
}

.youtube-integration__top-videos ul a{
   color: var(--p-text);
   text-decoration: none;
}

.youtube-integration__top-videos ul b{
   display: block;
   line-height: 1.3em;
   max-height: calc(1.3em * 2);
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   font-size: 1.2em;
}

.youtube-integration__revoke-message{
   margin-bottom: 2rem;
}

@media screen and (max-width: 890px) {
   .youtube-integration__list-accounts li{
      flex-wrap: wrap;
   }
   .youtube-integration__list-accounts .account-info{
      width: 100%;
      margin-bottom: 0.5rem;
   }
   .youtube-integration__list-accounts button.unlink{
      margin-left: 5.2rem;
   }
}

@media screen and (max-width: 780px) and (min-width: 600px), 
       screen and (max-width: 540px) {
   .youtube-integration__top-videos ul{
      grid-template-columns: repeat(2, 1fr);
   }

   .youtube-integration__top-videos li:last-child{
      display: block;
   }
}

@media screen and (max-width: 599px) {
   .affiliate-social__section-title {
      margin-left: 2rem;
   }
}

@media screen and (max-width: 370px) {
   .youtube-integration__top-videos ul{
      grid-template-columns: repeat(1, 1fr);
   }

   .youtube-integration__top-videos li:last-child{
      display: none;
   }
}