.Polaris-ProgressBar__Indicator {
    background-color:#fd2954 !important;
}

/* #F8333C, #E3170A , #F85A3E, #FF7733 possible toast error
#44AF69, #A9E5BB, #CBE896 possible toast succes  */
/* .Polaris-Frame__Main{
   background-color:white !important;
} */

.thankyou-wrapper {
   margin: 50px;
}

.thankyou-section {
   height: 500px;
   width: 900px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   background-color: #FCEBDB;
}

.thankyou-message {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 50%;
}

.thankyou-image {
   height: 500px;
}

.thankyou-btn {
   margin: 20px;
}

.thankyou-message-container {
   font-size: 20px;
   line-height: 32px;
   font-weight: 400;
   text-align: center;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 25px;
}

.navigation_buttons {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   padding: 15px;
}

.shopInfo {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
}

.edit-btn {
 display: flex;
 flex-direction: row;
 justify-content: flex-end;
}



.Onboarding__Card {
   margin-top: 3rem;
   width: 100%;
   background-color: white;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 2rem;
   box-shadow: rgba(0, 0, 0, 0.1) 0rem 1rem 2rem;
}

.Onboarding__Llama {
   height: 150px;
   width: auto;
}

.Onboarding__StepTracker {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
   padding: 1rem 2rem;
   color: #939393;
}

.hello-llama-drop-zone {
   width: 500px;
   padding: 25px;
}

.Onboarding__Header {
   font-size: 3rem;
   line-height: 1.1em;
   margin-bottom: 1.4rem;
   font-weight: bold;
   text-align: center;
}

.Onboarding__Paragraph {
   font-size: 2rem;
   color: #404040;
   text-align: center;
}

.Onboarding__SubParGender-Wrapper {
   max-width: 500px;
   text-align: center;
}

.Onboarding__SubParagraph {
   font-size: 1.2rem;
   line-height: 1.5rem;
   color: #404040;
}

a.Onboarding__MainCTA,
.Onboarding__MainCTA a {
   background-color: #ff2754;
   display: block;
   text-decoration: none;
   color: #ffffff;
   text-align: center;
   padding: 2rem 4rem;
   border-radius: 50px;
   font-size: 25px;
   font-weight: bold;
   cursor: pointer;
   margin: 4rem 0rem 1rem;
}

.Onboarding__SocialWrapper {
   margin: 2rem 0 0 0;
}

.Onboarding__SocialWrapper .affiliate-social__social-platform{
   max-width: 570px;
}

.hello-llama-skip {
   text-decoration: underline;
   color: #101010;
   text-align: center;
   padding: 2rem 4rem;
   font-size: 12px;
   font-weight: 100;
   cursor: pointer;
}

.step2-details {
   background-color: #f4f6f8;
   display: block;
   height: 550px;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.hello-llama-details-title {
   font-size: 20px;
   font-weight: 500;
   text-align: center;
   padding: 5rem 0;
}

.step2-example-image {
   width: 70%;
   height: 250px;

}




.Onboarding__BackButton{
   height: 25px;
   width: auto;
   transform: rotate(180deg);
   cursor: pointer;
}

.Onboarding__Name{
   margin-top: 3rem;
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   column-gap: 2rem;
   width: 100%;
   max-width: 500px;
}

.Onboarding__CategoriesChoiceList{
   list-style: none;
   padding-left: 0;
   margin-top: 3rem;
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   column-gap: 3rem;
}

.Onboarding__CategoriesChoiceList[data-loading="true"]{
   margin-left: 60px;
}

.Onboarding__CategoriesChoiceList .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
   width: 180px;
   margin-bottom: 20px;
   margin-right: 40px;
   position: relative;
}
.Onboarding__CategoriesChoiceList .Polaris-SkeletonBodyText__SkeletonBodyTextContainer:before{
   content: '';
   position: absolute;
   width: 20px;
   height: 20px;
   left: -30px;
   top: -5px;
   background-color: #dfe3e8;
   animation: polaris-SkeletonShimmerAnimation 800ms linear infinite alternate;
}

.Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop {
   background: #fd2954 !important;
}

.Polaris-Checkbox__Icon svg {
   fill: #fd2954 !important;
}




.Onboarding__GenderChoiceList .Polaris-ChoiceList__Choices {
   display: flex;
   justify-content: center;
   /* display: none; */
}

.Onboarding__GenderChoiceList .Polaris-Choice {
   align-items: center;
}


.Onboarding__GenderChoiceList .Polaris-ChoiceList__Choices li {
   width: 166px;
   display: flex;
   justify-content: center;
   align-items: flex-start;
   /* display: flex;
   align-items: center; */
   /* display: none; */
}

.Onboarding__GenderChoiceList .Polaris-RadioButton__Icon {
   background: #33125e;
   width: 60%;
   height: 60%;
   /* display: none; */
}

.Onboarding__GenderChoiceList .Polaris-Choice__Control {
   width: 2rem;
   height: 2rem;
   /* display: none; */
}

.Onboarding__GenderChoiceList .Polaris-ChoiceList {
   /* display: none; */
}

.Onboarding__GenderChoiceList .Polaris-Choice__Label {
   font-size: 2rem;
   line-height: 2.8rem;
   font-weight: bold;
   /* display: none; */
}

.Onboarding__GenderChoiceList .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop {
   /* display: none; */
}

.Onboarding__GenderLabels {
   display: flex;
   justify-content: center;
   margin: 2rem 0;
}

.Onboarding__GenderLabel {
   width: 166px;
   height: 166px;
   padding: 1.5rem;
   border-radius: 6px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   font-size: 2rem;
   line-height: 2.8rem;
   font-weight: bold;
   cursor: pointer;
}

.Onboarding__GenderImage {
   margin: 0 0 1.5rem 0;
}

.Onboarding__GenderLabel--active {
   background-color: #f0f4fa;
   border: 1px solid #c4c4c4;
}


.Onboarding__AgeChoiceList {
   max-width: 500px;
   margin: 1rem auto 0;
}

 .Onboarding__AgeChoiceList .Polaris-ChoiceList__Choices {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* margin-left: 20%;
    margin-bottom: 25px; */
 }

 .Onboarding__AgeChoiceList li {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 2rem;
}

.Onboarding__AgeChoiceList .Polaris-Choice__Label {
   font-size: 1.75rem;
   min-width: 48px;
   display: flex;
   align-items: center;
}

.Onboarding__AgeChoiceList .Polaris-Icon {
    width: 3rem;
    height: 3rem;
}

 .Onboarding__AgeChoiceList .Polaris-RadioButton__Icon {
    width: 60%;
    height: 60%;
    background: #33125e;
}

.Onboarding__AgeChoiceList .Polaris-Choice__Control {
    width: 2.6rem;
    height: 2.6rem;
}

 .Onboarding__AgeChoiceList .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop {
    background: #33125e;
    /* background: rgb( 255, 167, 43); */
 }

.template-download {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   margin: 10px;
}

.template-download-icon {
   height: 50px;
   width: 50px;
   padding: 5px;
}

.template-download-item {
   display: flex;
   flex-direction: row;
   align-items: center;
   cursor: pointer;
}

.hello-llama-selection {
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   width: 80%;
   padding: 25px;
}

.hello-llama-selection-item {
   padding: 10px 25px;
   display: block;
   border: #dfe3e8;
   border-style: solid;
   border-width: 0.5px;
   font-weight: 400;
   font-size: 18px;
}

.hello-llama-selection-item .Polaris-Choice__Control {
   width: 2.7rem;
   height: 2.7rem;
}

.hello-llama-selection-item .Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop {
   background: #fd2954;
}

.hello-llama-selection-item .Polaris-RadioButton__Icon {
   background: #fd2954;
   height: 60%;
   width: 60%;
}

.hello-llama-selection-item .Polaris-Choice__Label {
   line-height: 3rem;
   font-size: 2rem;
   font-weight: 300;
}

.hello-llama-text-chat-wrapper {
   padding: 0 0 25px 0;
}

.template-side-right {
   position: relative;
   left: 50%;
   bottom: 0;
}

.template-side-left {
   position: relative;
   right: 45%;
   bottom: 0;
}

.hello-llama-hide {
   visibility: hidden;
}

.hello-llama-dispNone {
  display: none;
}

.hello-llama-show {
   visibility: visible;
}

.hello-llama-text-chat-container {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: flex-end;
}

.hello-llama-text-chat-icon {
   height: 50px;
   width: 50px;
}

.hello-llama-text-chat-background {
   display: flex;
   flex-basis: row;
   justify-content: center;
   background-color: #d8d8d8;
   max-width: 350px;
   height: 60px;
   border-radius: 15px 15px 5px 5px;
}

.hello-llama-text-chat-message {
   font-size: 12px;
   font-weight: 100;
   padding: 10px;
   text-align: left;
}

.hello-llama-countrySelect {
    padding: 25px;
}

.hello-llama-countrySelect .Polaris-Select {
    width: 200px;
}

.hello-llama__payment-wrapper {
   margin: 1.5rem 0;
}

.Onboarding__PaypalWrapper {
   width: 430px;
   padding: 2rem;
   border: 3px solid #EBE8ED;
   border-radius: 5px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 3rem;
}


/* .Polaris-Frame__Content {
   height: 100vh;
}

.Polaris-Frame {
   height: 100vh;
} */

.onboarding__wrapper .Polaris-Frame {
   height: 100vh;
}

.onboarding__wrapper .Polaris-Layout .Polaris-Frame{
   height: auto;
}

.Onboarding__DropZone {
   margin: 1rem 0;
}

.Onboarding__DropZone .Polaris-DropZone__Container{
   width: 200px;
}

@media screen and (max-width: 860px){
   .Onboarding__CategoriesChoiceList{
      grid-template-columns: repeat(2, 1fr);
   }
}

@media screen and (max-width: 799px) {
   .Onboarding__Header {
      font-size: 2.25rem;
      margin-bottom: 0.6rem;
   }

   .Onboarding__Paragraph {
      font-size: 1.75rem;
   }

   .Onboarding__CategoriesChoiceList .Polaris-ChoiceList__Choices {
      margin: 1rem;
   }
}


@media screen and (max-width: 599px) {

   .Onboarding__Card {
      box-shadow: none;
   }

   .Onboarding__Llama {
      height: 100px;
   }

   .Onboarding__Header {
      font-size: 1.75rem;
   }

   .Onboarding__Paragraph {
      font-size: 1.25rem;
      line-height: 1.5rem;
      text-align: center;
   }

   .Onboarding__MainCTA {
      font-size: 2rem;
      padding: 1.25rem 3rem;
   }

   .Polaris-Layout {
      margin-left: 0 !important;
      margin-top: 2rem;
   }

   .Onboarding__BackButton {
      height: 16px;
   }

   .Onboarding__Name{
      display: block;
      width: 100%;
      max-width: 380px;
   }

   .Onboarding__Name > div:first-child{
      margin-bottom: 1rem;
   }

   .Onboarding__Name .Polaris-Label__Text{
      font-size: 1.2rem;
   }
   .Onboarding__Name .Polaris-TextField__Input{
      font-size: 1.3rem;
      width: 100%;
   }

   .Onboarding__CategoriesChoiceList .Polaris-Choice{
      padding: 0.2rem 0;
   }

   .Onboarding__CategoriesChoiceList .Polaris-Choice__Label{
      font-size: 1.3rem;
   }

   .Onboarding__SocialWrapper .affiliate-social__social-platform{
      max-width: none;
      min-width: 0;
   }

   .Onboarding__SocialWrapper .social__instagram{
      margin-top: 10px;
   }

   .Onboarding__SocialWrapper .social__profile__card{
      margin: 0 auto;
   }

   .hello-llama-text-chat-background {
      height: auto;
   }

   .Onboarding__GenderLabels {
      margin: 0.6rem 0;
   }

   .Onboarding__GenderLabel {
      width: 110px;
      height: 110px;
      font-size: 1.5rem;
      padding: 1rem;
   }

   .Onboarding__GenderImage {
      height: 50px;
      margin-top: 0.6rem;
   }

   .Onboarding__GenderChoiceList {
      width: 0;
   }


   .Onboarding__AgeChoiceList .Polaris-Choice__Label {
      font-size: 1.5rem;
   }

   .Onboarding__AgeChoiceList li {
      margin: 0.6rem 1rem;
   }


   .Onboarding__PaypalWrapper {
      width: 90%;
   }


}

@media screen and (max-width: 420px) {
   .Onboarding__CategoriesChoiceList{
      row-gap: 1.2rem;
   }
}

@media screen and (max-width: 399px) {
   .Onboarding__CategoriesChoiceList{
      grid-template-columns: repeat(1, 1fr);
   }
}