.header {
    width: 100%;
    height: 8rem;
    background: #ffffff;
    box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.2);
    padding: 1rem 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header__title {
    font-size: 2rem;
    font-weight: bold;
}

.header__notifications {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.notification {
    height: 30px;
    width: 30px;
}

.header__notification {
    padding: 0.8rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.notification_bell {
    transform: scale(0.04);
}

.header__notification-icon {
    height: 2.4rem;
    width: 2.4rem;
}

.bell{
    height: 50px;
    width: 50px;
}

.notification_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.header__notifications-indicator {
    background: #FB2956; 
    box-shadow: 0rem .25rem .25rem rgb(43, 42, 42, 0.3);
    font-size: 1rem;
    line-height: 1rem;
    color: #ffffff;
    padding: 0.4rem 0.55rem;
    font-weight: bold;
    border-radius: 999px;
    min-width: 18px;
    text-align: center;

    position: absolute;
    top: 0;
    left: 2rem;
}

.signout_container{
    margin-right: 1rem;
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%; */
}

.signout_button{
    padding: 10px;
}

.header__achievements {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.header__achievement {
    padding: 0.8rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.header__achievements-indicator {
    background: #FB2956; 
    box-shadow: 0rem .25rem .25rem rgb(43, 42, 42, 0.3);
    font-size: 1rem;
    line-height: 1rem;
    color: #ffffff;
    padding: 0.4rem 0.55rem;
    font-weight: bold;
    border-radius: 999px;
    min-width: 18px;
    text-align: center;

    position: absolute;
    top: 0;
    left: 3rem;
}

.header__achievements-carot {
    transform: rotate(45deg);
    width: 14px;
    height: 14px;
    background: #fff;
    border: 1px solid #E0E0E0;
    position: absolute;
    top: 60px;
    right: 88px;
}

@media screen and (max-width: 599px) {
}