.llama-pagination {
    width: 100%;
    
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.llama-pagination__info {
    margin-right: 2rem;
    color: #444444;
}

.llama-pagination .Polaris-Pagination__Button,
.llama-pagination .Polaris-Pagination__Button:disabled {
    background: none;
    border: none;
    border-radius: 3px;
}

@media (max-width: 30.625em){
    .llama-pagination__info{
        position: absolute; 
        width: 100%; 
        left: 0; 
        text-align: center;
    }
}