.offer-details__sidebar{
    width: 30rem;
    background: #fafafa;
    border: 1px solid #e0e0e0;
}

.offer-details__sidebar a{
    text-decoration: none;
    font-weight: bold;
    color: #fd2a54;
    font-size: 0.9em;
    display: inline-block;
}

.offer-details__sidebar a .Polaris-Icon{
    display: inline-block;
    vertical-align: top;
    margin-top: -1px;
}

.offer-details__sidebar a .Polaris-Icon svg{
    fill: #fd2a54;
}

.offer-details__sidebar h2,
.offer-details__sidebar h3{
    font-weight: bold;
    margin-bottom: 1.2rem;
}

.offer-details__sidebar h2{
    font-size: 1.8rem;
    position: relative;
    padding-left: 3.2rem;
}

.offer-details__sidebar h2::before{
    content: '';
    position: absolute;
    top: calc(50% - 11px);
    left: 0px;
    width: 21px;
    height: 22px;
}

.offer-details__sidebar h3{
    font-size: 1.6rem;
}

.offer-details__sidebar ul{
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.offer-details__sidebar section{
    padding: 2.3rem 2rem;
    border-bottom: 1px solid #e0e0e0;
}

.offer-details__sidebar section:last-child{
    border-bottom: none;
}

/* OFFER INFO */
.offer-details__sidebar .banner{
    width: 29.8rem;
    height: 11.1rem;
    background-size: cover;
}

.offer-details__sidebar .banner img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.offer-details__sidebar .offer-info{
    position: relative;
    padding-top: 3rem;
    font-size: 1.4rem;
}

.offer-details__sidebar .offer-name{
    display: flex;
    margin-bottom: 1.6rem;
}

.offer-details__sidebar .offer-name img{
    border-radius: 100%;
    margin-right: 1rem;
}

.offer-details__sidebar .brand-wrapper img{
    border-radius: 100%;
}

.offer-details__sidebar .brand-wrapper img[src*="llama-user-icon"],
.offer-details__sidebar .offer-name img[src*="llama-user-icon"]{
    background: #6e4d91;
}

.offer-details__sidebar .offer-name img[src*="llama-head"]{
    height: 42px;
}

.offer-details__sidebar .offer-name h1{
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.2em;
    margin-bottom: 0.2rem;
}

.offer-details__sidebar .offer-name .category{
    font-size: 1.2rem;
    color: #525150;
    text-transform: uppercase;
}

.offer-details__sidebar .offer-info .description{
    margin-bottom: 1.6rem;
}

.offer-details__sidebar .commission-rate{
    margin-bottom: 1.5rem;
}

.offer-details__sidebar .commission-rate:last-child{
    margin-bottom: 0;
}

.offer-details__sidebar .commission-rate .heading,
.offer-details__sidebar .commission-rate span{
    font-weight: bold;
}

.offer-details__sidebar .tags{
    margin-bottom: 0;
}

.offer-details__sidebar .tags li{
    display: inline-block;
    margin-bottom: 1px;
}

.offer-details__sidebar .tag-disclosure{
    font-weight: bold;
    color: #4d5db2;
    cursor: pointer;
}

.offer-details__sidebar .offer-tag{
    cursor: pointer;
}

.offer-details__sidebar .application-status{
    position: absolute;
    top: -12px;
    left: 0;
    width: 100%;
    text-align: center;
}

.offer-details__sidebar .application-status span{
    color: #fff;
    display: inline-block;
    padding: 2px 15px;
    border-radius: 100px;
    font-size: 1.2rem;
    font-weight: bold;
}

.offer-details__sidebar .application-status[data-new="true"] span{
    background: #169317;
}
.offer-details__sidebar .application-status[data-status="APPROVED"] span{
    background: #169317 url('../../assets/notifications/checkmark.svg') no-repeat 12px center;
    background-size: 12px;
    padding-left: 30px;
}
.offer-details__sidebar .application-status[data-status="PENDING"] span{
    background: #f3aa4a url('../../assets/notifications/clock.svg') no-repeat 12px center;
    background-size: 12px;
    padding-left: 30px;
    color: #242424;
}
.offer-details__sidebar .application-status[data-status="INACTIVE-OFFER"] span{
    background: #f00 url('../../assets/notifications/warning.svg') no-repeat 12px center;
    background-size: 12px;
    padding-left: 30px;
}

/* PROMOTE AND EARN */
.offer-details__sidebar .promote{
    background: #ebecf0;
}

.offer-details__sidebar .promote h2::before{
    background: url(../../assets/money-envelope.svg);
}

.offer-details__sidebar .promote p,
.offer-details__sidebar .stats p,
.offer-details__sidebar .smartlinks-rank p,
.offer-details__sidebar .coupons p{
    font-size: 1.2rem;
    line-height: 1.4em;
    margin-bottom: 1.5rem;
}

.offer-details__sidebar .promote p:last-child,
.offer-details__sidebar .stats p:last-child,
.offer-details__sidebar .coupons p:last-child{
    margin-bottom: 0;
}

.offer-details__sidebar .promote .link-box{
    background: #fff;
    padding: 8px 1rem;
    border-radius: 5px;
    margin-bottom: 1.3rem;
}

.offer-details__sidebar .promote .link-box p{
    margin-bottom: 3px;
    font-size: 1.4rem;
}

.offer-details__sidebar .promote button{
    font-weight: bold;
}

.offer-details__sidebar .promote .Polaris-Icon{
    display: inline-block;
    vertical-align: middle;
    margin: -2px 3px 0 -2px;
}

.offer-details__sidebar .promote .actions{
    display: flex;
    flex-wrap: wrap;
}

.offer-details__sidebar .promote .options{
    border: 2px solid #fd2a54 !important;
    color: #fd2a54 !important;
    background: none !important;
}

/* STATS */
.offer-details__sidebar .stats h2::before{
    background: url(../../assets/bar-graph-with-arrow.svg);
    width: 22px;
}

.offer-details__sidebar .stats dl{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
}

.offer-details__sidebar .stats dt{
    font-size: 1.2rem;
    color: #515151;
    grid-row-start: 2;
    margin-bottom: 2rem;
}

.offer-details__sidebar .stats dt:nth-of-type(n+3){
    grid-row-start: 4;
    margin-bottom: 0;
}

.offer-details__sidebar .stats dd{
    font-size: 1.8rem;
    font-weight: bold;
    margin-left: 0;
}

/* ABOUT */
.offer-details__sidebar .about .brand-wrapper{
    display: flex
}

.offer-details__sidebar .about img{
    margin-right: 1rem;
}

.offer-details__sidebar .about .brand-name{
    font-weight: bold;
    margin-bottom: 1px;
}

.offer-details__sidebar .about .description{
    font-size: 1.2rem;
    line-height: 1.4em;
}

.offer-details__sidebar .about .description a{
    font-size: 1em;
    margin-top: 3px;
}

.offer-details__sidebar .about .Polaris-Icon{
    margin-top: -3px;
}

/* COUPONS */
.offer-details__sidebar .coupon{
    padding: 3%;
    font-weight: 500;
    color: #637381;
    border-bottom: 1px solid #DCDCDC;
    list-style: none;
}

@media screen and (max-width: 999px) and (min-width: 931px),
       screen and (max-width: 860px) and (min-width: 780px){
    .offer-details__sidebar .promote .copy{
        margin-bottom: 1rem;
    }

    .offer-details__sidebar .promote button{
        width: 100%;
        margin-right: 0;
    }
}

@media screen and (max-width: 999px){
    .offer-details__sidebar{
        width: 25rem;
    }

    .offer-details__sidebar .banner{
        width: 25rem;
        height: 11.2rem;
    }
}

@media screen and (max-width: 930px){
    .offer-details__sidebar{
        width: 100%;
    }

    .offer-details__sidebar .banner{
        width: 100%;
        height: 20rem;
    }
}

@media screen and (max-width: 930px) and (min-width: 780px){
    .offer-details__sidebar{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .offer-details__sidebar .banner{
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .offer-details__sidebar .application-status{
        width: 200%;
    }
}

@media screen and (max-width: 549px){
    .offer-details__sidebar .banner{
        width: 100%;
        height: 15rem;
    }
}

@media screen and (max-width: 370px){

}