.dashboard .dashboard--featured-offers .info{
    margin-bottom: 3rem;
}

.dashboard--featured-offers ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
    row-gap: 5rem;
    column-gap: 5rem;
    padding-left: 0;
    margin-bottom: 5rem;
}

.offer-detail-snippet{
    display: flex;
    justify-content: space-between;
}

.offer-detail-snippet .merchant-logo{
    margin-right: 1.5rem;
    width: 8.5rem;
    height: 8.5rem;
}

.offer-detail-snippet .merchant-logo img{
    width: 100%;
    border-radius: 100%;
    border: 1px solid #e0e0e0;
}

.offer-detail-snippet .offer-info{
    width: calc(100% - 8.5rem - 1.5rem);
    line-height: normal;
}

.offer-detail-snippet .offer-info .sponsored {
    color: #34135D;
    letter-spacing: .09em;
    padding: 0.3rem 0.6rem;
    border-radius: .3rem;
    display: inline-block;
    border: 1px solid #693E9D;
    font-size: .8rem;
    line-height: normal;
    vertical-align: top;
    margin-left: .2rem;
}

.offer-detail-snippet .offer-info a{
    text-decoration: none;
}

.offer-detail-snippet .offer-info .category,
.offer-detail-snippet .offer-info .commission-rate {
    color: #637381;
}

.offer-detail-snippet .offer-info .category{
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 0.3rem;
}

.offer-detail-snippet .offer-info .name{
    margin-bottom: 1rem;
    line-height: 1.3em;
}

.offer-detail-snippet .offer-info .name a{
    font-weight: bold;
    color: #212B36;
    font-size: 1.6rem;
}

.offer-detail-snippet .offer-info .name a:hover{
    color: #000;
}

.offer-detail-snippet .offer-info .commission-rate{
    font-size: 1.5rem;
    margin-bottom: 0.7rem;
    line-height: 1.3em;
}

.offer-detail-snippet .offer-info .details a{
    color: #fd2a54;
    font-size: 1.2rem;
    font-weight: bold;
}

.dashboard--featured-offers .more-offers{
    border-top: 1px solid #e0e0e0;
    color: #637381;
    padding-top: 3rem;
    text-align: center;
    font-size: 1.6rem;
}

.dashboard--featured-offers .more-offers a{
    color: #fd2a54;
    font-weight: bold;
    text-decoration: none;
}

@media screen and (max-width: 910px){
    .dashboard--featured-offers ul {
        grid-template-columns: repeat(auto-fit, minmax(29rem, 40rem));
    }
}