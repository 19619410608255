.llama-slider {
    overflow: hidden;
    height: 2.1rem;
    width: 100%;
    position: relative;
}

.llama-slider input[type="range"] {
    -webkit-appearance: none;
    position: absolute;
    top: 0;
    left: 0;

    overflow: visible;
    height: 100%;
    width: 100%;
    cursor: pointer;
    margin: 0;
    border-radius: 3px;
    background: none;
}

:focus {
    outline: none;
}

::-webkit-slider-runnable-track {
    height: 1rem;
}

.llama-slider__fill-wrapper {
    position: relative;
    height: 1rem;
    top: 0.5rem;
    left: 0;
    width: calc(100% - 2rem);
    border-radius: 3px;
    overflow: hidden;
    margin: 0 1rem;
}

.llama-slider__fill,
.llama-slider__background {
    background: #33185C;
    position: absolute;
    height: 1rem;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 3px;
}

.llama-slider__background {
    background: #F5F5F5;
    position: relative;
}

.llama-slider__value-display {
    color: #444444;
    margin-top: 1rem;
}

/*
 * 1. Set to 0 width and remove border for a slider without a thumb
 */
::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px; /* 1 */
    height: 2rem;
    width: 2rem;
    background: #fff;
    border: 2px solid #33185C;
    border-radius: 999px;
    position: relative;
    top: -0.5rem;
    z-index: 5;
}

::-moz-range-thumb {
    -webkit-appearance: none;
    width: 20px; /* 1 */
    height: 2rem;
    width: 2rem;
    background: #fff;
    border: 2px solid #33185C;
    border-radius: 999px;
    position: relative;
    top: -0.5rem;
    z-index: 5;
    box-sizing: border-box;
    pointer-events: all;
}

/* ::-moz-range-track {
    height: 40px;
    background: #ddd;
}

::-moz-range-thumb {
    background: #fff;
    height: 40px;
    width: 20px;
    border: 3px solid #999;
    border-radius: 0 !important;
    box-shadow: -100vw 0 0 100vw dodgerblue;
    box-sizing: border-box;
}

::-ms-fill-upper { 
    background: dodgerblue;
}

::-ms-thumb { 
    background: #fff;
    border: 2px solid #999;
    height: 40px;
    width: 20px;
    box-sizing: border-box;
}

::-ms-ticks-after { 
    display: none; 
}

::-ms-ticks-before { 
    display: none; 
}

::-ms-track { 
    background: #ddd;
    color: transparent;
    height: 40px;
    border: none;
}

::-ms-tooltip { 
    display: none;
} */