.OfferView .affiliate-offer__products{
    border-top: none;
}

.OfferView__Tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid #dcdee5;

    border-bottom: 2px solid #EFEFEF;
    position: relative;
    /* padding: 0 1rem; */
}

.OfferView__Tab {
    padding: 2rem 0;
    flex: 1;

    margin-right: 1rem;

    text-align: center;
    color: #BEBEBE;

    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
}

.OfferView__TabCurrent {
    display: none;
}

.OfferView__Tabs--mobile {
    display: none;
}

@media only screen and (min-width: 600px) {

    .OfferView__Tab[data-active="true"] {
        color: rgba(253,42,84,1);
        border-bottom: 4px solid rgba(253,42,84,1);
    }

}

@media only screen and (max-width: 600px) {

    .OfferView {
        flex-wrap: wrap;
    }

    .OfferView__Tabs {
        display: none;
    }

    .OfferView__Tabs--mobile {
        display: flex;
        flex-direction: column;
    }

    .OfferView__Tab {
        border-bottom: 2px solid #EBE8ED;
        margin-right: 0;
    }

    .OfferView__Tabs--open {
        display: flex;
        flex-direction: column;
    }
    
    .OfferView__TabCurrent {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 2rem 0 1.5rem 0;
        flex: 1;

        color: #BEBEBE;
        border-bottom: 4px solid rgba(253,42,84,1);

        cursor: pointer;
    }

    .OfferView__CurrentTabName {
        margin-left: 1rem;
        text-transform: uppercase;
        font-weight: bold;
        color: rgba(253,42,84,1);
    }

    .OfferView__Arrow {
        height: 12px;
        margin-right: 2rem;
        transition: all 300ms ease-in-out;
    }

    .OfferView__Arrow--flipped {
        transform: rotate(180deg);
        transition: all 300ms ease-in-out;
    }
}