.info-tooltip {
    background: #4A4A4A;
    color: #FFFFFF;
    font-size: 1rem;
    display: inline-block;
    line-height: 1rem;
    padding: .3rem .7rem;
    border-radius: 1rem;
    font-weight: bold;
    margin-left: 0;
    transform: scale(0.8) translateY(-3px);
}

#branded-signup {
    background: #f4f6f8;
}

#branded-signup .logo {
    background: #34135D url(../../assets/logo.svg) no-repeat 20px center;
    background-size: 106px 25px;
    height: 57px;
    text-indent: -99999px;
}
#branded-signup header h1 {
    font-weight: bold;
    color: #fff;
    font-size: 2.8rem;
    padding: 4.1rem 2rem 15.2rem;
    text-align: center;
    clip-path: ellipse(72vw 100% at 50% 0);
}
#branded-signup .program {
    background: #fff;
    max-width: 800px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    margin: -111px auto 30px;
    position: relative;
    z-index: 10;
    display: flex;
}

#branded-signup[data-merchant="false"] .program{
    max-width: 500px;
}

/* MERCHANT CARD */
#branded-signup .merchant-invite-card {
    width: 50%;
    border-right: 1px solid #E0E0E0;
}

/* SIGNUP FORM */
#branded-signup .form {
    width: 50%;
    padding: 3rem 2rem 2rem;
    line-height: normal;
    position: relative;
}

#branded-signup[data-merchant="false"] .program .form{
    width: 100%;
}

#branded-signup .form h3 {
    font-weight: bold;
    font-size: 2.2rem;
    margin-bottom: 0.3rem;
    text-align: center;
}
#branded-signup .form h3 + p {
    font-size: 1.4rem;
    margin-bottom: 3rem;
    text-align: center;
    color: #637381;
    line-height: 1.45em;
}
#branded-signup .form .amplify-form-item {
    margin-bottom: 1.7rem;
}
#branded-signup .existing-user {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 6rem;
}
#branded-signup .existing-user a {
    color: #FD2A54;
    font-weight: bold;
    text-decoration: none;
}

/* WHAT'S LLAMA */
#branded-signup .about-content {
    max-width: 66rem;
    padding: 0 3rem;
    margin: 0 auto;
    font-size: 1.4rem;
}
#branded-signup .about-content h4 {
    font-weight: bold;
    font-size: 2.2rem;
    margin-bottom: 1.6rem;
    text-align: center;
}
#branded-signup .about-content p {
    line-height: 1.5em;
    margin-bottom: 2rem;
}
#branded-signup .about-content .about-features {
    list-style: none;
    padding-left: 1.4rem;
}
#branded-signup .about-content .about-features li {
    margin-bottom: 0.8rem;
    position: relative;
    padding-left: 3rem;
}
#branded-signup .about-content .about-features li::before {
    content: url(../../assets/check-purple.svg);
    width: 19px;
    height: 16px;
    display: inline-block;
    margin-right: 8px;
    position: absolute;
    top: calc(50% - 8px);
    left: 0;
}
#branded-signup .about-content button {
    display: inline-block;
    padding: 1rem 5rem;
    background: #fd2a54;
    color: #fff;
    text-align: center;
    font-weight: 700;
    border-radius: 50px;
    margin: 2rem 0;
    cursor: pointer;
    border: none;
    font-size: 1em;
}

#branded-signup .about-content .disclaimer{
    font-size: 1.2rem;
}

/* FLOATING LEO HEAD */
#branded-signup .leo-mascot {
    position: fixed;
    left: -20px;
    bottom: -5px;
    z-index: 100;
}

/* FOOTER */
#branded-signup footer nav {
    background: #34135D;
}
#branded-signup footer svg {
    vertical-align: bottom;
}
#branded-signup footer ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 1.5rem 0;
    margin: 0;
}
#branded-signup footer li {
    margin-right: 3rem;
}
#branded-signup footer li:last-child {
    margin-right: 0;
}
#branded-signup footer li a {
    color: #fff;
    text-decoration: none;
    font-size: 1.4rem;
    display: inline-block;
}

/* FOOTER WAVES  */
#branded-signup footer .wave-container {
    max-height: 225px;
    overflow: hidden;
}
#branded-signup footer .wave {
    animation: wave 20s linear;
    animation-iteration-count: infinite;
}
#branded-signup footer #wave2 {
    animation-duration: 30s;
    animation-direction: reverse;
    opacity: 0.6;
}

/* REMOVE EXTRA ELEMENTS FROM VERIFICATION CODE STEP */
#branded-signup .signup-amplify__graphic-wrapper{
    display: none;
}
#branded-signup .signin-amplify__wrapper{
    height: auto;
    display: block;
    background: none;
}

#branded-signup .signin-amplify__form-wrapper{
    position: relative;
    top: auto;
    left: auto;
    margin-top: -10rem;
    width: auto;
    display: block;
    height: auto;
}

#branded-signup .signin-amplify__signin-form{
    width: 100%;
    padding: 0;
}

@media screen and (max-width: 1200px){
    #branded-signup .signin-amplify__signin-form{
        width: 100%;
    }
}

@keyframes wave {
    100% {
        transform: translateX(-100%);
    }
}

@media screen and (max-width: 1190px) {
    #branded-signup .leo-mascot img {
        width: 180px;
        height: 264px;
    }
}
@media screen and (max-width: 1075px) {
    #branded-signup .leo-mascot img {
        width: 150px;
        height: 220px;
    }
}
@media screen and (max-width: 1025px) {
    #branded-signup[data-merchant="true"] .leo-mascot {
        top: 66px;
        bottom: auto;
        left: calc(50% + 280px);
        z-index: 1;
        position: absolute;
    }
    #branded-signup .leo-mascot img {
        width: 100px;
        height: 146.875px;
    }
}
@media screen and (max-width: 840px) {
    #branded-signup[data-merchant="true"] .program {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}
@media screen and (max-width: 760px) {
    #branded-signup footer {
        position: relative;
        overflow: hidden;
    }
    #branded-signup[data-merchant="false"] .leo-mascot {
        position: absolute;
    }
    #branded-signup .leo-mascot {
        top: auto;
        left: -1.5rem;
        bottom: -2rem;
    }
}
@media screen and (max-width: 750px) {
    /* #branded-signup .merchant-invite-card {
        width: 58%;
    }
    #branded-signup[data-merchant="true"] .form {
        width: 42%;
    } */
    #branded-signup .merchant-invite-card .program-banner {
        height: 135px;
    }
    #branded-signup .merchant-invite-card .merchant-logo {
        top: calc(135px - (112px / 2));
    }
    /* #branded-signup .form h3 {
        font-size: 1.8rem;
    }
    #branded-signup .form h3 + p {
        font-size: 1.2rem;
    } */
}
@media screen and (max-width: 690px) {
    #branded-signup .logo {
        background-position: center center;
    }
    #branded-signup h1 {
        padding-top: 0;
    }
    #branded-signup .program {
        max-width: 450px;
        flex-wrap: wrap;
    }
    #branded-signup[data-merchant="true"] .program{
        margin-left: auto;
        margin-right: auto;
    }
    #branded-signup .merchant-invite-card {
        border-right: none;
    }
    #branded-signup .merchant-invite-card,
    #branded-signup[data-merchant="true"] .form {
        width: 100%;
    }
    #branded-signup .merchant-invite-card .program-banner {
        height: 149px;
    }
    #branded-signup .merchant-invite-card .merchant-logo {
        top: calc(149px - (112px / 2));
    }
    #branded-signup .form {
        border-top: 1px solid #E0E0E0;
    }
    #branded-signup .form h3 {
        font-size: 1.9rem;
    }
    #branded-signup .form h3 + p {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 530px) {
    #branded-signup h1 {
        display: none;
    }
    #branded-signup .program {
        margin-top: 0;
    }
    #branded-signup .merchant-invite-card .branding p:first-child {
        position: relative;
    }
    #branded-signup .merchant-invite-card .branding p:first-child::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
    }
    #branded-signup .merchant-invite-card .merchant-logo {
        top: calc(50% - 50px);
        width: 100px;
        height: 100px;
    }
    #branded-signup .merchant-invite-card h2 {
        left: calc(15px + 15px + 100px);
        letter-spacing: 0.04em;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        margin-top: 0;
        color: #fff;
    }

    #branded-signup footer ul{
        flex-direction: column;
    }

    #branded-signup footer li{
        margin-right: 0;
    }

    #branded-signup footer li a{
        padding: .5rem 0
    }
}

@media screen and (max-width: 375px) {
    #branded-signup .merchant-invite-card .merchant-logo {
        top: calc(50% - 40px);
        width: 80px;
        height: 80px;
    }
    #branded-signup .merchant-invite-card h2 {
        left: calc(15px + 15px + 80px);
        font-size: 2rem;
    }
}