.HypeAuditor__Label {
    color: #494949;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    display: block;
    padding: 0 1rem;
}

.HypeAuditor__Input,
.StripeElement {
    display: block;
    width: 100%;
    margin: 1rem 0 3rem 0;
    padding: 10px 14px;
    font-size: 18px;
    letter-spacing: 0.025em;
    font-family: 'Source Code Pro', monospace;
    border: 0;
    outline: 0;
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: .1rem solid #c4cdd5;
    border-radius: 3px;
}

.HypeAuditor__Input::placeholder {
    color: #aab7c4;
}

.Polaris-Modal-Footer .Polaris-Button {
    border-radius: 999px;
}

.Polaris-Modal-Footer .Polaris-Button--primary,
.Polaris-Modal-Footer .Polaris-Button--primary:hover {
    background: rgba(253,42,84,1);
    border: none;
    box-shadow: none;
    z-index: 100;
    opacity: 1;
    transition: all 100ms ease-out;
}

.Polaris-Modal-Footer .Polaris-Button--primary:hover {
    opacity: 0.8;
}

.Polaris-Modal-Footer .Polaris-Checkbox__Backdrop {
    border-radius: 1px;
}

.HypeAuditor__Product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
}

.HypeAuditor__ProductDetail {
    text-align: right;
}

.HypeAuditor__ProductDescription {
    color: #7f7f7f;
}

.HypeAuditor__TrialBanner {
    margin-bottom: 4rem;
}

.HypeAuditor__TrialParagraph {
    margin-bottom: 2rem;
}

.HypeAuditor__TrialBanner .Polaris-Banner {
    box-shadow: inset 0 3px 0 0 #FB2955, inset 0 0 0 0 transparent, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    background: rgb(243, 243, 243);
}

.HypeAuditor__ProductSelect {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
}

.HypeAuditor__Settings {
}

.HypeAuditor__SettingsWrapper {
    margin: 3rem;
    border-radius: 3px;
    overflow: hidden;
}
.HypeAuditor__VerifyWrapper {
    margin: 0 2rem;
    border-radius: 3px;
    overflow: hidden;
}

.HypeAuditor__AccountSettings {
    width: 100%;
    color: #212B36;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
}

.HypeAuditor__AccountSettingsHeader {
    background: #E6EDF3;
    border-bottom: 2px solid #828282;
    font-weight: bold;
}

.HypeAuditor__AccountUsername {
    flex: 2;
}

.HypeAuditor__AccountStatus{
    flex: 2;
}

.HypeAuditor__AccountAction{
    
    display: flex;
    flex-direction: column;
    justify-content: start;

    min-width: 170px;
}

.HypeAuditor__CancelWrapper {
    padding: 3rem;
}

.HypeAuditor__CancelDescription {
    color: #7f7f7f;
    flex: 5;
}

.HypeAuditor__CancelSettings {
    width: 100%;
    color: #212B36;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 3rem;
}

.HypeAuditor__CancelDescription {
    flex: 4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.HypeAuditor__CancelActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.HypeAuditor__VerificationHeader {
    font-weight: bold;
    padding-bottom: 2.2rem;
}

.HypeAuditor__VerificationCode {
    flex: 2;
    text-align: center;
    font-size: 36px;
    color: #34135D;
}

.HypeAuditor__VerificationText {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin: 1rem 0;
    max-width: 750px;
}

.HypeAuditor__VerificationText--bold {
    font-weight: bold;
}

.HypeAuditor__VerificationTextSubdued {
    font-size: 1.2rem;
    color: #888888;
    margin-top: 0.6rem;
    margin-bottom: 2rem;
}

.HypeAuditor__VerificationFooter {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.HypeAuditor__VerificationButton {
    font-size: 1.5rem;
    font-weight: bold;
}
.HypeAuditor__VerificationText--centered {
    text-align: center;
    padding-top: 1.2rem;
}


.hype-account__add-account {
    background: #f7fbff;
    padding: 2rem 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
}
.hype-account__add-text {
    color: #637381;
    margin-right: 1rem;
}
.tiktok-account__add-account {
    background: #f7fbff;
    padding: 2rem 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
}
.tiktok-account__add-text {
    color: #637381;
    margin-right: 1rem;
}
