.smartlinks .analytics h3{
    font-weight: bold;
    font-size: 1.6rem;
}

.smartlinks .analytics .heading-and-date,
.smartlinks .analytics .summary-and-date{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #DFE3E8;
    padding-bottom: 0.5rem;
}

/* OVERVIEW */
.smartlinks .analytics .overall-stats{
    margin-bottom: 5rem;
}

.smartlinks .analytics .summary-and-date{
    font-size: 1.4rem;
    color: #637381;
    margin-bottom: 3rem;
}

.smartlinks .analytics .summary-and-date .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    width: 40rem;
    height: 2.2rem;
    transform: translateY(.6rem);
}

/* GRAPH */
.smartlinks .analytics .overall-stats .day-breakdown{
    height: 34rem;
}

/* CLICKS */
.smartlinks .analytics .clicks .linklist{
    list-style: none;
    padding-left: 0;
    margin-top: .8rem;
}

.smartlinks .analytics .clicks .linklist li{
    border-bottom: 1px solid #DFE3E8;
    padding: 1.3rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.smartlinks .analytics .clicks .link-info{
    display: flex;
    flex-direction: column;
    color: #637381;
    padding-right: 2rem;
}

.smartlinks .analytics .clicks .link-info b{
    color: #212b36;
}

.smartlinks .analytics .clicks .toggle-graph{
    margin-top: 0.9rem;
    background: none;
    border: none;
    padding: 0;
    font-weight: bold;
    color: #fd2a54;
    cursor: pointer;
}

/* PIE CHART */
.smartlinks .analytics .clicks figure{
    display: flex;
    margin: 0;
    align-items: center;
}

.smartlinks .analytics .clicks figure svg{
    vertical-align: middle;
}

.smartlinks .analytics .clicks figcaption{
    margin-left: 1.3rem;
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    line-height: 1.4em;
}

.smartlinks .analytics .clicks .empty{
    color: #637381;
    text-transform: uppercase;
    font-weight: bold;
    align-self: center;
    font-size: 1.3rem;
}

.smartlinks .analytics .clicks .Polaris-SkeletonDisplayText--sizeSmall{
    width: 3.4rem;
    height: 3.4rem;
}
.smartlinks .analytics .clicks .Polaris-SkeletonDisplayText--sizeSmall::after{
    border-radius: 100%;
}

.smartlinks .analytics .clicks .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    width: 60%;
}
.smartlinks .analytics .clicks figcaption .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    width: 8rem;
}

/* GRAPH */
.smartlinks .analytics .clicks .day-breakdown{
    height: 20rem;
    grid-column-start: 1;
    grid-column-end: 3;
    margin-top: 3rem;
}

/* SKELETON GRAPH */
.smartlinks .analytics .skeleton-graph{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    column-gap: 2rem;
    align-items: end;
    padding: 0 3rem;
    border-bottom: 1px solid #D6D6D6;
    border-left: 1px solid #D6D6D6;
    height: 71%;
}

.smartlinks .analytics .skeleton-graph .group{
    height: 100%;
    display: flex;
    align-items: flex-end;
}

.smartlinks .analytics .skeleton-graph .Polaris-SkeletonDisplayText--sizeMedium {
    width: 50%;
}

.smartlinks .analytics .skeleton-graph .group:nth-child(1) .Polaris-SkeletonDisplayText--sizeMedium:nth-child(1){
    height: 90%;
}
.smartlinks .analytics .skeleton-graph .group:nth-child(1) .Polaris-SkeletonDisplayText--sizeMedium:nth-child(2){
    height: 70%;
}
.smartlinks .analytics .skeleton-graph .group:nth-child(2) .Polaris-SkeletonDisplayText--sizeMedium:nth-child(1){
    height: 80%;
}
.smartlinks .analytics .skeleton-graph .group:nth-child(2) .Polaris-SkeletonDisplayText--sizeMedium:nth-child(2){
    height: 40%;
}
.smartlinks .analytics .skeleton-graph .group:nth-child(3) .Polaris-SkeletonDisplayText--sizeMedium:nth-child(1){
    height: 50%;
}
.smartlinks .analytics .skeleton-graph .group:nth-child(3) .Polaris-SkeletonDisplayText--sizeMedium:nth-child(2){
    height: 25%;
}
.smartlinks .analytics .skeleton-graph .group:nth-child(4) .Polaris-SkeletonDisplayText--sizeMedium:nth-child(1){
    height: 60%;
}
.smartlinks .analytics .skeleton-graph .group:nth-child(4) .Polaris-SkeletonDisplayText--sizeMedium:nth-child(2){
    height: 40%;
}
.smartlinks .analytics .skeleton-graph .group:nth-child(5) .Polaris-SkeletonDisplayText--sizeMedium:nth-child(1){
    height: 70%;
}
.smartlinks .analytics .skeleton-graph .group:nth-child(5) .Polaris-SkeletonDisplayText--sizeMedium:nth-child(2){
    height: 50%;
}
.smartlinks .analytics .skeleton-graph .group:nth-child(6) .Polaris-SkeletonDisplayText--sizeMedium:nth-child(1){
    height: 95%;
}
.smartlinks .analytics .skeleton-graph .group:nth-child(6) .Polaris-SkeletonDisplayText--sizeMedium:nth-child(2){
    height: 70%;
}
.smartlinks .analytics .skeleton-graph .group:nth-child(7) .Polaris-SkeletonDisplayText--sizeMedium:nth-child(1){
    height: 85%;
}
.smartlinks .analytics .skeleton-graph .group:nth-child(7) .Polaris-SkeletonDisplayText--sizeMedium:nth-child(2){
    height: 50%;
}

@media screen and (max-width: 1230px){
    .smartlinks .analytics .clicks .linklist li{
        grid-template-columns: 2fr 1fr;
    }
}

@media screen and (max-width: 1040px) and (min-width: 981px),
       screen and (max-width: 820px){
    .smartlinks .analytics .summary-and-date{
        display: block;
    }
    .smartlinks .analytics .summary-and-date .LlamaDatePicker__DateFilter{
        margin-top: 1rem;
    }

    .smartlinks .analytics .summary-and-date .LlamaDatePicker__DateFilter .date-picker-trigger{
        margin-left: auto;
    }
}

@media screen and (max-width: 780px) and (min-width: 600px),
       screen and (max-width: 480px){
    .smartlinks .analytics .clicks .linklist li{
        grid-template-columns: 1fr;
    }

    .smartlinks .analytics .clicks .day-breakdown{
        grid-column-end: 2;
    }

    .smartlinks .analytics .clicks figure{
        margin: 1rem 0;
    }
}

@media screen and (max-width: 390px){
    .smartlinks .analytics .heading-and-date{
        display: block;
    }
    .smartlinks .analytics .heading-and-date .LlamaDatePicker__DateFilter{
        margin-top: 1rem;
    }
    .smartlinks .analytics .summary-and-date .LlamaDatePicker__DateFilter .date-picker-trigger{
        margin-left: 0;
    }

    .smartlinks .analytics .LlamaDatePicker__DateFilter{
        display: block;
    }
    .smartlinks .analytics .LlamaDatePicker__DatePopover{
        left: 0;
    }
}