.invoice_table .Polaris-DataTable__Cell--fixed {
    width: 25.5rem;
}

.invoice_total_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.invoice_total_title {
    font-weight: bold;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.invoice_total_title_label {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.invoice_total {
    font-size: 25px;
    font-weight: bold;
    padding: 10px;
}

.invoice_total_made {
    height: 15px;
    width: 15px;
    background-color: #2cbc7b;
    border-radius: 50%;
    display: inline-block;
    margin: 5px;
}

.invoice_total_owed {
    height: 15px;
    width: 15px;
    background-color: #fb4f53;
    border-radius: 50%;
    display: inline-block;
    margin: 5px;
}

.invoice_custom_badge_warning .Polaris-Badge--statusWarning{
    background-color: #fb4f53;
    font-weight: bold;
}

.invoice_custom_badge_success .Polaris-Badge--statusSuccess{
    background-color: #2cbc7b;
    font-weight: bold;
}