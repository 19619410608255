.OfferReferrals__Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 2rem 2rem;
}

.OfferReferrals__Heading {
    margin-bottom: 2rem;
}

.OfferReferralsTable{
    border-collapse: collapse;
    margin-bottom: 1rem;
    border-spacing: 0;
    width: 100%;
}

.OfferReferralsTable thead th{
    white-space: nowrap;
    background: #f9fafb;
    border-bottom: 1px solid #c4cdd5;
    border-top: .1rem solid #dfe3e8;
    text-align: left;
    
}

.OfferReferralsTable thead th,
.OfferReferralsTable td{
    padding: 1.2rem 1.2rem;
}

.OfferReferralsTable td{
    border-bottom: 1px solid #dfe3e8;
}

.OfferReferralsTable .date,
.OfferReferralsTable .name{
    white-space: nowrap;
}

.OfferReferralsTable .name[data-has-name="false"]{
    opacity: .6;
}

.OfferReferralsTable label{
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
}

.OfferReferralsTable .progress{
    white-space: nowrap;
}

.OfferReferralsTable progress {
    -webkit-appearance: none;
    appearance: none;

    width: 65%;
    height: 2rem;
}

.OfferReferralsTable progress::-webkit-progress-value{
    background: #33185C;
    border-radius: 4px;
}

.OfferReferralsTable progress::-moz-progress-bar {
    background: #33185C;
    border-radius: 4px;
}

.OfferReferralsTable progress::-webkit-progress-bar,
.OfferReferralsTable progress {
    background: #e9e2ef;
    border-radius: 4px;
    border: none;
}

.OfferReferralsTable .progress .commission-amount{
    position: relative;
    top: -2px;
    display: inline-block;
    font-weight: bold;
}

.OfferReferralsTable .name .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    width: 10rem;
}
.OfferReferralsTable .date .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    width: 8rem;
}

.OfferReferralsTable .progress .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    display: inline-block;
    height: 0.8rem;
    width: 4rem;
    margin-bottom: 3px;
}

.OfferReferralsTable .status .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    width: 8.5rem;
}

@media screen and (max-width: 930px){
    .OfferReferrals__Wrapper{
        padding: 2rem 0;
    }
}

@media screen and (max-width: 1068px) and (min-width: 931px),
       screen and (max-width: 810px){
    .OfferReferralsTable thead{
        display: none;
    }

    .OfferReferralsTable td{
        display: block;
        border-bottom: none;
        padding: 0;
    }
    .OfferReferralsTable td:first-child{
        padding-top: 2rem;
    }
    .OfferReferralsTable td:last-child{
        border-bottom: 1px solid #dfe3e8;
        padding-bottom: 2rem;
    }

    .OfferReferralsTable .name{
        font-size: 1.7rem;
        font-weight: bold;
    }
    
    .OfferReferralsTable .name[data-has-name="false"]::before{
        content: 'Name '
    }

    .OfferReferralsTable .date{
        margin-bottom: 1rem;
    }
    .OfferReferralsTable .progress{
        margin-bottom: 1.5rem;
    }

    .OfferReferralsTable .date::before{
        content: 'Date Joined: '
    }

    .OfferReferralsTable .progress::before{
        content: 'Amount Earned: ';
        display: block;
        font-weight: bold;
    }

    .OfferReferralsTable progress{
        width: 90%;
    }

    .OfferReferralsTable .status .Polaris-Badge__Content::before{
        content: 'Payout Status: ';
    }
}

@media screen and (max-width: 450px){
    .OfferReferralsTable progress{
        width: 85%;
    }
}

.OfferReferrals__Totals {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;

    padding: 2rem 0 3rem;
}

.OfferReferrals__TotalStat {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 40%;
}

.OfferReferrals__TotalKey {
    font-size: 1.8rem;
    font-weight: bold;
}

.OfferReferrals__TotalValue {
    font-size: 2.5rem;
    font-weight: bold;
    padding: 1rem;
    min-width: 4rem;
    text-align: center;
}

.OfferReferrals__Wrapper .empty-state__wrapper{
    padding: 2rem;
}

.OfferReferrals__EmptyStateHead {
    text-align: left;
    font-size: 1.6rem;
    line-height: 1.6em;
}

.OfferReferrals__EmptyStateSteps {
    list-style: none;
    padding-left: 0;
    text-align: left;
    font-size: 1.6rem;
    line-height: 1.5em;
    font-weight: normal;
    margin: 2rem 0
}

.OfferReferrals__EmptyStateSteps li {
    margin-bottom: 1.6rem;
}

.OfferReferrals__EmptyStateSteps li span {
    font-weight: bold;
    color: #33185C;
    display: block;
}