.AchievementNotificationMenu {
    background: #fff;
    position: absolute;
    border: 1px solid #dce5ed;
    box-shadow: 0 0 11px rgba(0, 0, 0, .15);
    top: 67px;
    right: 3rem;
    padding: 1.5rem;
    width: 380px;
    max-width: 380px;
    z-index: 200;
    border-radius: 5px;
}

.AchievementNotificationMenu .title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.AchievementNotificationMenu .close button{
    width: 2rem;
    height: 2rem;
    background: none;
    border: none;
    text-indent: -99999px;
    position: relative;
    cursor: pointer;
}

.AchievementNotificationMenu .close button::before,
.AchievementNotificationMenu .close button::after{
    content: '';
    width: 1.5rem;
    height: 0.2rem;
    position: absolute;
    left: calc(50% - .7rem);
    top: calc(50% - .1rem);
    background: #000;
}
.AchievementNotificationMenu .close button::before{
    transform: rotate(45deg);
}
.AchievementNotificationMenu .close button::after{
    transform: rotate(-45deg);
}

.AchievementNotificationMenu ul {
    list-style-type: none;
    padding: 0;
}

.AchievementNotificationMenu h5 {
    font-size: 1.5rem;
    font-weight: bold;
}

.AchievementNotificationMenu--open {
    display: block;
}

.AchievementNotificationMenu--closed {
    display: none;
}

.AchievementNotificationMenu--loading-notification {
    background: #f2f3f7;
    padding-left: 1.4rem;
    height: 80px;
    margin: 0.6rem;
}

.AchievementNotificationMenu--loading-notification h1{
    min-width: 80%;
    padding: 0.6rem;
}