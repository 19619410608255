.Achievements__Wrapper {
    padding: 2rem 3rem 2rem 5rem;
}
.Achievements__Wrapper h1 {
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: .8rem;
}
.Achievements__Wrapper .subtitle {
    color: #637381;
    margin-bottom: 1.5rem;
}

.Achievements__Wrapper--loading {
    max-width: 1050px;
}

.Achievements__Wrapper--loading .loading-row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
}

.Achievements__Wrapper--loading .loading-badge {
    background: #F2F3F7;
    border: 1px solid #E0E0E0;
    width: 180px;
    border-radius: 3px;
    padding: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Achievements__Wrapper--loading .loading-badge h1 {
    min-width: 80%;
    padding-top: 1rem;
    padding-bottom: 3rem;
}

.Achievements__Wrapper--loading .loading-steps .loading-step {
    background-color: #F2F3F7;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding: 18px;
    margin-bottom: 16px;
}