.smartlinks .tab-content{
    counter-reset: links;
}

.smartlinks .tab-content .info{
    font-size: 1.6rem;
}

.smartlinks .tab-content .info .switch-mode{
    padding: 0;
    border: none;
    font: inherit;
    color: #fd2a54;
    font-weight: bold;
    cursor: pointer;
    background: none;
}

.smartlinks .tab-content .info strong{
    color: #4c5965
}

.smartlinks-links{
    list-style: none;
    padding-left: 0;
}

.smartlinks-links li, 
.smartlinks--sortable-link{
    counter-increment: links;
    position: relative;
    background: #fafafa;
    border: 1px solid #e5e5e5;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
    border-radius: 6px;
    margin-bottom: 2rem;
    padding: 1.6rem 2rem 1.6rem 7rem;
    line-height: normal;
}

.smartlinks--sortable-link[data-editing="true"]{
    border-color: #fd2a54;
}
.smartlinks--sortable-link[data-removing="true"]{
    border-color: #000;
}

/*counter*/
.smartlinks-links li:before{
    content: counter(links);
    position: absolute;
    top: calc(50% - 1.6rem);
    left: 3rem;
    color: #b7b6b6;
    font-size: 2.8rem;
    font-weight: bold;
}

.smartlinks-links[data-is-sortable="true"] li, 
.smartlinks--sortable-link{
    padding-left: 10rem;
}

/*counter*/
.smartlinks-links[data-is-sortable="true"] li:before{
    left: 6rem;
}

/*move icon*/
.smartlinks-links[data-is-sortable="true"] li:after, 
.smartlinks--sortable-link:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3rem;
    height: 100%;
    background: #ededed url(../../assets/smartlink-img/move-icon.svg) center center no-repeat;
    background-size: 12px 33px;
    border-radius: 5px 0 0 5px;
}

.smartlinks-links .actions,
.smartlinks--sortable-link .actions{
    position: absolute;
    right: 2rem;
    top: calc(50% - 12px);
}

.smartlinks-links .actions button,
.smartlinks--sortable-link button,
.cc-info-wrapper button{
    border-radius: 20px;
    border: none;
    font: inherit;
    color: #fff;
    padding: .3rem 1.2rem .3rem 3.2rem;
    cursor: pointer;
}

.smartlinks-links .edit,
.smartlinks--sortable-link .edit,
.cc-info-wrapper .edit{
    background: #fa2f58 url(../../assets/edit-white.svg) 1.3rem center no-repeat;
    background-size: 12px 12px;
    margin-right: 1rem;
}

.smartlinks-links .remove,
.smartlinks--sortable-link .remove{
    background: #262626 url(../../assets/delete-white.svg) 1.3rem center no-repeat;
    background-size: 11px 13px;
}
.smartlinks-links .offer-name, 
.smartlinks--sortable-link .offer-name{
    font-size: 1.2rem;
    font-weight: bold;
    color: #637381;
    display: block;
    margin-bottom: 0.3rem;
}

.smartlinks-links .offer-name a, 
.smartlinks--sortable-link .offer-name a{
    color: #637381;
    text-decoration: none;
}
.smartlinks-links .offer-name a:hover, 
.smartlinks--sortable-link .offer-name a:hover{
    color: #fa2f58;
}

.smartlinks-links .title, 
.smartlinks--sortable-link .title{
    font-size: 1.8rem;
    font-weight: bold;
    display: block;
    margin-bottom: 0.3rem;
}

.smartlinks-links .url, 
.smartlinks--sortable-link .url{
    display: block;
    color: #637381;
    font-size: 1.5rem;
    margin-bottom: 0.3rem;
}

.smartlinks--sortable-link .url,
.smartlinks--sortable-link .title{
    max-width: calc(100% - 190px);
}

.smartlinks--sortable-link{
    list-style: none;
    cursor: move;
}

.smartlinks-links[data-loading="true"]{
    position: relative;
}
.smartlinks-links[data-loading="true"]::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 8rem);
    background: rgba(255, 255, 255, .8);
    z-index: 100;
}
.smartlinks-links[data-loading="true"] .loading{
    background: none;
    counter-increment: none;
    border: none;
    box-shadow: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
}
.smartlinks-links[data-loading="true"] .loading::before, .smartlinks-links[data-loading="true"] .loading::after{
    display: none;
}

.smartlinks--last-updated{
    text-align: right;
    font-size: 1.2rem;
    color: #637381;
}

.smartlinks--add-link{
    margin-bottom: 3rem;
}

.smartlinks--add-link button{
    margin: 1rem auto;
    min-width: 184px;
    font-size: 1.5rem;
    padding: .8rem 2rem;
    font-weight: bold;
}

.smartlinks--add-link button:last-child{
    margin-right: auto !important;
}
.smartlinks--add-link button:first-child{
    margin-left: auto !important;
}

/* form styles */
.smartlinks--link-form{
    list-style: none;
    padding-left: 0;
}

.smartlinks--link-form li{
    margin-bottom: 3rem;
}

.smartlinks--link-form li:last-child{
    margin-bottom: 0;
}

.smartlinks--link-form .Polaris-Label__Text{
    font-size: 1.6rem;
}

.smartlinks--field-info{
    display: block;
    margin-top: 0.3rem;
    font-size: 1.4rem;
}

.smartlinks--link-type label{
    margin-right: 2rem;
}

.smartlinks--account-switch-banner .Polaris-Button,
.smartlinks--account-switch-banner .Polaris-Button:hover{
    background: #fd2a54;
    border: none;
    color: #fff;
}

.Polaris-Modal .smartlink-offer-submit{
    position: absolute;
}

@media screen and (max-width: 1210px){
    .smartlinks-links li, 
    .smartlinks--sortable-link{
        padding-left: 5.5rem;
    }

    .smartlinks-links[data-is-sortable="true"] li, 
    .smartlinks--sortable-link{
        padding-left: 8.5rem;
    }

    /*counter*/
    .smartlinks-links li:before{
        left: 2rem;
        font-size: 2.5rem
    }

    .smartlinks-links[data-is-sortable="true"] li:before{
        left: 5rem;
    }

    .smartlinks-links .title, 
    .smartlinks--sortable-link .title{
        font-size: 1.6rem;
    }

    .smartlinks-links .url, 
    .smartlinks--sortable-link .url{
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 1050px){
    .smartlinks-links .actions,
    .smartlinks--sortable-link .actions{
        position: static;
        display: block;
        margin-top: 1.5rem;
    }

    .smartlinks--sortable-link .url,
    .smartlinks--sortable-link .title{
        max-width: none;
    }

    .smartlinks-links[data-is-sortable="true"] li:before{
        top: calc(50% - 3.4rem);
    }
    .smartlinks--upgrade-notice .smartlinks-links[data-is-sortable="true"] li:before{
        top: calc(50% - 1.2rem);
    }
}