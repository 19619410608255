/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/
 
/* .discover-offer .Polaris-Card__Section:first-child {
    padding: 0;
} */

.discover-offer__pagination-text{
  margin: 10px;
}

.discover-offers__sidebar-icon {
  display: none;
}

.discover-offer {
  display: flex;
}

/* .discover-offers__main-wrapper {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 40rem));
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;

  flex-grow: 1;
} */

.discover-offers__main-wrapper{ 
  list-style: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(30rem, 1fr));
  row-gap: 5rem;
  column-gap: 3rem;
  margin-bottom: 5rem;
  margin-top: 0;
}

@media screen and (min-width: 1750px) {
  .discover-offers__main-wrapper{
    grid-template-columns: repeat(3, minmax(30rem, 1fr));
  }
}
@media screen and (min-width: 2140px) {
  .discover-offers__main-wrapper{
    grid-template-columns: repeat(4, minmax(30rem, 1fr));
  }
}

.discover-offer .Polaris-Card__Section:nth-child(2){
  border-top: none;
}

.discover-offer__info {
  margin-bottom: 1rem;
}
.discover-offer__stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 5%;
  padding: 0 2rem;
  width: 40%;
}

.discover-offer__loading{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.discover-offer__pagination {
  width: 100%;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.discover-offer__check-col {
  display: flex;
  flex-direction: column;
}

.discover-offers__sidebar-content {
  width: 100%;
}

.discover-offers__sidebar-section {
  width: 100%;
  padding: 1.5rem 2rem;
}

.discover-offers__sidebar-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.discover-offers__reset-filters{
  padding-left: 2rem;
  background: url(../../assets/reset-filters-icon.svg) no-repeat left center;
  background-size: 14px 12px;
  margin-left: .7rem;
  color: #331b5b;
  cursor: pointer;
}

.discover-offers__reset-filters[data-enabled="false"]{
  opacity: .2;
  cursor: default;
}

.discover-offers__filter-group {
  margin: .5rem 0 0;
  display: flex;
  align-items: center;
}

.discover-offers__filter-group .llama-button:disabled{
  opacity: 0.2;
}

@media only screen and (max-width: 1290px) and (min-width: 1001px),
            screen and (max-width: 950px) {
  .discover-offers__main-wrapper{
    grid-template-columns: repeat(1, 100%);
  }
  .discover-offers__main-wrapper .title-brand-wrapper,
  .discover-offers__main-wrapper .description{
    height: auto;
  }
}

@media only screen and (max-width: 1000px) {
  .mobile-tab {
    border-bottom: 2px solid #f0f0f0;
    padding: 1.2rem 2rem;
  }

  .discover-offers__sidebar--active {
    border-bottom: 3px solid #FD2856;
  }

  .discover-offers__sidebar-icon {
    display: block;
    height: 14px;
  }

  .discover-offers__sidebar-content {
    display: none;
  }

  .expanded {
    display: block;
    padding: 0 2rem 3.2rem;
  }
  .discover-offers__sidebar-row + .expanded{
    padding-top: 3.2rem;
  }

  .discover-offers__sidebar-section {
    padding: 0;
  }
}

@media only screen and (max-width: 950px) {
  .discover-offers__main-section{
    margin: 0 auto;
  } 
}