#social-auth-buttons{
    padding-left: 0;
    list-style: none;
}

#social-auth-buttons button{
    all: unset;
    width: 100%;
    cursor: pointer;
    text-align: center;
    border-radius: 999px;
    font-size: 1.6rem;
    padding: 1rem;
    box-sizing: border-box;
    display: block;
    position: relative;
}

#social-auth-buttons button::after{
    position: absolute;
}

#social-auth-buttons li:not(:last-child){
    margin-bottom: 1rem;
}

#social-auth-buttons .facebook{
    background: #1877F2;
    border: 1px solid #1877F2;
    color: #fff;
    font-weight: bold;
}

#social-auth-buttons .facebook::after{
    content: url(../../assets/fb_white-circle.svg);
    width: 2.4rem;
    height: 2.4rem;
    top: calc(50% - 1.2rem);
    left: 1.5rem;
}

#social-auth-buttons .google{
    background: #fff;
    border: 1px solid #C1C8D2;
    color: #757575;
    box-shadow: 0 1px 3px rgba(131, 144, 165, .1);
    font-weight: 500;
    font-family: Roboto, 'Helvetica Neue';
}

#social-auth-buttons .google::after{
    content: url(../../assets/google.svg);
    width: 1.7rem;
    height: 1.8rem;
    top: calc(50% - .9rem);
    left: 1.8rem;
}

.social-auth-separator{
    text-align: center;
    color: #637381;
    font-size: 1.6rem;
    margin: 2rem 0;
    position: relative;
}

.social-auth-separator::before,
.social-auth-separator::after{
    content: '';
    top: calc(50%);
    /* 1.5rem is width of the word or, 1rem is spacing */
    width: calc(50% - 1.5rem - 1rem);
    height: 1px;
    background: #e0e0e0;
    display: block;
    position: absolute;
}

.social-auth-separator::before{
    left: 0;
}

.social-auth-separator::after{
    right: 0;
}

.social-auth-breadcrumb{
    position: absolute;
    top: .8rem;
    left: 0;
}

@media screen and (max-width: 690px) {
    .social-auth-breadcrumb{
        position: static;
        margin-bottom: .5rem;
        transform: translateX(-1rem);
    }
}