#smartlinks__customize-panel .panel-content.themes{
    max-width: none;
}

/* themes */
#smartlink-themes-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, 120px);
    column-gap: .5rem;
    row-gap: .5rem;
    list-style: none;
    padding-left: 0;
}

#smartlink-themes-list li{
    padding: 6px;
    border-radius: 10px;
}

#smartlink-themes-list .accessibility {
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
}

#smartlink-themes-list .Polaris-SkeletonDisplayText__DisplayText{
    height: 100%;
}

#smartlink-themes-list button{
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

#smartlink-themes-list button[disabled]{
    opacity: .5;
}

#smartlink-themes-list li[data-selected="true"]{
    background: #34175b;
    position: relative;
    text-align: center;
}

#smartlink-themes-list li[data-selected="true"]::before{
    content: '';
    width: 10px;
    height: 5px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
    position: absolute;
    bottom: 15px;
    left: 22px;
}

#smartlink-themes-list li[data-selected="true"]::after{
    content: 'Selected';
    color: #fff;
    padding-left: 10px;
}

#smartlink-themes-list .theme-wrapper{
    border-radius: 10px;
    border: 1px solid #ccc;
    width: 108px;
    height: 190px;
    padding: 9px 7px 15px;
    background: #fff;
    margin-bottom: 4px;
}

#smartlink-themes-list span{
    display: block;
    text-indent: -99999px;
}

#smartlink-themes-list .avatar{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #b2b2b2;
    margin: 0 auto 8px;
}

#smartlink-themes-list .headline{
    width: 65px;
    height: 7px;
    border-radius: 7px;
    background: #000;
    margin: 0 auto 12px;
}

#smartlink-themes-list .link{
    width: 100%;
    margin-bottom: 7px;
    border-radius: 3px;
    padding: 8px;
    border: 2px solid #fff;
}

#smartlink-themes-list .link-text{
    background: #fff;
    height: 4px;
    border-radius: 4px;
    margin: 0 auto 7px;
}
#smartlink-themes-list .link-text:last-child{
    margin-bottom: 0;
}
#smartlink-themes-list .link:nth-child(4) .link-text:nth-child(2){
    width: 60px;
}
#smartlink-themes-list .link:nth-child(5) .link-text{
    width: 70px;
}

/* default theme */
#smartlink-themes-list .theme-default .theme-wrapper{
    background: #fff;
}
#smartlink-themes-list .theme-default .headline, #smartlink-themes-list .theme-default .link{
    background: #34135D;
}
#smartlink-themes-list .theme-default .link{
    border-color: #34135D
}

/* steel theme */
#smartlink-themes-list .theme-steel .theme-wrapper{
    background: #535256;
}
#smartlink-themes-list .theme-steel .headline,
#smartlink-themes-list .theme-steel .link-text{
    background: #fff;
}
#smartlink-themes-list .theme-steel .link{
    background: #688aa4;
    border-color: #688aa4;
}

/* camo theme */
#smartlink-themes-list .theme-camo .headline{
    background: #fff;
}
#smartlink-themes-list .theme-camo .theme-wrapper{
    background: #5d8c6a;
}
#smartlink-themes-list .theme-camo .link{
    background: #992c23;
    border-color: #992c23
}

/* snow theme */
#smartlink-themes-list .theme-snow .link{
    background: #000;
    border-color: #000;
}

/* punk rock theme */
#smartlink-themes-list .theme-punk-rock .headline{
    background: #fff;
}
#smartlink-themes-list .theme-punk-rock .theme-wrapper{
    background: #19191a;
}
#smartlink-themes-list .theme-punk-rock .link{
    background: #a40e4a;
    border-color: #8a0739
}

/* electric blue theme */
#smartlink-themes-list .theme-electric-blue .headline,
#smartlink-themes-list .theme-electric-blue .link-text{
    background: #f4ff57;
}
#smartlink-themes-list .theme-electric-blue .link{
    border-color: #f4ff57;
}
#smartlink-themes-list .theme-electric-blue .theme-wrapper{
    background: #09007d;
}

/* lemon lime theme */
#smartlink-themes-list .theme-lemon-lime .headline{
    background: #1d591e;
}
#smartlink-themes-list .theme-lemon-lime .theme-wrapper{
    background: #dfd51b;
}
#smartlink-themes-list .theme-lemon-lime .link{
    background: #1d591e;
    border-color: #1d591e
}
#smartlink-themes-list .theme-lemon-lime .link-text{
    background: #fdf88d;
}

/* bloom theme */
#smartlink-themes-list .theme-bloom .headline{
    background: #fff;
}
#smartlink-themes-list .theme-bloom .theme-wrapper{
    background: linear-gradient(#694ff6, #ffb9c6);
}

/* spray theme */
#smartlink-themes-list .theme-spray .headline{
    background: #fff;
}
#smartlink-themes-list .theme-spray .theme-wrapper{
    background: linear-gradient(#76f3fa, #3a4eff);
}

/* gold theme */
#smartlink-themes-list .theme-gold .headline,
#smartlink-themes-list .theme-gold .link-text{
    background: #58380b;
}
#smartlink-themes-list .theme-gold .link{
    border-color: #58380b
}
#smartlink-themes-list .theme-gold .theme-wrapper{
    background: linear-gradient(#a77227, #f8e18c, #a77227);
}

/* prety in pink theme */
#smartlink-themes-list .theme-pretty-in-pink .headline{
    background: #fff;
}
#smartlink-themes-list .theme-pretty-in-pink .theme-wrapper{
    background: #cc1a74;
}
#smartlink-themes-list .theme-pretty-in-pink .link{
    background: #fdc2d9;
    border-color: #fdc2d9
}
#smartlink-themes-list .theme-pretty-in-pink .link-text{
    background: #a4115c;
}

/* custom theme */
#smartlink-themes-list .custom .theme-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    text-indent: 0;
    background: #333;
}
#smartlink-themes-list .custom .theme-wrapper span{
    color: #fff;
    font-weight: bold;
    text-indent: 0;
    position: relative;
}
#smartlink-themes-list .custom .theme-wrapper span::before{
    content: url(../../../assets/edit-white.svg);
    width: 30px;
    height: 30px;
    display: block;
    margin: 0 auto 5px;
}

@media screen and (max-width: 1075px) and (min-width: 781px),
       screen and (max-width: 660px) and (min-width: 200px){
    #smartlink-themes-list{
        grid-template-columns: repeat(auto-fit, 95px);
    }

    #smartlink-themes-list li[data-selected="true"]::before{
        left: 9px;
    }

    #smartlink-themes-list .Polaris-SkeletonDisplayText__DisplayText{
        height: 190px;
    }

    #smartlink-themes-list .theme-wrapper{
        width: 83px;
        height: 135px;
        padding: 6px 5px 11px;
    }

    #smartlink-themes-list .avatar{
        width: 27px;
        height: 27px;
        margin: 0 auto 6px;
    }

    #smartlink-themes-list .headline{
        width: 62px;
        margin: 0 auto 9px;
    }

    #smartlink-themes-list .link{
        margin-bottom: 4px;
        padding: 6px;
    }

    #smartlink-themes-list .link-text{
        height: 2px;
        margin: 0 auto 5px;
    }
    #smartlink-themes-list .link:nth-child(4) .link-text:nth-child(2){
        width: 28px;
    }
    #smartlink-themes-list .link:nth-child(5) .link-text{
        width: 49px;
    }
}