/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/
 /* .twitter__button {
    width: 140px;
    height: 40px;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    white-space: nowrap;
    color: #fff;
    background-color: #4AB3F4;
    border: solid #4AB3F4;
} */

.twitter-integration__button {
   color: #fff;
   background: #1da1f2 url(../../../assets/twitter_white.svg) no-repeat 1rem center;
   background-size: auto calc(100% - 1.8rem);
   cursor: pointer;
   padding: 0.7rem 1rem .7rem 4.5rem;
   margin: 2rem 0 1rem 0;
   font-weight: bold;
   border-radius: 3px;
   display: flex;
   align-items: center;
   justify-content: center;
   border: none;
   position: relative;
}

.twitter-integration__button::after {
   content: '';
   position: absolute;
   top: .6rem;
   left: 3.3rem;
   width: 1px;
   height: calc(100% - 1.2rem);
   background: rgba(255, 255, 255, .3);
}

.twitter-integration__header {
   font-weight: bold;
   font-size: 1.75rem;
   margin-bottom: 0.6rem;
}

.twitter-integration__list-accounts {
   padding-left: 0;
   list-style: none;
}

.twitter-integration__list-accounts li{
   display: flex;
   align-items: center;
   padding: 1.5rem 0;
   border-bottom: 1px solid #E0E0E0;
}

.twitter-integration__list-accounts img{
   width: 40px;
   height: 40px;
   border-radius: 100%;
   margin-right: 1.2rem;
}

.twitter-integration__list-accounts .account-info{
   display: flex;
   align-items: center;
   font-size: 1.3rem;
   color: #637381;
   line-height: 1.4em;
   margin-right: auto;
}

.twitter-integration__list-accounts .account-info b{
   display: block;
   font-size: 1.15em;
   color: #212B36;
   white-space: nowrap;
}

.twitter-integration__list-accounts .account-info + button{
   margin-left: 3rem;
}

.twitter-integration__list-accounts button{
   white-space: nowrap;
   font-size: 1.2rem;
   font-weight: bold;
   padding-left: 3.7rem;
}

.twitter-integration__list-accounts button.unlink{
   background: #000 url(../../../assets/unlink-white.svg) no-repeat 1.5rem .5rem;
   border-color: #000;
}


.twitter-integration__account-select {
    width: 100%;
    padding: 2rem;
 }

 .twitter-integration__accounts {
   width: 100%;
   padding: 2rem;
 }

 .twitter-integration__resource-item {
   border-bottom: 1px solid #cdcdcd;
 }

 .twitter-integration__name-photo {
   display: flex;
   align-items: center;
}

.twitter-integration__photo-avatar {
   width: 30px;
   height: 30px;
   padding: 1rem;
   border-radius: 50px;
   background-color: #1da1f2;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 1.2rem;
}

.twitter-integration__photo {
   height: 16px;
}

.twitter-integration__name {
   font-weight: bold;
}
 
 .twitter-integration__accounts-groups {
    display: flex;
    flex-direction: column;
 }
 
 .twitter-integration__section-header {
    font-size: 1.5rem;
    font-weight: bold;
 }
 
 .twitter-integration__unlink {
   display: flex;
   align-items: center;
   color: #ff2754;
 }
 
 .twitter-integration__groups-pages {
    width: 100%;
    margin: 2rem 4rem;
 }
 
 .twitter-integration__list-accounts {
   margin: 1rem 0;
}

 .twitter-integration__list-account {
    display: flex;
    justify-content: space-between;
 }
 
 .twitter-integration__list-groups {
    max-width: 90%;
    margin: 1rem 0;
 }
 
 
 .twitter-integration__unlink-image {
    height: 1.25rem;
    margin-right: 0.5rem;
 }

 @media screen and (max-width: 599px) {

   .affiliate-social__section-title {
      margin-left: 2rem;
   }

   .twitter-integration__name {
      font-size: 1.2rem;
   }

   .twitter-integration__unlink {
      font-size: 1.2rem;
   }

}

@media screen and (max-width: 450px) {
   .twitter-integration__list-accounts button.unlink{
      margin-right: 0;
   }
}

@media screen and (max-width: 420px) {
   .twitter-integration__list-accounts li{
      flex-wrap: wrap;
   }
   .twitter-integration__list-accounts .account-info{
      width: 100%;
      margin-bottom: 0.5rem;
   }
   .twitter-integration__list-accounts button.unlink{
      margin-left: 5.2rem;
   }
}