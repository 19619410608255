/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/
 
.settings-1099__iframe{min-height: calc(100vh - 170px)}
.w9{
    border-top: 1px solid #e5e5e5;
    margin-top: 8px;
    padding-top: 20px;
}

.settings-1099__wrapper {
    padding: 20px;
    padding-left: 40px;
    margin: 0 auto 20px;
    width: 50%;
}

@media screen and (max-width: 800px) {
    .settings-1099__wrapper {
        padding-left: 20px;
    }
}

@media screen and (max-width: 599px) {

    .settings-1099__wrapper {
        width: 100%;
    }

}