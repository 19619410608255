.TransactionReports {
    padding: 2rem;
}

.TransactionReports__Table {
    width: auto;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: scroll;
}

.TransactionReports__TableHeader {
    background: rgb(230, 237, 243);
    font-weight: bold;
    border-bottom: 2px solid #828282;
}

.TransactionReports__Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
}

.TransactionReports__Row:last-child {
    border-bottom: 1px solid #828282;
}

.TransactionReports__Cell {
    margin: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 5rem;
}

.TransactionReports__Cell:last-child {
    border-right: none;
}

.TransactionReports__DataName {
    width: 10rem;
    justify-content: flex-start;
}

.TransactionReports__DataNumber {
    justify-content: flex-start;
}
.TransactionReports__DataDate {
    justify-content: flex-end;
    width: 7rem;
}

.TransactionReports__DataCoupon {
    justify-content: flex-end;
    width: 9rem;
    text-align: right;
}

.TransactionReports__DataBadge {
    width: 7rem;
}
.TransactionReports__DataBadge,
.TransactionReports__DataCurrency {
    justify-content: flex-end;
    width: 7rem;
}

.TransactionReports__DataLookForward {
    justify-content: flex-end;
    text-align: right;
    width: 7rem;
}

.TransactionReports__CouponCode {
    background: #33165C;
    color: #ffffff;
    font-weight: bold;
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
}