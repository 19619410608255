.HypeUpsell__Section-Header {
    font-size: 2rem;
    font-weight: bold;
}

.HypeUpsell__SubHeader {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.2rem;
}

.HypeUpsell__TextWrapper {
    max-width: 400px;
    margin: 0 auto;
}

.HypeUpsell__P {
    font-size: 1.5rem;
    color: #404040;
}

.HypeUpsell__Phone {
    width: 140px;
}

.HypeUpsell__FlexSection {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 0;
}

.HypeUpsell__ListItem {
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
}

.HypeUpsell__CheckIcon {
    height: 1rem;
    /* height: 1.2rem; */
    margin-right: 1rem;
}

.HypeUpsell__ListItemName {
    font-size: 1.5rem;
    /* font-size: 1.8rem; */
}

.HypeUpsell__SecondaryCTA {
    display: flex;
    align-items: center;
    margin-left: 2.75rem;
    cursor: pointer;
}

.HypeUpsell__SecondaryCTAText {
    color: #fd295b;
    font-size: 1.4rem;
}

.HypeUpsell__RightCarot {
    height: 0.75rem;
    margin-left: 0.5rem;
}

.HypeUpsell__PlanSelection {
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HypeUpsell__Plan {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    cursor: pointer;
}

.HypeUpsell__Plan--active {
    border-bottom: 4px solid #fd295b;
}

.HypeUpsell__PlanType {
    color: #34135D;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 0.5rem;
    cursor: pointer;
}

.HypeUpsell__PlanType--Inactive {
    opacity: 0.3;
}

.HypeUpsell__PlanType:nth-child(2) {
    margin-left: 1rem;
}

.HypeUpsell__PlanPrice {
    font-size: 2rem;
    line-height: 2.6rem;
    font-weight: bold;
}

.HypeUpsell__PlanDisclaimer {
    font-size: 1rem;
    margin-bottom: 0.6rem;
    color: #7f7f7f;
}

.HypeUpsell__PlanDisclaimerFooter {
    font-size: 1rem;
    line-height: 1.25rem;
    color: #7f7f7f;
    margin: 1rem 0 2rem;
}

.HypeUpsell__PlanDisclaimer--Pink {
    color: #fd295b;
}

.HypeUpsell__CTASection {
    /* display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column; */
    max-width: 400px;
    margin: 2rem auto;
}

.HypeUpsell__CTA {
    background-color: #ed1376;
    cursor: pointer;
    padding: 0.5rem 1rem;
    /* margin: 2rem 0 1rem 0; */
    font-weight: bold;
    border-radius: 3px;
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    /* max-width: 300px;
    margin: 0 auto; */
}

.HypeUpsell__CTAInput {
    margin-bottom: 2rem;
    width: 100%;
    max-width: 400px;
}

.HypeUpsell__CTAInput .Polaris-Label__Text {
    color: #494949;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 1.2rem;
    display: block;
}

.HypeUpsell__Footer {
    display: flex;
    flex-direction: column;
    max-width: 480px;
}

.HypeUpsell__Success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 360px;
    width: 360px;
    margin: 0 auto;
}

.HypeUpsell__SuccessMessage {
    font-size: 1.4rem;
}

.HypeUpsell__SuccessRow {
    display: flex;
    align-items: center;
}

.HypeUpsell__SuccessCheck {
    height: 1rem;
    margin-left: 0.6rem;
}

.HypeUpsell__SuccessLlama {
    display: block;
    height: 60px;
    margin-bottom: 0.6rem;
}

.affiliate-social__social-platform .HypeUpsell__Card{
    padding: 2rem;
}