#smartlinks-onboarding{
    margin: -3rem -5.5rem 0;
    z-index: 120;
    position: relative;
}

#smartlinks-onboarding .Polaris-Frame{
    background: none;
}

#smartlinks-onboarding .Polaris-ProgressBar{
    border-radius: 0;
}

#smartlinks-onboarding .inner-content{
    text-align: center;
    /* full height - heading - tabs */
    min-height: calc(100vh - 8rem - 5.3rem);
    background: #fff;
    padding: 8rem 10rem 0;
}

#smartlinks-onboarding .leohead{
    margin-bottom: 3.2rem;
}

#smartlinks-onboarding .leohead img{
    width: 9rem;
    height: 9rem;
    border-radius: 100%;
}

#smartlinks-onboarding[data-step="0"] .leohead img{
    width: 12rem;
    height: 12rem;
}

#smartlinks-onboarding .title{
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1.4rem;
    line-height: 1.3em;
}

#smartlinks-onboarding .intro{
    font-size: 2rem;
    color: var(--llama-subdued);
    line-height: 1.3em;
}

#smartlinks-onboarding .step{
    font-size: 1.4rem;
    color: var(--llama-subdued);
    position: absolute;
    right: 2rem;
    top: 2rem;
}

#smartlinks-onboarding header{
    margin-bottom: 4rem;
}

#smartlinks-onboarding footer{
    margin-top: 4rem;
}

#smartlinks-onboarding footer .info{
    font-size: 1.4rem;
    margin-bottom: 2rem;
}

#smartlinks-onboarding footer button{
    margin: 0 auto;
    font-weight: bold;
    font-size: 2.2rem;
    width: min(30rem, 100%);
    padding: .9rem;
}

#smartlinks-onboarding a{
    color: var(--llama-pink);
    text-decoration: none;
    font-weight: bold;
}

#smartlinks-onboarding .Polaris-Labelled__HelpText{
    text-align: left;
}

#smartlinks-onboarding .smartlinks--link-form{
    width: min(47rem, 100%);
    margin: 0 auto;
}

#smartlinks-onboarding .themes legend{
    display: none;
}

#smartlinks-onboarding .themes fieldset{
    border: none;
    padding: 0;
}

#smartlinks-onboarding #smartlink-themes-list{
    grid-template-columns: repeat(3, 120px);
    justify-content: center;
    margin: 0;
}

#smartlinks-onboarding[data-step="4"] .info{
    width: min(56rem, 100%);
    margin: 0 auto 2rem;
}

#smartlinks-onboarding .smartlinks-url-form{
    justify-content: center;
    align-items: center;
}

#smartlinks-onboarding .smartlinks-url-form .availability,
#smartlinks-onboarding .smartlinks-url-form .Polaris-Spinner{
    margin-top: 2.2rem;
}

#smartlinks-onboarding .smartlinks-url-form button{
    display: none !important;
}


#smartlinks-onboarding footer .back{
    position: absolute;
    top: 2rem;
    left: 2rem;
    font-size: 1.2rem;
    width: auto;
}

@media screen and (max-width: 1070px) {
    #smartlinks-onboarding .inner-content{
        padding: 6rem 6rem 0;
    }
}

@media screen and (max-width: 1020px) {
    #smartlinks-onboarding .inner-content{
        padding: 6rem 5rem 0;
    }

    #smartlinks-onboarding .leohead{
        margin-bottom: 2.8rem;
    }

    #smartlinks-onboarding .title{
        font-size: 2.6rem;
        margin-bottom: 1.2rem;
    }
    
    #smartlinks-onboarding .intro{
        font-size: 1.7rem;
    }

    #smartlinks-onboarding header{
        margin-bottom: 3rem;
    }
    
    #smartlinks-onboarding footer{
        margin-top: 3rem;
    }

    #smartlinks-onboarding footer .info{
        font-size: 1.3rem;
    }
    
    #smartlinks-onboarding footer button{
        font-size: 1.7rem;
    }
}

@media screen and (max-width: 980px) {
    #smartlinks-onboarding{
        margin-left: -4rem;
        margin-right: -4rem;
        margin-top: 2.6rem;
    }
}

@media screen and (max-width: 680px) {
    #smartlinks-onboarding .leohead{
        margin-bottom: 2rem;
    }

    #smartlinks-onboarding .leohead img{
        width: 7rem;
        height: 7rem;
    }
    
    #smartlinks-onboarding[data-step="0"] .leohead img{
        width: 11rem;
        height: 11rem;
    }

    #smartlinks-onboarding .title{
        font-size: 2.3rem;
        margin-bottom: 1rem;
    }
    
    #smartlinks-onboarding .intro{
        font-size: 1.6rem;
    }

    #smartlinks-onboarding header{
        margin-bottom: 2.5rem;
    }

    #smartlinks-onboarding footer{
        margin-top: 2.5rem;
    }

    #smartlinks-onboarding footer .info{
        font-size: 1.3rem;
    }
    
    #smartlinks-onboarding footer button{
        font-size: 1.7rem;
    }
}

@media screen and (max-width: 630px) and (min-width: 600px),
       screen and (max-width: 440px){
    #smartlinks-onboarding .inner-content{
        padding: 4rem 3rem;
    }

    #smartlinks-onboarding .leohead{
        margin-bottom: 2rem;
    }

    #smartlinks-onboarding .leohead img{
        width: 7rem;
        height: 7rem;
    }
    
    #smartlinks-onboarding[data-step="0"] .leohead img{
        width: 11rem;
        height: 11rem;
    }

    #smartlinks-onboarding .title{
        font-size: 2.1rem;
        margin-bottom: 1rem;
    }
    
    #smartlinks-onboarding .intro{
        font-size: 1.5rem;
    }

    #smartlinks-onboarding footer .info{
        font-size: 1.2rem;
    }
    
    #smartlinks-onboarding footer button{
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 490px) {
    #smartlinks-onboarding{
        margin-left: -3rem;
        margin-right: -3rem;
    }
}


@media screen and (max-width: 1075px) and (min-width: 781px),
       screen and (max-width: 660px) and (min-width: 200px){
    #smartlinks-onboarding #smartlink-themes-list{
        grid-template-columns: repeat(3, 95px);
    }
}

@media screen and (max-width: 690px) and (min-width: 600px){
    #smartlinks-onboarding #smartlink-themes-list{
        grid-template-columns: repeat(3, 95px);
    }

    #smartlinks-onboarding #smartlink-themes-list li[data-selected="true"]::before{
        left: 9px;
    }

    #smartlinks-onboarding #smartlink-themes-list .theme-wrapper{
        width: 83px;
        height: 135px;
        padding: 6px 5px 11px;
    }

    #smartlinks-onboarding #smartlink-themes-list .Polaris-SkeletonDisplayText__DisplayText{
        height: 190px;
    }

    #smartlinks-onboarding #smartlink-themes-list .avatar{
        width: 27px;
        height: 27px;
        margin: 0 auto 6px;
    }

    #smartlinks-onboarding #smartlink-themes-list .headline{
        width: 62px;
        margin: 0 auto 9px;
    }

    #smartlinks-onboarding #smartlink-themes-list .link{
        margin-bottom: 4px;
        padding: 6px;
    }

    #smartlinks-onboarding #smartlink-themes-list .link-text{
        height: 2px;
        margin: 0 auto 5px;
    }
    #smartlinks-onboarding #smartlink-themes-list .link-text:last-child{
        margin-bottom: 0;
    }
    #smartlinks-onboarding #smartlink-themes-list .link:nth-child(4) .link-text:nth-child(2){
        width: 28px;
    }
    #smartlinks-onboarding #smartlink-themes-list .link:nth-child(5) .link-text{
        width: 49px;
    }
}


@media screen and (max-width: 370px){
    #smartlinks-onboarding #smartlink-themes-list{
        grid-template-columns: repeat(2, 95px);
    }
}

@media screen and (max-width: 640px){
    #smartlinks-onboarding .Polaris-Labelled__HelpText,
    #smartlinks-onboarding .Polaris-TextField,
    #smartlinks-onboarding .Polaris-Label__Text,
    #smartlinks-onboarding .Polaris-DropZone-FileUpload__Button,
    #smartlinks-onboarding .Polaris-Stack__Item{
        font-size: 1.4rem;
    }
}