.offer-preview-card{
    padding: 0 2rem 2rem 2rem;
    border-radius: 7px;
    border: 1px solid #dce5ed;
    box-shadow: 0 0 11px rgba(0, 0, 0, .15);
    position: relative;
    z-index: 2;
    background: #fff;
}

.offer-preview-card *{
    line-height: normal;
}

/* card stack aesthetic for multi offers */
.offer-preview-card-wrapper[data-is-multi="true"]{
    position: relative;
}

.offer-preview-card-wrapper[data-is-multi="true"]::before,
.offer-preview-card-wrapper[data-is-multi="true"]::after{
    content: '';
    height: 1rem;
    background: #fff;
    box-shadow: 0 0 11px rgba(0, 0, 0, .15);
    border: 1px solid #dce5ed;
    border-top: none;
    border-radius: 0 0 7px 7px;
    position: absolute;
}
.offer-preview-card-wrapper[data-is-multi="true"]::before{
    bottom: -1rem;
    width: calc(100% - 2rem);
    left: 1rem;
    z-index: 1;
}
.offer-preview-card-wrapper[data-is-multi="true"]::after{
    bottom: -1.9rem;
    width: calc(100% - 4rem);
    left: 2rem;
    z-index: 0;
}

.offer-preview__cover{
    height: 15rem;
    margin-left: -2rem;
    width: calc(100% + 4rem);
    border-radius: 7px 7px 0 0;
    margin-bottom: 2rem;
    cursor: pointer;

    /*IE*/
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: stretch;
    align-items: flex-end;
}

.offer-preview__cover img{
    width: 100%;
    height: 15rem;
    object-fit: cover;
    border-radius: 7px 7px 0 0;
}

.offer-preview-card .category{
    font-size: 1.1rem;
    color: #525150;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.offer-preview-card .title-brand-wrapper{
    /* height: 67px; */
    margin-bottom: 1rem;
}

.offer-preview-card h3{
    font-size: 1.9rem;
    font-weight: bold;
    margin-bottom: .6rem;
    line-height: 1.4em;
    max-height: 4.8rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    cursor: pointer;
}

.offer-preview-card .brand{
    font-size: 1.3rem;
    display: flex;
    align-items: center;
}

.offer-preview-card .brand img{
    border-radius: 100%;
    margin-right: 8px;
    /* vertical-align: middle; */
}

.offer-preview-card .badges{
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    gap: 1rem;
}

.offer-preview-card .badges li{
    list-style: none;
    color: #fff;
    font-weight: bold;
    padding: 0.5rem 1.4rem;
    border-radius: 4px;
    font-size: 1.2rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .26);
}

.offer-preview-card .application-status[data-status="new"]{
    background: #f20000;
    border: 1px solid #fd4040;
}

.offer-preview-card .application-status[data-status="pending"]{
    background: #f3aa4a url('../../assets/notifications/clock.svg') no-repeat 14px center;
    background-size: 12px;
    padding-left: 32px;
    color: #242424;
    border: 1px solid #ffc67a;
}

.offer-preview-card .application-status[data-status="approved"]{
    background: #169317 url('../../assets/notifications/checkmark.svg') no-repeat 14px center;
    background-size: 12px;
    padding-left: 32px;
    border: 1px solid #3bb43c;
}

.offer-preview-card .product-giveaway{
    background: #fff;
}

.offer-preview-card .product-giveaway span{
    text-indent: -99999px;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: url('../../assets/gift-icon.svg') no-repeat center center;
}

.offer-preview-card .pinned {
    background: #fff;
}

.offer-preview-card .pinned span {
    text-indent: -99999px;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: url('../../assets/pin-icon.svg') no-repeat center center;
}

.offer-preview-card .description{
    font-size: 1.3rem;
    line-height: 1.4em;
    height: 5.4rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 2.5rem;
}

/* titled section */
.offer-preview-card h4{
    font-weight: bold;
    font-size: 1.4rem;
    padding-bottom: 8px;
    border-bottom: 1px solid #c4cdd5;
}

/* eligible products */
.offer-preview-card .eligible-products{
    border-bottom: 1px solid #E0E0E0;
    min-height: 223px;
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 14px;
    padding: 22px 0;
}

.offer-preview-card .eligible-products li{
    /* (100% [container width] - (14 [gap] * 3 [number of columns minus 1])) / 4 [number of columns] */
    width: calc((100% - 42px) / 4);
    aspect-ratio: 1 / 1;
}

/* go a little bigger on 5-6 products so they wrap nicer */
.offer-preview-card .eligible-products[data-num-products="5"] li,
.offer-preview-card .eligible-products[data-num-products="6"] li{
    /* (100% [container width] - (14 [gap] * 3 [number of columns minus 1])) / 4 [number of columns] */
    width: calc((110% - 42px) / 4);
}

.offer-preview-card .eligible-products[data-num-products="3"] li{
    /* (100% [container width] - (14 [gap] * 2 [number of columns minus 1])) / 3 [number of columns] */
    width: calc((100% - 28px) / 3);
}

.offer-preview-card .eligible-products[data-num-products="2"] li{
    /* (100% [container width] - (14 [gap] * 1 [number of columns minus 1])) / 2 [number of columns] */
    width: calc((100% - 14px) / 2);
}

.offer-preview-card .eligible-products[data-num-products="1"] li{
    width: 100%;
    max-height: 179px;
}

.offer-preview-card .eligible-products img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* heights that are roughly square until aspect-ratio is more widely supported */
@supports not (aspect-ratio: auto) {
    .offer-preview-card .eligible-products li{
        height: 80px;
    }
    .offer-preview-card .eligible-products[data-num-products="3"] li{
        height: 100px;
    }
    .offer-preview-card .eligible-products[data-num-products="2"] li{
        height: 150px;
    }
    .offer-preview-card .eligible-products[data-num-products="1"] li{
        height: 179px;
    }

    @media screen and (max-width: 1290px) and (min-width: 1001px),
           screen and (max-width: 950px) and (min-width: 701px){
        .offer-preview-card .eligible-products li{
            height: 110px;
        }
        .offer-preview-card .eligible-products[data-num-products="3"] li{
            height: 140px;
        }
        .offer-preview-card .eligible-products[data-num-products="2"] li{
            height: 180px;
        }
    }

    @media screen and (max-width: 1000px) and (min-width: 951px),
           screen and (max-width: 390px){
        .offer-preview-card .eligible-products li{
            height: 55px;
        }
        .offer-preview-card .eligible-products[data-num-products="3"] li{
            height: 90px;
        }
        .offer-preview-card .eligible-products[data-num-products="2"] li{
            height: 130px;
        }
    }
}

@media screen and (max-width: 1290px) and (min-width: 1001px),
       screen and (max-width: 950px){
    .offer-preview-card .eligible-products{
        min-height: 0;
    }
}

.offer-preview-card .eligible-products .more{
    background: #EBECF0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 0.9rem;
    cursor: pointer;
}

.offer-preview-card .eligible-products .more b{
    font-weight: bold;
    font-size: 2em;
    padding-right: 0.5rem;
}

.offer-preview-card .eligible-products .Polaris-Tooltip__Label{
    font-size: 1.2rem;
}

/* commission rate */
.offer-preview-card .commission-rate{
    margin: 0 0 2.5rem;
    background: #ebecf0;
    border-radius: 4px;
    padding: 1.2rem 1rem;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: .5rem;
}

.offer-preview-card .commission-rate dt{
    grid-row-start: 1;
    font-size: 1.2rem;
    white-space: nowrap;
    margin-bottom: -3px;
}

.offer-preview-card .commission-rate dd{
    margin-left: 0;
    font-weight: bold;
}

/* stats */
.offer-preview-card .stats{
    padding: 2rem 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    margin: 0;
    border-bottom: 1px solid #c4cdd5;
}

.offer-preview-card .stats dt{
    font-size: 1.2rem;
    color: #515151;
    grid-row-start: 2;
    margin-bottom: 2rem;
}

.offer-preview-card .stats dt:nth-of-type(n+3){
    grid-row-start: 4;
    margin-bottom: 0;
}

.offer-preview-card .stats dd{
    font-size: 1.8rem;
    font-weight: bold;
    margin-left: 0;
}

.offer-preview-card .button-group{
    padding-top: 2rem;
    display: flex;
    justify-content: center;
}

.offer-preview-card .merchants-link {
    margin-left: 28px;
    margin-top: -1px;
}

@media screen and (max-width: 950px){
    .offer-preview__cover, .offer-preview__cover img{
        height: 20rem;
    }
}

@media screen and (max-width: 450px){
    .offer-preview-card .button-group .llama-button:last-child{
        margin-right: 0;
    }
}