/* .discover-container__wrapper {
    max-width: 1440px;
} */

.discover-container__wrapper .Polaris-Frame {
    background-color: #ffffff;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 15px;
}

.scrollArea {
    height: 100vh;
    overflow-y: auto;
}

.discover-offers__layout {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.discover-offers__sidebar {
    max-width: 30rem;
    min-width: 30rem;
    width: 100%;
    background-color: #fafafa;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 5rem;
    border: 1px solid #e0e0e0;
}

/* .discover-offers__main-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
} */

.discover-offers__search-section {
    background-color: #ebecf0;
    width: 100%;
    padding: 2rem;
    position: relative;
    border-bottom: 1px solid #e0e0e0;
}

.discover-offers__search-section .Polaris-Connected__Item{
    z-index: 20;
}

.discover-offers__search-section .search-button{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border:none;
    background: none;
    cursor: pointer;
    padding-left: 0.5rem;
}



.discover-offers__header {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.32rem;
    line-height: 2rem;
}

.discover-offers__section-header {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    line-height: 2rem;
    color: #525252;
}

.discover-offers__percent-range {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.discover-offer__card-header, .discover-offer__cover{
    cursor: pointer;
}

/* .discover-offers__sidebar-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;
} */

/* .discover-offers__sidebar-icon {
    height: 14px;
} */

/**/

@media screen and (max-width: 1248px) and (min-width: 1291px){
    .discover-offers__sidebar {
        margin-right: 3rem;
    }
}

@media only screen and (max-width: 1000px) {
    .discover-offers__sidebar {
        margin-right: 0;
        margin-bottom: 2.5rem;
    }

    .discover-offers__main-section {
        padding: 0 1rem;
    }

    .discover-offers__layout {
        flex-wrap: wrap;
    }

    .discover-offers__sidebar {
        width: 100%;
        max-width: none;
    }

}