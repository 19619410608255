.settings-general__wrapper .Polaris-Frame {
    background-color: #ffffff;
}



.settings-general{
    /* max-width: 500px; */
    margin: 0 auto;
}
.settings-general__basic-info {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.settings-general__section-label {
    width: 100%;
    background-color: #f3f4f7;
    margin-top: 3rem;
    padding: .3rem 1.5rem;
    color: #7e8f9f;
}

.settings-general__section-title {
    text-transform: uppercase;
}

.settings-general__icon{
    padding-top: 12px;
    margin-left: 30px;
    padding-bottom: 12px;
}

.settings-general__display {
    border: none;
    font-size: 1em;
    text-align: right;
    /* margin-right: 1.5em; */
    border: 1px solid transparent;
    border-top: none;
    border-bottom: none;
}

.settings-general__input {
    border: 1px solid #7e8f9f;
    font-size: 1em;
    text-align: left;
    padding: 0.4rem;
    border-radius: 3px;
    max-width: 30rem;
    height: 3rem;
}

.settings-general__edit .llama-button{
    margin-left: 1.5rem;
}

/* .settings-general__input-align {
    display: flex;
    justify-content: flex-end;
    max-width: 200px;
} */

/* .settings-general--input{
    margin-top: 8px;
    padding-bottom: 12px;
    width: 200px;
    margin-left: 30px;
} */

.settings-general__advertiser-address {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.settings-general--input-label .hint{
    color: #637381;
    font-size: 0.9em;
    display: block;
}

.settings-general__input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1.5rem;
    border-bottom: 1px solid #EFEFEF;
}

.settings-general__edit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.settings-general__edit-text,
.settings-general__edit-password {
    font-size: 1.5rem;
    color: #ff2754;
    cursor: pointer;
    padding: 0;
    background: none;
    border: none;
    margin-left: 1.5rem;
}

.settings-general__referral-section {
    /* border: 3px solid #EBE8ED; */
    padding: 2rem 4rem;
    margin: 0;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    background: #eef9f9;
    border-top: 3px solid #4dc1be;
}

.settings-general__referral-title {
    font-size: 1.5rem; 
    margin-bottom: 1rem;
    font-weight: bold;
    color: #34135D;
    text-transform: uppercase;
}

.settings-general__referral-section button{
    align-self: flex-start;
    padding: 0.7rem 1.6rem;
}

.settings-general__referral-form-row {
    display: flex;
    flex-wrap: wrap;
}

.settings-general__referral-textfield {
    /* min-width: 30rem; */
    max-width: 30rem;
    margin-right: 1rem;
    margin-bottom: 10px;
}

.settings-general__referral-graphic {
    height: 100px;
    margin-right: 20px;
}

.settings-general__wrapper .Polaris-Frame {
    background-color: #ffffff;
}



/*CHANGE PASSWORD*/

.settings-general__password-modal {
    max-width: 38rem;
    margin: 0 auto;
    /* background: #f7f7f7;
    padding: 1rem 2rem;
    border-radius: 8px; */
}

.settings-general__password-modal .amplify-form-row{
    margin-bottom: 0 !important;
}

.settings-general__password-modal .amplify-form-row input{
    margin-bottom: 3px;
}

.settings-general__password-modal .amplify-form-row input#old-password,
.settings-general__password-modal .amplify-form-row input#confirm-password{
    margin-bottom: 15px;
}

.settings-general__password-modal span[data-test="password-match-span"],
.settings-general__password-modal .amplify-password-requirements{
    margin-bottom: 15px;
    display: block;
}

.settings-general__password-modal span[data-test="password-match-span"]{
    margin-top: -12px;
}

.settings-general__password-modal .Polaris-InlineError{
    margin-bottom: 15px;
    margin-top: -10px;
}
.settings-general__password-modal .Polaris-Banner{
    margin-bottom: 1.5rem;
}

@media screen and (max-width: 599px) {

    .settings-general__input-row {
        padding: 1rem 1.25rem;
    }

    .settings-general__section-title {
        font-size: 1.2rem;
    }
    
    .settings-general--input-label {
        font-size: 1.2rem;
    }

    .settings-general__edit input,
    .settings-general__edit .Polaris-Select__SelectedOption {
        font-size: 1.2rem;
    }

    .settings-general__display {
        font-size: 1.2rem;
        margin-right: .5rem;
    }

    .settings-general__edit-text,
    .settings-general__edit-password,
    .settings-general__edit .llama-button {
        font-size: 1.2rem;
    }

    .settings-general__save {
        width: 2rem;
    }


    .settings-general__referral-section {
        flex-wrap: wrap;
        padding: 1.25rem 1.5rem;
    }

    .settings-general__referral-graphic{
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 450px) {
    .settings-general__referral-section {
        justify-content: center;
        text-align: center;
    }
    .settings-general__referral-link{
        width: 100%;
    }
    .settings-general__referral-textfield{
        max-width: none;
        width: 100%;
    }
    .llama-button:last-child{
        margin-right: auto;
    }
    .settings-general__referral-section button{
        margin: 0 auto;
        min-width: 15rem;
    }
}