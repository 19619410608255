:root{
    --circle-dim: 316px;
}

#llama-preloader{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #34175b;
}

/* llama.app logo */
#llama-preloader h1{
    margin-bottom: 30px;
    height: 500px;
    width: 400px;
    position: relative;
    background: url(../../assets/logo.svg) center bottom no-repeat;
    background-size: 400px 95px;
    text-indent: -99999px;
    animation: .4s ease-in logoPopIn;
}

#llama-preloader h1::before, #llama-preloader h1::after{
    content: '';
    position: absolute;
    width: var(--circle-dim);
}

/* llama head circle */
#llama-preloader h1::before{
    left: calc(50% - var(--circle-dim) / 2);
    top: 75px;
    height: var(--circle-dim);
    border-radius: 100%;
    background: linear-gradient(to right, #fda63d 0, #fda63d 50%, #fc2c57 50%, #fc2c57 100%);
}
/* llama head */
#llama-preloader h1::after{
    left: calc(50% - var(--circle-dim) / 2);
    top: -15px;
    height: calc(391px + 15px);
    border-radius: 0 0 var(--circle-dim) var(--circle-dim);
    background: url(../../assets/llama-head-no-bg.svg) center 15px no-repeat;
    animation: 1.1s ease-in llamaPopIn;
    overflow: hidden;
}

.initial-progress-bar{
    width: 80%;
    max-width: 830px;
    height: 24px;
    border-radius: 12px;
    background: rgba(255, 255, 255, .3);
    position: relative;
    text-indent: -99999px;
}

.initial-progress-bar::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    background: linear-gradient(to right, #fda63d 0, #fda63d 5%, #fc2c57 20%, #fc2c57 25%, #fda63d 50%, #fda63d 55%, #fc2c57 95%, #fc2c57 100%);
    background-size: 400% 400%;
    background-attachment: fixed;
    animation: 2s ease-in increaseProgress, 4s ease-in pauseState infinite;
}

/* animations */
@keyframes pauseState{
    0%{
        background-position: 100% 0;
    }
    100%{
        background-position: 15% 0;
    }
}

@keyframes increaseProgress{
    from{width: 0}
    to{width: 100%}
}

@media screen and (min-width: 591px){
    @keyframes logoPopIn{
        0%{
            background-size: 0 0;
        }
        100%{
            background-size: 400px 95px
        }
    }

    @keyframes llamaPopIn{
        0%{
            background-position: center 391px;
        }
        70%{
            background-position: center 0;
        }
        100%{
            background-position: center 15px;
        }
    }
}

@media screen and (max-width: 590px), screen and (max-height: 770px){
    #llama-preloader h1{
        height: 358px;
        width: 286px;
        background-size: 286px 68px;
    }

    :root{
        --circle-dim: 226px;
    }
    
    #llama-preloader h1::before{
        top: 53px;
    }
    #llama-preloader h1::after{
        height: calc(279px + 15px);
    }

    .initial-progress-bar{
        height: 20px;
    }

    /* animations */
    @keyframes logoPopIn{
        0%{
            background-size: 0 0;
        }
        100%{
            background-size: 286px 68px
        }
    }

    @keyframes llamaPopIn{
        0%{
            background-position: center 279px;
        }
        70%{
            background-position: center 0;
        }
        100%{
            background-position: center 15px;
        }
    }
}

@media screen and (max-width: 420px), screen and (max-height: 570px){
    #llama-preloader h1{
        height: 264px;
        width: 211px;
        background-size: 211px 50px;
        margin-bottom: 20px;
    }

    :root{
        --circle-dim: 167px;
    }
    
    #llama-preloader h1::before{
        top: 39px;
    }
    #llama-preloader h1::after{
        height: calc(206px + 15px);
    }

    .initial-progress-bar{
        height: 16px;
    }

    /* animations */
    @keyframes logoPopIn{
        0%{
            background-size: 0 0;
        }
        100%{
            background-size: 211px 50px
        }
    }

    @keyframes llamaPopIn{
        0%{
            background-position: center 206px;
        }
        70%{
            background-position: center 0;
        }
        100%{
            background-position: center 15px;
        }
    }
}