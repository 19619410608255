.OfferCreatives__Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2rem;
    max-width: 600px;
}

.OfferCreatives__Heading {
    margin-bottom: 2rem;
}

.OfferCreatives {
    /* display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.5rem;
    row-gap: 1.5rem;
}

/* .OfferCreatives__ItemCompWrapper {
    margin: 1rem 1rem 1rem 0;
} */

.OfferCreatives__CreativeItemWrapper {
    width: auto;
    height: 110px;
    background: #f4f4f4;
    position: relative;
    cursor: pointer;
}

.OfferCreatives__ExpandOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background:rgba(0,0,0,0.5);
    display: none;
}

.OfferCreatives__ExpandWrapper {
    display: flex;
    justify-content: flex-end;
}

.OfferCreatives__CreativeItemWrapper .OfferCreatives__CreativeItem {
    text-align: center;
    position: relative;
}

.OfferCreatives__CreativeItemWrapper:hover .OfferCreatives__ExpandOverlay{
    display: block;
}

.OfferCreatives__CreativeImage {
    width: auto;
    height: 110px;
    max-width: 100%;
    object-fit: cover;
}

.OfferCreatives__ItemName {
    font-size: 1.2rem;
    width: 100%;
}

.OfferCreatives__ItemControls {
    display: flex;
    align-items: center;
    width: 100%;
    color: #525150;
}

.OfferCreatives__Icon {
    height: 18px;
    cursor: pointer;
    margin-right: 1rem;
}

.OfferCreatives__ExpandIcon {
    width: 18px;
    cursor: pointer;

    margin: 1rem;
    z-index: 200;
}

.OfferCreatives__DownloadIcon {
    height: 36px;
    margin: 1rem;
}

.OfferCreatives__ItemName {
    font-size: 1.75rem;
    font-weight: bold;
    text-align: center;
    margin: 0 0 2rem 0;
}

.OfferCreatives__ItemExpanded {
    height: 70vh;
}

.OfferCreatives__ImageExpanded {
    max-height: calc(100vh - 7rem - 6.4rem - 2.5rem - 2.5rem - 6rem);
    margin: 0 auto;
    display: block;
    max-width: 100%;
    object-fit: contain;
}

.OfferCreatives__ExpandedWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 100vh;
}



.OfferCreatives__EmptyState {
    max-width: 700px;
    margin: 0 auto;
    padding: 4rem;
    text-align: center;
}

.OfferCreatives__EmptyMessage {
    font-size: 2.25rem;
    line-height: 2.85rem;
}



.OfferCreatives__SkeletonItemWrapper .Polaris-SkeletonThumbnail--sizeLarge {
    width: 20rem;
    height: 20rem;
}

@media only screen and (max-width: 710px) {
    .OfferCreatives {
        /* flex-wrap: wrap;
        justify-content: center; */
        grid-template-columns: repeat(2, 1fr);
    }

    .OfferCreatives__CreativeImage,
    .OfferCreatives__CreativeItemWrapper{
        height: calc(25vw - 4rem)
    }
}

@media only screen and (max-width: 600px) {
    .OfferView{
        margin-bottom: 7rem;
    }

    .OfferCreatives__Wrapper{
        margin: 2rem 1rem;
    }
}

@media only screen and (max-width: 440px) {
    .OfferCreatives {
        grid-template-columns: repeat(1, 1fr);
    }
    .OfferCreatives__CreativeImage,
    .OfferCreatives__CreativeItemWrapper{
        height: auto;
        max-width: 100%;
    }
}
