#smartlinks__customize-panel .photo{
    width: 80px;
    height: 80px;
    position: relative;
}

#smartlinks__customize-panel .photo img{
    background: rgba(0, 0, 0, .15);
}

#smartlinks__customize-panel .photo img,
#smartlinks__customize-panel .photo button{
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

#smartlinks__customize-panel .photo button{
    position: absolute;
    top: 0;
    left: 0;
    text-indent: -99999px;
    border: 2px solid #fd2a54;
    background: transparent;
    cursor: pointer;
}

#smartlinks__customize-panel .photo button::after{
    content: '';
    position: absolute;
    bottom: 0;
    right: -12px;
    width: 30px;
    height: 30px;
    background: #fd2a54 url(../../../assets/edit-white.svg) center center no-repeat;
    background-size: 12px 12px;
    border-radius: 100%;
}

/* modal styles */

#smartlink-customize-photos-list {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, 60px);
    column-gap: 1.5rem;
    row-gap: 1.5rem;
}

#smartlink-customize-photos-list button{
    border: 2px solid transparent;
    background: #C4C4C4;
    padding: 0;
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    position: relative;
}

#smartlink-customize-photos-list img{
    width: 100%;
    border-radius: 100%;
}

#smartlink-customize-photos-list li:not(.custom) button::before{
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    bottom: -3px;
    right: -8px;
}

#smartlink-customize-photos-list li:not(.custom) button::after{
    content: '';
    position: absolute;
}

#smartlink-customize-photos-list li.twitter button::before{
    background: #1da1f2 url(../../../assets/twitter_white.svg) calc(50% + 1px) center no-repeat;
    background-size: auto 8px;
}
#smartlink-customize-photos-list li.facebook button::before{
    background: #1877F2 url(../../../assets/fb_white.svg) calc(50% + 1px) center no-repeat;
    background-size: auto 10px;
}
#smartlink-customize-photos-list li.instagram button::before{
    background: url(../../../assets/instagram_white.svg) calc(50% + 1px) center no-repeat, url(../../../assets/instagram-bg.png) no-repeat;
    background-size: auto 9px, 100%;
}
#smartlink-customize-photos-list li.youtube button::before{
    background: #f00 url(../../../assets/youtube_white.svg) calc(50% + 1px) center no-repeat;
    background-size: auto 8px;
}
#smartlink-customize-photos-list li.tiktok button::before{
    background: #000 url(../../../assets/tiktok_white.svg) calc(50% + 1px) center no-repeat;
    background-size: auto 10px;
}



#smartlink-customize-photos-list li[data-selected="true"] button{
    border-color: #fd2a54;
}

#smartlink-customize-photos-list li[data-selected="true"] button::before{
    background: #fd2a54;
}

#smartlink-customize-photos-list li[data-selected="true"] button::after{
    width: 10px;
    height: 5px;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    transform: rotate(-45deg);
    bottom: 6px;
    right: -3px;
}

#smartlink-customize-photos-list .custom button{
    text-indent: -99999px;
    border: 2px dashed #919eab;
    background: #f5f5f5;
    position: relative;
}

#smartlink-customize-photos-list .custom button::before,
#smartlink-customize-photos-list .custom button::after{
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    top: calc(50% - 1px);
    left: calc(50% - 10px);
    background: #919eab;
}

#smartlink-customize-photos-list .custom button::after{
    transform: rotate(90deg);
}