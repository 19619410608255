.empty__state__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem;
}

.empty__state__container .Polaris-Banner{
    margin: 1.5rem 0;
}

.empty__state__content__loading {
    width: 420px;
    /* border: 3px solid #EBE8ED; */
    border-radius: 5px;
    margin: 1rem 0 0 1rem;
}

.empty__state__header {
    font-weight: bold;
    font-size: 1.75rem;
    margin-bottom: 0.6rem;
}

.empty__state__description {
    color: #404040;
}
.empty__state__disclaimer {
    margin-top: 1.5rem;
    color: #404040;
    font-size: 1.3rem;
    line-height: 1.4em;
}

.empty-social__button {
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 0.7rem 1rem .7rem 3.5rem;
    margin: 2rem 0 1rem 0;
    font-weight: bold;
    border-radius: 3px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    position: relative;
}

.empty-social__button::after{
    content: '';
    position: absolute;
    top: .6rem;
    width: 1px;
    height: calc(100% - 1.2rem);
    background: rgba(255, 255, 255, .3);
}

.empty-social__button.facebook {
    background: #1877F2 url(../../assets/fb_white-circle.svg) no-repeat 1rem center;
    background-size: auto calc(100% - 1.4rem);
    width: auto;
    padding-left: 4rem;
}
.empty-social__button.facebook::after {
    display: none;
}

.empty-social__button.twitter {
    background: #1da1f2 url(../../assets/twitter_white.svg) no-repeat 1rem center;
    background-size: auto calc(100% - 1.8rem);
    padding-left: 4rem;
}
.empty-social__button.twitter::after {
    left: 3.5rem;
}

.empty-social__button.youtube {
    background: #ff0000 url(../../assets/youtube_white.svg) no-repeat 1rem center;
    background-size: auto calc(100% - 1.8rem);
    padding-left: 4rem;
}
.empty-social__button.youtube::after {
    left: 3.7rem;
}




@media screen and (max-width: 599px) {

    .empty__state__header {
        font-size: 1.75rem;
    }
    
    .empty__state__description {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

}

