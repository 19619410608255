#smartlink-customize-social-list .Polaris-TextField__Prefix{
    margin-left: 4rem;
}
#smartlink-customize-social-list .Polaris-Select__SelectedOption{
    margin-left: 3rem;
}
#smartlink-customize-social-list li.soundcloud .Polaris-TextField__Prefix{
    margin-left: 4.5rem;
}
#smartlink-customize-social-list li.youtube .Polaris-Select__SelectedOption{
    margin-left: 3.3rem;
}

#smartlink-customize-social-list li{
    position: relative;
}

#smartlink-customize-social-list li::after{
    z-index: 20;
    position: absolute;
    left: 8px;
    top: 30px;
    width: 24px;
    height: 24px;
}

#smartlink-customize-social-list li.facebook::after{
    content: url(../../../assets/smartlink-img/smartlink-fb.svg);
}
#smartlink-customize-social-list li.twitter::after{
    content: url(../../../assets/smartlink-img/smartlink-tw.svg);
    top: 32px;
    width: 23px;
    height: 18px;
}
#smartlink-customize-social-list li.instagram::after{
    content: url(../../../assets/smartlink-img/smartlink-ig.svg);
}
#smartlink-customize-social-list li.tiktok::after{
    content: url(../../../assets/smartlink-img/smartlink-tiktok.svg);
}
#smartlink-customize-social-list li.youtube::after{
    content: url(../../../assets/smartlink-img/smartlink-yt.svg);
    top: 33px;
    width: 27px;
    height: 19px;
}
#smartlink-customize-social-list li.soundcloud::after{
    content: url(../../../assets/smartlink-img/smartlink-sc.svg);
    top: 33px;
    width: 29px;
    height: 12px;
}