.DiscoverMerchants__MerchantInfo {
    padding-bottom: 2rem;
    display: flex;
}
.DiscoverMerchants__MerchantLogo img{
    width: 60px;
    height: 60px;
}
.DiscoverMerchants__MerchantName {
    font-size: 2rem;
    margin-bottom: 0.6rem;
    font-weight: bold;
}
.DiscoverMerchants__MerchantDesc {
    font-size: 1.4rem;
}
.DiscoverMerchants__MerchantContent {
    margin-left: 1.5rem;
}
.DiscoverMerchants__Header {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 2.4rem;
    padding: 1.6rem 0 1.2rem 0;
    border-bottom: 1px solid #e0e0e0;
}
.DiscoverMerchants__MerchantContent a {
    font-size: 1.4rem;
    color: #FD2856;
    font-weight: bold;
    text-decoration: none;
    margin-top: 3px;
}
.DiscoverMerchants__MerchantContent a .Polaris-Icon {
    display: inline-block;
    vertical-align: top;
    margin-top: -1px;
}
.DiscoverMerchants__MerchantContent a .Polaris-Icon svg {
    fill: #fd2a54;
}
.DiscoverMerchants__MerchantDomainLink .Polaris-Icon {
    margin-top: -3px;
}

/* dynamic column count (override from discover-offers.css) */
.DiscoverMerchants__Wrapper .discover-offers__main-wrapper{ 
    grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
}

@media screen and (min-width: 1634px) {
    .DiscoverMerchants__Wrapper .discover-offers__main-wrapper{ 
        grid-template-columns: repeat(auto-fill, minmax(37rem, 1fr));
    }
}

@media screen and (max-width: 489px) {
    .DiscoverMerchants__Wrapper{
        padding: 0 2rem;
    }
}