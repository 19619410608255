.smartlinks, .smartlinks>div, .smartlinks .Polaris-Tabs__Panel{
    height: 100%;
    background: #fff;
}
.smartlinks .Polaris-Tabs__Panel--hidden{
    height: 0;
}

.smartlinks .Polaris-Tabs{
    padding: 0 3.2rem;
}

.smartlinks .Polaris-Tabs .pro-feature{
    background: #34135D;
    color: #fff;
    font-weight: bold;
    padding: 3px 5px;
    border-radius: 4px;
    font-size: 0.7em;
    line-height: 1.4em;
    text-transform: uppercase;
    vertical-align: middle;
}

.customTheme-live-preview{
    margin-bottom: 2.6rem;
}

.smartlinks .Polaris-Tabs__Panel{
    display: flex;
    justify-content: space-between;
}

/* smartlinks tabs */
.smartlinks .tab-content{
    padding: 3rem 5.5rem;
    width: 100%;
}

.smartlinks .tab-content .heading{
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1.1rem;
}

.smartlinks .tab-content .info{
    font-size: 1.6rem;
    color: #637381;
    line-height: 1.37em;
    margin-bottom: 3.5rem;
}

/* upgrade banner */
.smartlinks--upgrade-notice{
    position: relative;
}

.smartlinks--hidden-content{
    position: relative;
    filter: Blur(Strength=2); /*ie fix*/
    filter: blur(2px);
}
.smartlinks--hidden-content::after{
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 50px);
    background: linear-gradient(rgba(255, 255, 255, .5), rgba(255, 255, 255, 1) 97%);
    z-index: 50;
}

.smartlinks--upgrade-notice .Polaris-Banner{
    text-align: center;
    max-width: 810px;
    margin: 0 auto;
    position: absolute;
    top: 2rem;
    left: calc(50% - 810px / 2);
    z-index: 100;
}
.smartlinks--upgrade-notice .Polaris-Banner--withinPage{
    padding: 2.2rem;
    border-radius: 1rem;
}
.smartlinks--upgrade-notice .Polaris-Banner--withinPage.Polaris-Banner--statusWarning{
    position: absolute;
}
.smartlinks--upgrade-notice .Polaris-Heading{
    font-size: 1.8rem;
}
.smartlinks--upgrade-notice .Polaris-Banner__Ribbon{
    display: none
}
.smartlinks--upgrade-notice .Polaris-ButtonGroup__Item{
    margin: 0 auto;
}
.smartlinks--upgrade-notice .Polaris-Button--outline, .smartlinks--upgrade-notice .Polaris-Button--outline:hover{
    border: none;
    color: #fff;
    background: #fd2a54;
}
.smartlinks--upgrade-notice .Polaris-Button__Content{
    font-weight: bold;
    font-size: 1.5rem;
}

/* smartlinks preview sidebar */
.smartlinks .smartlink-preview{
    background: #fafafa;
    /* width: 450px; */
    border-left: 1px solid #e0e0e0;
    text-align: center;
    padding: 3.5rem 5rem 0;
    z-index: 120;
    margin-bottom: -56px;
}

.smartlinks .smartlink-preview .inner-content{
    position: sticky;
    top: -2rem;
}

.smartlinks-sidebar--heading{
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: .5rem;
}

.smartlinks .smartlink-preview .copy{
    margin: 2rem auto;
}
.smartlinks .smartlink-preview .copy button{
    margin: 0 auto;
    font-size: 1.5rem;
    padding: .8rem 2rem;
    font-weight: bold;
}

.smartlinks .custom-link-upgrade-message{
    font-size: 1.4rem;
    margin-top: 2.8rem;
}

.smartlinks .custom-link-upgrade-message .Polaris-Button--plain .Polaris-Button__Content{
    color: #fd2a54;
    font-weight: bold;
    font-size: 1.4rem;
}

@media screen and (max-width: 1490px){
    .smartlinks--upgrade-notice .Polaris-Banner{
        width: calc(100% - 6rem);
        left: calc(50% - (100% - 6rem) / 2);
    }
}

@media screen and (max-width: 1290px){
    .smartlinks--upgrade-notice .Polaris-Heading{
        font-size: 1.6rem;
        line-height: 1.4em;
    }
}

@media screen and (max-width: 1260px){
    .smartlinks .smartlink-preview{
        padding: 3rem 3rem 0;
    }
    .smartlinks .tab-content{
        padding: 3rem 4rem;
    }
    .smartlinks .Polaris-Tabs{
        padding: 0 1.9rem;
    }
}

@media screen and (max-width: 1210px){
    .smartlinks .tab-content .heading{
        font-size: 1.6rem;
        margin-bottom: .8rem;
    }
    
    .smartlinks .tab-content .info{
        font-size: 1.4rem;
        margin-bottom: 3.1rem;
    }

    .smartlinks-sidebar--heading{
        font-size: 1.4rem;
        margin-bottom: 0.3rem;
    }

    .customTheme-live-preview{
        margin-bottom: 2rem;
    }

    .smartlinks .biolink a{
        font-size: 1.3rem;
    }
    .smartlinks .smartlink-preview .copy{
        margin: 1.5rem auto;
    }
    .smartlinks .smartlink-preview .copy button{
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 1120px){
    .smartlinks .smartlink-preview{
        padding: 3rem 2.5rem 0;
    }
}

@media screen and (max-width: 980px){
    .smartlinks .Polaris-Tabs__Panel{
        flex-direction: column;
        justify-content: flex-start;
    }

    .smartlinks .smartlink-preview{
        padding: 0;
    }

    .smartlinks .tab-content{
        order: 1;
        border-right: none;
    }
    
    .customTheme-live-preview{
        position: fixed;
        display: flex;
        align-items: center;
        background: rgba(0, 0, 0, .5);
        top: 0;
        left: 0; 
        width: 100vw;
        height: 100vh;
        z-index: 20;
    }
}

@media screen and (max-width: 860px){
    .smartlinks aside{
        padding: 1.5rem 3rem;
    }
    .smartlinks .biolink-wrapper{
        display: block;
        text-align: left;
        margin-right: auto;
    }

    .smartlinks-sidebar--heading{
        margin-bottom: -.4rem;
    }

    .smartlinks .biolink{
        margin: 0;
    }
}

@media screen and (max-width: 490px){
    .smartlinks .tab-content{
        padding: 3rem;
    }
    .smartlinks .Polaris-Tabs{
        padding: 0 .7rem;
    }
}

@media screen and (max-width: 400px){
    .smartlinks .Polaris-Tabs__Tab{
        padding: 0 0.8rem;
    }
}

@media screen and (max-width: 340px){
    .smartlinks .Polaris-Tabs__Tab{
        padding: 0 2rem;
    }
}