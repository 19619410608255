.EngagementScore__GradeSection {
    background: #f2f2f2;
    padding: 2rem;
    min-height: 150px;
 }
 
 .EngagementScore__GradeHeading {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 1px;
 }
 
 .EngagementScore__GradeSubheading {
    font-size: 13px;
    opacity: 0.75;
    margin-bottom: 1.2rem;
 }
 
 .EngagementScore__GradeWrapper {
    display: flex;
 }
 
 .EngagementScore__GradeTitle {
    font-size: 13px;
    font-weight: bold;
 }
 
 .EngagementScore__GradeDesc {
    font-size: 12px;
    line-height: 1.35em;
    opacity: 0.75;
 }
 
 .EngagementScore__Grade {
    border-radius: 4px;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    width: 60px;
    height: 60px;
    font-weight: bold;
 }
 
 .EngagementScore__Grade--A {
    background: #c3e3b8;
 }
 .EngagementScore__Grade--B {
    background: #bce0f8;
 }
 .EngagementScore__Grade--C {
    background: #fcea97;
 }
 .EngagementScore__Grade--D {
    background: #f7c793;
 }
 .EngagementScore__Grade--F {
    background: #f7c793;
 }
 
 .EngagementScore__GradeText {
    margin-left: 1rem;
 }