/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/
.social__wrapper {
    display: flex;
    flex-direction: column;
}

.social__flex__row {
    display: flex;
    align-items: flex-start;
}

.social__flex__column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.social__selector__flex__column {
    display: flex;
    justify-content: space-between;
}

.social__facebook, .social__twitter,  .social__youtube {
    margin: 10px;
    height: 70px;
    width: 75px;
    border: 3px solid #EBE8ED;
    border-radius: 5px;
    background-color: #ffffff;
    /* background-color: #F9FAFB; */
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.social__instagram {
    margin: 0 10px 10px 10px;
    height: 70px;
    width: 75px;
    border: 3px solid #EBE8ED;
    border-radius: 5px;
    background-color: #ffffff;
    /* background-color: #F9FAFB; */
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.social__facebook:hover, .social__twitter:hover, .social__instagram:hover, .social__youtube:hover{
    background-color: rgba(179, 188, 245, 0.1);
    padding: 5px;
    border: 3px solid #ff2754;
}

.social__active {
    border: 3px solid #ff2754;
}

.social__icon {
    height: 30px;
    width: 30px;
}

.status__message {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.social__Link__Status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.social__profile__details__card {

}

.social__account__card {
    width: '50%';
    max-width: '50%';
}

.social__account__details__card {
    width: '50%';
    max-width: '50%';
}

.affiliate-social__section-label {
    width: 100%;
    background-color: #f3f4f7;
    margin-top: 3rem;
}

.affiliate-social__section-title {
    color: #7e8f9f;
    text-transform: uppercase;
    margin-left: 4rem;
}

.affiliate-social__social-platform {
    max-width: 570px;
    min-width: 432px;
    border: 3px solid #EBE8ED;
    border-radius: 5px;
    min-height: 340px;
    margin-left: 1rem;
}

.social-settings__featured-page{
    padding: 0 2rem;
    margin-bottom: 3rem;
}

.social-settings__featured-page h2{
    font-weight: bold;
    font-size: 1.7rem;
}

.social-settings__featured-page .page{
    display: flex;
    align-items: center;
    margin-top: 1.6rem;
}
.social-settings__featured-page img{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 12px;
    vertical-align: middle;
}

.social-settings__featured-page .info{
    margin-right: 1.2rem;
    line-height: 1.35em;
}

.social-settings__featured-page .info b{
    display: block;
}
.social-settings__featured-page .info span{
    color: #637381;
    font-size: 1.3rem;
}

.social-settings__featured-page button.edit{
    background:#FD2856 url(../../assets/edit-white.svg) no-repeat 1.5rem .5rem;
    background-size: 12px 12px;
    padding-left: 3.6rem;
}

@media screen and (max-width: 870px) {
    .social__instagram{
        margin-top: 1rem;
    }
    .social__flex__row {
        flex-wrap: wrap;
    }

    .social__wrapper {
        flex-direction: row;
        align-items: space-between
    }

    .social__facebook, .social__twitter, .social__instagram, .social__youtube {
        height: 60px;
        width: 65px;
    }

    .affiliate-social__section-title {
        margin-left: 2rem;
    }

    .affiliate-social__social-platform {
        width: 95%;
        min-height: auto;
    }
}

@media screen and (max-width: 460px) {
    .affiliate-social__social-platform {
        min-width: auto;
    }
}
