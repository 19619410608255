.settings-shop__section-label {
    width: 100%;
    background-color: #f3f4f7;
    margin-top: 3rem;
}

.settings-shop__section-title {
    color: #7e8f9f;
    text-transform: uppercase;
    margin-left: 4rem;
}

.settings-categories-subtitle {
    font-size: 1em;
}

.settings-shop--input-label {
    color: hsl(0, 0%, 45%);
}

.settings-shop__input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 4rem;
    border-bottom: 1px solid #EFEFEF;
}

.settings-shop__edit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.settings-shop__edit-text {
    font-size: .75rem;
    color: #ff2754;
    margin-left: 1rem;
}

.settings-shop__display {
    border: none;
    font-size: 1em;
    text-align: right;
    margin-right: 2em;
    padding: 0.4rem;
    border: 1px solid transparent;
    border-top: none;
    border-bottom: none;
    height: 3rem;
}

.settings-shop__input {
    border: 1px solid #7e8f9f;
    font-size: 1em;
    text-align: right;
    margin-right: 2em;
    padding: 0.4rem;
    border-radius: 3px;
    width: 30rem;
    height: 3rem;
}

.settings-shop__edit-text {
    font-size: .75rem;
    color: #ff2754;
    margin-left: 1rem;
    cursor: pointer;
    width: 3rem;
    text-align: center;
}

.settings-shop__save {
    font-size: .75rem;
    color: #fff;
    cursor: pointer;
    background: #ff2754;
    border-radius: 999px;
    width: 3rem;
}

.settings-shop__primary .Polaris-Frame {
    background-color: #ffffff;
}

.settings-categories{
    list-style: none;
    columns: 3;
    column-gap: 30px;
    padding: 0 1.5rem;
}

.settings-categories li{
    margin-bottom: 5px;
}

.settings-categories li.category-loading{
    margin-bottom: 25px;
}

@media screen and (max-width: 870px){
    .settings-categories{
        columns: 2
    }
}

@media screen and (max-width: 599px) {
    .settings-shop__input-row {
        padding: 1rem 1.25rem;
    }

    .settings-shop__section-title {
        margin-left: 1.25rem;
    }

    .settings-shop__section-title {
        font-size: 1.2rem;
    }

    .settings-shop--input-label {
        font-size: 1.2rem;
    }

    .settings-shop__input {
        font-size: 1.2rem;
    }

    .settings-shop__display {
        font-size: 1.2rem;
        margin-right: .5rem;
    }

    .settings-shop__edit-text {
        width: 2rem;
    }

    .settings-shop__save {
        width: 2rem;
    }

    .settings-categories{
        padding-left: 1.45rem;
    }

    .settings-categories li{
        margin-bottom: 10px;
    }

    .settings-categories .Polaris-Choice__Label{
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 489px){
    .footer{
        padding: 2rem;
    }
}

@media screen and (max-width: 410px){
    .settings-categories{
        margin-bottom: 0;
        columns: 1
    }
}