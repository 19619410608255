#help-widget{
    position: fixed;
    bottom: 4px;
    right: 4px;
    z-index: 2000;
}

#help-widget button {
    border: none;
}

#help-widget .llama-head {
    width: 72px;
    height: 92px;
    margin: 0 9px 8px 0;
    text-indent: -99999px;
    position: relative;
    background: url(../../assets/help-leo.png) right bottom;
    cursor: pointer;
}

#help-widget .llama-head::after{
    content: '?';
    color: #fff;
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-weight: bold;
    text-align: center;
    background: #33135C;
    border-radius: 100%;
    position: absolute;
    top: 2px;
    right: 7px;
    text-indent: 0;
    font-size: 1.2rem;
}

/* POPOUT MENU */
#help-widget .menu{
    background: #EBECF0;
    border-radius: 5px;
    position: absolute;
    bottom: 20px;
    right: 90px;
    min-width: 200px;
    padding: 10px;
    border: 1px solid #cecece;
    box-shadow: 0 3px 7px rgba(0, 0, 0, .12);
    font-size: 1.3rem;
    line-height: 1.4em;
}

#help-widget .menu::after{
    content: '';
    position: absolute;
    border-left: 10px solid #cecece;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    right: -10px;
    bottom: 15px;
}

#help-widget .menu ul{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

#help-widget .menu li{
    margin-bottom: 10px;
}

#help-widget .menu li:last-child{
    margin-bottom: 0;
}

#help-widget .menu li.chat{
    padding-top: 10px;
    border-top: 1px solid #ccc;
}

#help-widget .menu button,
#help-widget .menu .btn{
    border-radius: 20px;
    background: #fd2a54;
    color: #fff;
    text-align: center;
    width: 100%;
    display: block;
    padding: 6px 8px;
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: none;
    font-family: inherit;
    line-height: 1.4em;
    white-space: nowrap;
}

#help-widget .menu .button-group{
    max-height: 370px;
    overflow: auto;
}

#help-widget .menu .button-group > li{
    margin-bottom: 2rem;
}

#help-widget .menu .button-group > li:last-child{
    margin-bottom: 0;
}

#help-widget .menu ul.button-group .heading{
    font-weight: bold;
    font-size: 1.05em;
    margin-bottom: 0.5rem;
}

#help-widget .menu p.kb-link,
#help-widget .menu p.video{
    margin-top: 1rem;
}

#help-widget .menu p.kb-link{
    line-height: 1.3em;
}

#help-widget .menu p.kb-link a{
    color: #fd2a54;
    text-decoration: none;
    font-weight: bold;
}

#help-widget .menu .help-content{
    width: 25rem;
    max-height: 370px;
    overflow: auto;
}

#help-widget .menu .help-content p{
    margin-bottom: 1rem;
}

#help-widget .menu .help-content ol{
    padding-left: 2rem;
    margin-bottom: 0;
}

#help-widget .menu .help-content ul{
    list-style: disc;
}

#help-widget .menu .help-content img{
    max-width: 100%;
    max-height: 170px;
    cursor: pointer;
}

.help-enlarged-img{
    text-align: center;
}

/* BACK BUTTON */
#help-widget .menu button.back{
    background :none;
    color: #4d4d4d;
    width: auto;
    text-align: left;
    position: relative;
    padding-left: 8px;
}

#help-widget .menu button.back::before{
    content: '';
    position: absolute;
    border-left: 1px solid #4d4d4d;
    border-bottom: 1px solid #4d4d4d;
    width: 6px;
    height: 6px;
    top: calc(50% - 4px);
    left: 0px;
    transform: rotate(45deg);
}

/* VIDEO */
#help-video{
    width: 580px;
    height: calc((9/16) * 580px);
}

/* CLOSE DRIFT OVERLAY */
#help-widget .close-drift{
    width: 65px;
    height: 65px;
}

#help-widget .close-drift button{
    width: 100%;
    height: 100%;
    text-indent: -99999px;
    cursor: pointer;
    background: none;
}

.App__Main{
    margin-bottom: 110px;
}

.Polaris-Modal-Dialog__Container{
    z-index: 3000 !important;
}

.Polaris-Modal-CloseButton--withoutTitle{
    z-index: 10;
}

@media screen and (max-width: 768px){
    #help-video{
        width: calc(100vw - 40px);
        height: calc((9/16) * (100vw - 40px));
    }
}

@media screen and (max-width: 390px){
    #help-widget .menu .help-content{
        /* page width - right - container padding - page left offset */
        width: calc(100vw - 90px - 20px - 20px);
    }
}