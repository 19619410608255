/*  BREADCRUMBS TO NAVIGATE BACK FROM DEMO  */
.affiliate-ig__hype-wrapper-locked {
    padding: 0 2rem;
    max-width: 1440px;
}
.hype-locked__breadcrumbs-wrapper {
    display: flex;
    width: 70px;
}
.hype-locked__breadcrumbs-wrapper p {
    color: #637381;
    font-size: 1.4rem;
    cursor: pointer;
}
.hype-locked__breadcrumbs-wrapper .Polaris-Icon .Polaris-Icon--isColored {
    color: #637381;
}


/*  HARDCODED STAT SECTION  */
.hype-locked__affiliate-stats {
    display: flex;
    align-items: center;
    padding: 0 2rem;
}
.hype-locked__stat-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 2rem;
}
.hype-locked__stat-item:first-child {
    padding-left: 0;
}
.hype-locked__stat-item h2 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 3.2rem;
    min-width: 3rem;
}
.hype-locked__stat-item p {
    color: #888888;
    width: 100%;
    min-width: 3rem;
}


/*  NAVIGATE BACK TO UPSELL */
.hype-locked__header-wrapper {
    display: flex;
}
.hype-locked__header-wrapper h3 {
    color: #747474;
    font-size: 1.6rem;
    font-weight: bold;
}
.hype-locked__header-wrapper p {
    cursor: pointer;
    color: #fd2954;
    margin-left: 1rem;
}



/*  MAIN SECTION    */
.hype-locked__wrapper {
    background-color: #efefef;
    padding: 1.2rem 2rem;
    display: flex;
    flex-wrap: wrap;
}
.hype-locked__section-content {
    background-color: #ffffff;
    width: 400px;
    margin: 2rem 2rem 2rem 0;
}
.hype-locked__section h3 {
    font-weight: bold;
    font-size: 1.6rem;
}
.hype-locked__section-items {
    padding-bottom: 1rem;
}
.hype-locked__locked-item {
    margin: 0.5rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.hype-locked__section-top{
    border-bottom: 2px solid #f0f0f0;
    padding: 1.2rem 0;
}
.hype-locked__section-top h3 {
    font-weight: bold;
    margin-left: 2rem;
}
.hype-locked__locked-item img {
    height: 14px;
}

@media screen and (max-width: 599px) {
    .hype-locked__section h3 {
        font-size: 1.4rem;
    }
    .hype-locked__section-top h3 {
        font-size: 1.4rem;
        margin-left: 1rem;
    }
    .hype-locked__locked-item {
        font-size: 1.125rem;
        margin: 0.5rem 1rem;
    }
    .hype-locked__locked-item img {
        height: 12px;
    }
    .hype-locked__section-content {
        width: 320px;
    }
}