#smartlinks__customize-panel{
    display: flex;
}

/* category nav */
#smartlinks__customize-panel nav{
    background: #FAFAFA;
    border: 1px solid #E0E0E0;
    width: 178px;
    margin-right: 30px;
    flex-shrink: 0;
}

#smartlinks__customize-panel ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

#smartlinks__customize-panel nav li{
    position: relative;
}

#smartlinks__customize-panel nav li[data-alert="true"]::after{
    content: '';
    background: #e59422 url(../../assets/notifications/warning.svg) no-repeat calc(50%) calc(50% - 1px);
    background-size: 12px 12px;
    border-radius: 100%;
    color: #fff;
    width: 22px;
    height: 22px;
    position: absolute;
    right: 10px;
    top: calc(50% - 11px);   
}

#smartlinks__customize-panel nav li:not(:first-child){
    margin-top: -1px;
}

#smartlinks__customize-panel nav a,
#smartlinks__customize-panel .current-page{
    text-decoration: none;
    display: block;
    padding: 13px 15px 13px 38px;
    color: var(--p-text, #212b36);
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    background-repeat: no-repeat;
    background-position: 15px center;
    background-size: auto 15px;
}

#smartlinks__customize-panel nav li:first-child a{
    border-top: none;
}

#smartlinks__customize-panel nav a.active,
#smartlinks__customize-panel nav a:hover{
    background-color: #F7FBFF;
    border-top-color: #E0E0E0;
    border-bottom-color: #E0E0E0;
    z-index: 100;
    font-weight: bold;
}

#smartlinks__customize-panel nav .general a,
#smartlinks__customize-panel .current-page.general{
    background-image: url(../../assets/smartlink-img/settings-general-icon.svg);
}
#smartlinks__customize-panel nav .themes a,
#smartlinks__customize-panel .current-page.themes{
    background-image: url(../../assets/smartlink-img/settings-themes-icon.svg);
}
#smartlinks__customize-panel nav .integrations a,
#smartlinks__customize-panel .current-page.integrations{
    background-image: url(../../assets/smartlink-img/settings-integrations-icon.svg);
}
#smartlinks__customize-panel nav .social a,
#smartlinks__customize-panel .current-page.social{
    background-image: url(../../assets/smartlink-img/settings-social-icon.svg);
}

/* form fields */
#smartlinks__customize-panel .panel-content{
    width: 100%;
    max-width: 375px;
}
#smartlinks__customize-panel fieldset{
    border: none;
    padding: 0;
    margin-bottom: 40px;
    display: block;
}

#smartlinks__customize-panel legend{
    margin: 0;
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 1.7rem;
    display: block;
}

#smartlinks__customize-panel fieldset li{
    margin-bottom: 2rem;
}

#smartlinks__customize-panel fieldset[data-pro-locked="true"]{
    opacity: .8;
}

#smartlinks__customize-panel [data-pro-locked="true"] .Polaris-Label label::after,
#smartlinks__customize-panel [data-pro-locked="true"] .Polaris-Choice__Label::after,
#smartlinks__customize-panel [data-pro-locked="true"] legend::after,
#smartlinks__customize-panel label[data-pro-locked="true"]::after{
    content: 'PRO';
    display: inline-block;
    margin-left: 5px;
    background: #34135D;
    color: #fff;
    font-weight: bold;
    padding: 3px 5px;
    border-radius: 4px;
    font-size: 0.7em;
    line-height: 1.4em;
    opacity: 1;
    vertical-align: middle;
    margin-top: -4px;
}

#smartlinks__customize-panel .Polaris-Labelled__HelpText{
    font-size: 1.2rem;
    line-height: 1.4em;
}

#smartlinks__customize-panel .Polaris-Labelled__HelpText a{
    color: #fd2a54;
    text-decoration: none;
}

#smartlinks__customize-panel .button-group{
    text-align: right;
    margin-top: 8px;
}

#smartlinks__customize-panel .button-group button{
    display: inline-block !important;
}

#smartlinks__customize-panel .button-group button.cancel{
    margin-right: 5px;
    color: #fd2a54;
    border: none;
    background: none;
    cursor: pointer;
}

#smartlinks__customize-panel .pro-banner{
    margin-top: -2rem;
}

#smartlinks__customize-panel .pro-banner .Polaris-Button{
    background: #fd2a54;
    border: none;
    color: #fff;
}

@media screen and (max-width: 1120px) {
    #smartlinks__customize-panel nav{
        width: 155px;
    }
}

@media screen and (max-width: 780px){
    #smartlinks__customize-panel{
        flex-direction: column;
    }

    #smartlinks__customize-panel nav{
        margin: -3rem 0 3rem;
        width: 100%;
    }

    #smartlinks__customize-panel .current-page{
        border: none;
        border-bottom: 4px solid #fd2a54;
        color: #fd2a54;
        padding-top: 2rem;
        padding-bottom: 1.5rem;
        background-color: transparent;
        width: 100%;
        margin-bottom: 3rem;
        text-align: left;
        font-weight: bold;
        position: relative;
        font-size: 1em;
    }

    #smartlinks__customize-panel .current-page::after{
        position: absolute;
        content: url(../../assets/dropdown-arrow.svg);
        width: 20px;
        height: 12px;
        transition: transform 300ms ease-in-out;
        right: 12px;
        top: calc(50% - 6px);
    }

    #smartlinks__customize-panel .current-page.open::after{
        transform: rotate(180deg);
    }

    #smartlinks__customize-panel .panel-content{
        max-width: none;
    }
}