.LlamaDatePicker__DateFilter {
    font-weight: bold;
    color: #222222;
    position: relative;

    display: flex;
    flex-direction: row;
}

.LlamaDatePicker__DateFilter .date-picker-trigger{
    border: none;
    background: none;
    padding: 0;
    white-space: nowrap;
    font-weight: bold;
    border-bottom: 2px solid transparent;
}

.LlamaDatePicker__DateFilter .date-picker-trigger:hover {
    color: #222222;
    border-bottom: 2px solid #FB2956;
    cursor: pointer;
}

.LlamaDatePicker__DateFilter-- .date-picker-trigger {
    color: #919EAA;
    cursor: unset;
}

.LlamaDatePicker__DateFilter--disabled .date-picker-trigger:hover {
    border-bottom: 2px solid transparent;
    color: #919EAA;
}

.LlamaDatePicker__PopoverLocator {
    position: relative;
    height: 0;
    align-self: flex-end;
}

.LlamaDatePicker__DatePopover {
    position: absolute;
    z-index: 100;
    top: 0.6rem;
    right: 0;
    background: #ffffff;
    box-shadow: 0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
    border: 1px solid #EBE8ED;
}

.LlamaDatePicker__DatePicker {
    padding: 2rem;
}

.LlamaDatePicker__NamedDates {
    border-bottom: 1px solid #EBE8ED;
    margin: 0;
    padding: 1rem 0;
}

.LlamaDatePicker__NamedDates li {
    list-style: none;
}

.LlamaDatePicker__NamedDates li button {
    background: none;
    border: none;
    border-radius: 0;
    width: 100%;
    padding: 1rem 2rem;
    text-align: left;
    font-weight: bold;
    cursor: pointer;
}

.LlamaDatePicker__NamedDates li button:hover{
    background: #dfe3e8;
}

.LlamaDatePicker__Icon {
    display: inline-block;
    vertical-align: middle;
    height: 2rem;
    width: 2rem;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
}