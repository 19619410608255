/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/
 
.settings-payment__primary {
    display: block;
    margin: 0 auto;
    width: 90%;
    margin-bottom: 20px;
}

.settings-payment__wrapper {
    padding: 20px;
    padding-right: 40px;
    margin: 0 auto 20px;
    width: 50%;
}

.paypal-button-container{
    border-top: 1px solid #e5e5e5;
    margin-top: 8px;
    padding-top: 20px;
}

.settings-payment__disconnect {
    color: #FB2956;
    text-decoration: underline;
}

.paypal-button-container img{cursor: pointer;}
.paypal_close{text-align: center; margin-top: 10px; color: #007ace; cursor: pointer;}

.smartlinks-links .loading,
.notifications-settings__wrapper .loading
{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.pplogin{text-align: center;}
.pplogin img{cursor: pointer;}


/*CSS for onboarding PayPal view*/ 

.onboarding--paypal-connected{
    background: url(../../assets/paypal-logo.svg) no-repeat center top;
    background-size: 82px 20px;
    padding-top: 12.7rem;
    position: relative;
    font-size: 1.6rem;
}

.onboarding--paypal-connected .settings-payment__disconnect{
    font-size: .875em;
    display: block;
    text-align: center;
    cursor: pointer;
}

.onboarding--paypal-connected::before,
.onboarding--paypal-connected::after{
    content: '';
    position: absolute;
}

.onboarding--paypal-connected::before{
    top: 4.3rem;
    left: calc(50% - 3rem);
    width: 6.1rem;
    height: 6.1rem;
    border: 3px solid #008826;
    border-radius: 100%;
}

.onboarding--paypal-connected::after{
    width: 3.1rem;
    height: 1.7rem;
    border-left: 3px solid #008826;
    border-bottom: 3px solid #008826;
    transform: rotate(-45deg);
    top: 6rem;
    left: calc(50% - 1.5rem);
}

@media screen and (max-width: 800px) {
    .settings-payment__wrapper {
        padding-right: 20px;
    }
}

@media screen and (max-width: 599px) {
    
    .settings-payment__wrapper {
        width: 100%;
    }
}