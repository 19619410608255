.dropzone-files{
    list-style: none;
    padding-left: 0;
    margin: 0
}

.dropzone-files li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .75rem 0 .75rem 1.5rem;
}

.dropzone-files li:hover{
    background: #f7f7f7;
}

.dropzone-files li button{
    height: 40px;
    width: 40px;
    background: none;
    border: none;
    cursor: pointer;
}

.dropzone-files .Polaris-Thumbnail{
    margin-right: 1.5rem;
}

.dropzone-files div{
    margin-right: auto;
}

.dropzone-delete {
    width: 1rem;
    height: auto;
    opacity: 0.4;
    cursor: pointer;
}

.dropzone-delete:hover {
    opacity: 0.6;
}