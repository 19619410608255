:root{
  --llama-pink: #fd2a54;
  --llama-subdued: #637381;
  --llama-main: #212B36;
  --llama-purple: #34135D;
  --llama-border: #E0E0E0;
  --llama-gray-bg: #F4F6F8;
}

.App {
  display: flex;
  min-height: calc(100vh - 8rem);
  background: #FFFFFF;
}

.App__Main {
  flex: 9;
  min-height: 100%;
}

.sidebar {
  flex: 1;
}

#drift-widget {
  bottom: 0 !important;
  right: 0 !important;
}

#drift-widget-container,
.drift-conductor-item{
  display: none !important;
  z-index: 1000 !important;
}

.custom-banner {
  width: 30%;
  position: absolute !important;
  top: 12%;
  right: 2%;
}

.custom-banner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: initial;
}

.custom-banner-loader {
  width: 14;
}

/* hide for users but not screen readers */
.visually-hidden{
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
}

/** 
 *  COLORS:
 *
 *  DARK PURPLE: #34135D
 *  PURPLE: #4C2A93
 *  LIGHT PURPLE: #6D4AB6
 *  GRADIENT: linear-gradient(90deg, rgba(254,161,56,1) 0%, rgba(253,42,84,1) 100%);
 *  YELLOW: #F3AA4A
 *  PINK: #EB4159
 *  GREEN: #49AE6B
 *  RED: #F6323F
 */


 .App__Main .Polaris-Card {
  border-radius: 0.5rem;
  border: 3px solid #EBE8ED;
  /* padding: 1rem; */

  box-shadow: 0rem 1rem 2rem rgba(0, 0, 0, 0.1);
}

.App__Main .Polaris-Button {
  border-radius: 999px;
}

.App__Main .Polaris-Button--primary,
.App__Main .Polaris-Button--primary:hover {
  background: rgba(253,42,84,1);
  border: none;
  box-shadow: none;
  z-index: 100;
  opacity: 1;
  transition: all 100ms ease-out;
}

.App__Main .Polaris-Button--primary:hover {
  opacity: 0.8;
}

.App__Main .Polaris-Checkbox__Backdrop {
  border-radius: 1px;
}

.App__Main .Polaris-Checkbox__Backdrop::after {
  background: #FFFFFF;
  border-radius: 1px;
}

.App__Main .Polaris-Select__Content,
.App__Main .Polaris-Select__Backdrop {
    background: #FFFFFF;
    box-shadow: none;
    border: 0.1rem solid #c4cdd5;
    border-radius: 3px;
}

.App__Main .Polaris-Frame-Toast,
.Polaris-Frame-ToastManager .Polaris-Frame-Toast {
  background:  #33185C;
}

.App__Main .Polaris-Frame-Toast--error {
  background:#f8333c; 
}

.App__Main .Polaris-Frame {
  min-height: auto;
}

.App__Main .Polaris-Badge{
  text-transform: uppercase;
  padding: 0.1rem 1.3rem;
}

.App__Main .Polaris-Badge--statusSuccess {
  border: none;
  background: #bbe5b3;
  color: #414f3e;
  font-weight: bold;
}

.App__Main .Polaris-Badge--statusAttention {
  border: none;
  background: #ffea8a;
  color: #595130;
  font-weight: bold;
}

.App__Main .Polaris-Badge--statusWarning {
  border: none;
  background: #ffc58b;
  color: #594430;
  font-weight: bold;
}

.App__Main .Polaris-Badge--statusCritical {
  border: none;
  background: #ff8b8b;
  color: #593030;
  font-weight: bold;
}

.App__Main .plain-button{
    all: unset;
    color: var(--llama-pink);
    cursor: pointer;
}

.accessible-click-wrapper{
  all: unset;
  cursor: pointer;
}

.nivo-graph-tooltip-wrapper{
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  padding: 5px 9px;
}

.nivo-graph-tooltip-item{
  display: flex;
  white-space: pre;
  align-items: center;
  margin-bottom: 9px;
}

.nivo-graph-tooltip-item:last-child{
  margin-bottom: 0;
}

.nivo-graph-tooltip-color-block{
  display: block;
  width: 17px;
  height: 17px;
  margin-right: 9px;
}

.nivo-graph-tooltip-date{
  font-size: .8em;
  font-weight: bold;
  display: block;
  margin-bottom: 6px;
}

@media screen and (max-width: 599px) {
  .App {
    display: flex;
    flex-wrap: wrap;
    min-height: calc(100vh - 6rem);
  }

  .App__Main {
    flex: 10;
    width: 100%;
  }

  .App__Navigation-Sidebar {
    width: 100%;
    flex: 10;
  }

}

@media screen and (max-width: 490px){
  .Polaris-Page__Content{
      padding: 0 2rem;
  }
}