.smartlink-preview .loading{
    display: flex;
    justify-content: center;
    align-items: center;
}

.customTheme-live-preview[data-visible="false"]{
    display: none;
}

.smartlink-preview .previewTheme{
    background: url(../../assets/smartlink-img/mobile-preview-black.svg) center no-repeat;
    background-size: contain;
    position: relative;
    width: 230px;
    height: 499px;
    padding: 63px 13px 69px;
    margin: 0 auto;
    font-size: 5px;
}
.smartlink-preview .previewTheme *{
    margin: 0;
    padding: 0;
}
.smartlink-preview .preview-container{
    min-height: 100%;
    position: relative;
}
.smartlink-preview .previewTheme .mobile::-webkit-scrollbar {
    width: 6px;
}
.smartlink-preview .previewTheme .mobile::-webkit-scrollbar-track {
    background : rgba(0,0,0,0.1);
    border-radius: 6px;
    transition: background-color .2s linear,height .2s linear,
                width .2s ease-in-out,border-radius .2s ease-in-out;
}
.smartlink-preview .previewTheme .mobile::-webkit-scrollbar-thumb {
    background : rgba(0,0,0,0.3);
    border-radius: 6px;
    box-shadow:  0 0 2px rgba(0, 0, 0, 0.5);
}

/* preview container */
.smartlink-preview .previewTheme .preview-area-scroll[data-theme="theme-default"]{
    background: #fff;
    --sl-preview-headline: #34135D;
    --sl-preview-link-border: #34135D;
    --sl-preview-link-bg: #34135D;
    --sl-preview-link-text: #fff;
    --sl-preview-image-bg: rgba(52, 19, 93, .3);
    --sl-preview-image-border: rgba(52, 19, 93, .3);
}
.smartlink-preview .previewTheme .preview-area-scroll[data-theme="theme-steel"]{
    background: #535256;
    --sl-preview-headline: #fff;
    --sl-preview-link-border: #688aa4;
    --sl-preview-link-bg: #688aa4;
    --sl-preview-link-bg-hover: #fff;
    --sl-preview-link-text: #fff;
    --sl-preview-image-bg: rgba(255,255,255,.5);
    --sl-preview-image-border: rgba(255,255,255,.5);
}
.smartlink-preview .previewTheme .preview-area-scroll[data-theme="theme-camo"]{
    background: #5d8c6a;
    --sl-preview-headline: #fff;
    --sl-preview-link-border: #992c23;
    --sl-preview-link-bg: #992c23;
    --sl-preview-link-text: #fff;
    --sl-preview-link-text-hover: #992c23;
    --sl-preview-image-bg: rgba(255,255,255,.5);
    --sl-preview-image-border: rgba(255,255,255,.5);
}
.smartlink-preview .previewTheme .preview-area-scroll[data-theme="theme-snow"]{
    background: #fff;
    --sl-preview-headline: #3d3b3c;
    --sl-preview-link-border: #3d3b3c;
    --sl-preview-link-bg: #3d3b3c;
    --sl-preview-link-text: #fff;
    --sl-preview-image-bg: rgba(61, 59, 60, 0.3);
    --sl-preview-image-border: rgba(61, 59, 60, 0.3);
}
.smartlink-preview .previewTheme .preview-area-scroll[data-theme="theme-punk-rock"]{
    background: #19191a;
    --sl-preview-headline: #fff;
    --sl-preview-link-border: #8a0739;
    --sl-preview-link-bg: #a40e4a;
    --sl-preview-link-text: #fff;
    --sl-preview-link-text-hover: #a40e4a;
    --sl-preview-image-bg: rgba(255,255,255,.5);
    --sl-preview-image-border: rgba(255,255,255,.5);
}
.smartlink-preview .previewTheme .preview-area-scroll[data-theme="theme-bloom"]{
    background: linear-gradient(#694ff6, #ffb9c6);
    --sl-preview-headline: #fff;
    --sl-preview-link-border: #fff;
    --sl-preview-link-bg: transparent;
    --sl-preview-link-text: #fff;
    --sl-preview-link-text-hover: #c18fd7;
    --sl-preview-image-bg: rgba(255,255,255,.5);
    --sl-preview-image-border: rgba(255,255,255,.5);
}
.smartlink-preview .previewTheme .preview-area-scroll[data-theme="theme-spray"]{
    background: linear-gradient(#76f3fa, #3a4eff);
    --sl-preview-headline: #fff;
    --sl-preview-link-border: #fff;
    --sl-preview-link-bg: transparent;
    --sl-preview-link-text: #fff;
    --sl-preview-link-text-hover: #5ba2f9;
    --sl-preview-image-bg: rgba(255,255,255,.5);
    --sl-preview-image-border: rgba(255,255,255,.5);
}
.smartlink-preview .previewTheme .preview-area-scroll[data-theme="theme-lemon-lime"]{
    background: #dfd51b;
    --sl-preview-headline: #1d591e;
    --sl-preview-link-border: #1d591e;
    --sl-preview-link-bg: #1d591e;
    --sl-preview-link-text: #fdf88d;
    --sl-preview-link-text-hover: #1d591e;
    --sl-preview-image-bg: rgba(255,255,255,.5);
    --sl-preview-image-border: rgba(255,255,255,.5);
}
.smartlink-preview .previewTheme .preview-area-scroll[data-theme="theme-pretty-in-pink"]{
    background: #cc1a74;
    --sl-preview-headline: #fff;
    --sl-preview-link-border: #fdc2d9;
    --sl-preview-link-bg: #fdc2d9;
    --sl-preview-link-text: #a4115c;
    --sl-preview-link-text-hover: #cc1a74;
    --sl-preview-image-bg: rgba(255,255,255,.5);
    --sl-preview-image-border: rgba(255,255,255,.5);
}
.smartlink-preview .previewTheme .preview-area-scroll[data-theme="theme-gold"]{
    background: linear-gradient(#a77227, #f8e18c, #a77227);
    --sl-preview-headline: #58380b;
    --sl-preview-link-border: #58380b;
    --sl-preview-link-bg: transparent;
    --sl-preview-link-text: #58380b;
    --sl-preview-link-text-hover: #58380b;
    --sl-preview-image-bg: rgba(255,255,255,.5);
    --sl-preview-image-border: rgba(255,255,255,.5);
}

.smartlink-preview .previewTheme .preview-area-scroll{
    display: flex;
    justify-content: center;
    text-align: center;
    font: 10px Helvetica, Arial, sans-serif;
    width: 100%; 
    height: 100%;
    overflow: auto;
    padding: 2rem 1.5rem 3rem;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    --sl-preview-link-border-radius: 4px;
    --sl-preview-link-text-hover: var(--sl-preview-link-bg);
    --sl-preview-link-bg-hover: #fff;
}

.smartlink-preview .preview-area-scroll ul{
    list-style: none;
}

.smartlink-preview .preview-area-scroll a{
    color: inherit; 
    display: block; 
    text-decoration: none;
}

/* NSFW */
.smartlink-preview .nsfw-banner{
    background: #f1e100;
    color: #584b00;
    position: absolute;
    width: 204px;
    top: 63px;
    left: calc(50% - 102px);
    padding: 1.2rem;
    font-size: 1.6em;
    z-index: 20;
}

.smartlink-preview .nsfw-heading{
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: .3rem;
}

.smartlink-preview .nsfw-banner p{
    line-height: 1.3em;
}

.smartlink-preview .nsfw-banner:not(.overlay) + div .content{
    margin-top: 5rem;
}

/* NSFW OVERLAY */
.smartlink-preview .nsfw-banner.overlay{
    background: rgba(0, 0, 0, .7);
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 63px - 69px);
    bottom: 0;
    font-size: 2em;
    padding: 1rem;
    text-align: center;
    --sl-preview-link-border-radius: 4px;
}

.smartlink-preview .nsfw-banner.overlay .nsfw-heading{
    margin-bottom: 1.2rem;
    font-size: 1.2em;
}

.smartlink-preview .nsfw-banner.overlay .close{
    margin-top: 1.8rem;
}

.smartlink-preview .nsfw-banner.overlay .close button{
    background: #fff;
    color: #000;
    font: inherit;
    border: 1px solid #fff;
    border-radius: var(--sl-preview-link-border-radius);
    padding: .7rem 1.8rem;
    cursor: pointer;
    font-weight: bold;
}

.smartlink-preview .nsfw-banner.overlay + div .content{
    filter: Blur(Strength=10); /*ie fix*/
    filter: blur(10px);
    opacity: .8;
}

.smartlink-preview .preview-area-scroll .content{
    width: 100%;
    max-width: 600px;
    margin: auto;
}

/* PHOTO */
.smartlink-preview .profile-picture{
    margin-bottom: 1.1rem;
}

.smartlink-preview .profile-picture img{
    border-radius: 100%;
    background: var(--sl-preview-image-bg);
    border: 1px solid var(--sl-preview-image-border);
    width: 73px;
    height: 73px;
}

/* HEADLINE */
.smartlink-preview .headline{
    font-size: 1.7em;
    font-weight: bold;
    color: var(--sl-preview-headline);
    margin-bottom: .6rem;
}

/* BIO */
.smartlink-preview .bio-wrapper{
    font-size: .8em;
}

.smartlink-preview .bio{
    color: var(--sl-preview-headline);
    line-height: 1.45em;
}
.smartlink-preview .bio[data-open="false"]{
    max-height: 4.2em;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.smartlink-preview .read-more button{
    border: none;
    background: none;
    padding: 0;
    color: var(--sl-preview-headline);
    font-weight: bold;
    font-family: inherit;
    cursor: pointer;
    margin-top: .2rem;
    font-size: .9em;
}

/* SOCIAL */
.smartlink-preview .social{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: .9rem;
}

.smartlink-preview .social li{
    margin-right: 1.6rem;
}

.smartlink-preview .social li:last-child{
    margin-right: 0;
}

.smartlink-preview .social a{
    text-indent: -99999px;
    width: 9px;
    height: 9px;
    background: var(--sl-preview-headline) no-repeat center center;
}
.smartlink-preview .social .facebook{
    -webkit-mask-image: url(../../assets/smartlink-img/smartlink-fb.svg);
    mask-image: url(../../assets/smartlink-img/smartlink-fb.svg);
}
.smartlink-preview .social .twitter{
    width: 10px;
    height: 8px;
    -webkit-mask-image: url(../../assets/smartlink-img/smartlink-tw.svg);
    mask-image: url(../../assets/smartlink-img/smartlink-tw.svg);
}
.smartlink-preview .social .instagram{
    -webkit-mask-image: url(../../assets/smartlink-img/smartlink-ig.svg);
    mask-image: url(../../assets/smartlink-img/smartlink-ig.svg);
}
.smartlink-preview .social .tiktok{
    -webkit-mask-image: url(../../assets/smartlink-img/smartlink-tiktok.svg);
    mask-image: url(../../assets/smartlink-img/smartlink-tiktok.svg);
}
.smartlink-preview .social .youtube{
    width: 13px;
    height: 9px;
    -webkit-mask-image: url(../../assets/smartlink-img/smartlink-yt.svg);
    mask-image: url(../../assets/smartlink-img/smartlink-yt.svg);
}
.smartlink-preview .social .soundcloud{
    width: 15px;
    height: 6px;
    -webkit-mask-image: url(../../assets/smartlink-img/smartlink-sc.svg);
    mask-image: url(../../assets/smartlink-img/smartlink-sc.svg);
}

/* LINKS */
.smartlink-preview .preview-links{
    margin-top: 1.6rem;
}

.smartlink-preview .preview-links li{
    margin-bottom: .8rem;
}

.smartlink-preview .preview-links li:last-child{
    margin-right: 0;
}

.smartlink-preview .preview-links a{
    padding: .7rem 1.1rem .8rem;
    border: 2px solid var(--sl-preview-link-border);
    border-radius: var(--sl-preview-link-border-radius);
    background: var(--sl-preview-link-bg);
    color: var(--sl-preview-link-text);
    width: 100%;
    transition: background .2s ease-in, color .2s ease-in;
    font-weight: 700;
    font-size: .9em;
}
.smartlink-preview .preview-links a:hover,
.smartlink-preview .previewTheme form button:hover{
    background: var(--sl-preview-link-bg-hover);
    color: var(--sl-preview-link-text-hover);
}

.smartlink-preview .preview-links a .company{
    font-size: .6em;
    opacity: .7;
    display: block;
    margin-bottom: 3px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .08em;
}

.smartlink-preview .preview-links .empty{
    color: var(--sl-preview-link-bg);
    font-weight: bold;
}

/* EMAIL SIGN UP */
.smartlink-preview .previewTheme form{
    margin-top: 1.4rem;
}

.smartlink-preview .form-heading{
    margin-bottom: .7rem;
    color: var(--sl-preview-headline);
    font-weight: bold;
    font-size: 1em;
}

.smartlink-preview .previewTheme form input,
.smartlink-preview .previewTheme form button{
    width: 100%;
    border: 2px solid var(--sl-preview-link-border);
    border-radius: var(--sl-preview-link-border-radius);
    font-family: inherit;
    font-size: .8em;
}

.smartlink-preview .previewTheme form input{
    background: #fff;
    padding: .8rem 1.2rem;
    text-align: center;
    margin-bottom: .4rem;
}

.smartlink-preview .previewTheme form button{
    background: var(--sl-preview-link-bg);
    padding: .8rem 1.2rem;
    color: var(--sl-preview-link-text);
    cursor: pointer;
    transition: background .2s ease-in, color .2s ease-in;
    font-weight: 700;
}

/* ATTRIBUTION */
.smartlink-preview .attribution{
    position: absolute;
    bottom: 69px;
    left: calc(50% - 67px);
}

.smartlink-preview .attribution a{
    display: block;
    border-radius: 28px 28px 0 0;
    width: 134px;
    height: 26px;
    text-indent: -99999px;
    background: #34135d url(../../assets/smartlink-img/attribution.png) no-repeat center center;
    background-size: 94px 16px;
    image-rendering: -webkit-optimize-contrast;
}

@media screen and (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
    .smartlink-preview .attribution a{background-image: url(../../assets/smartlink-img/attribution-2x.png);}
}


@media screen and (max-width: 1450px) and (min-width: 981px){
    .smartlink-preview .previewTheme{
        width: 190px;
        height: 412px;
        padding: 52px 10px 57px;
    }
    
    .smartlink-preview .previewTheme .preview-area-scroll{
        padding: 1.6rem 1.3rem 2.6rem;
        font-size: 1rem;
    }

    .smartlink-preview .nsfw-banner{
        width: 170px;
        left: calc(50% - 85px);
        top: 52px;
    }

    .smartlink-preview .nsfw-banner.overlay{
        height: calc(100% - 53px - 56px)
    }
    
    .smartlink-preview .profile-picture{
        margin-bottom: 1.2rem;
    }
    
    .smartlink-preview .profile-picture img{
        width: 64px;
        height: 64px;
    }
    
    .smartlink-preview .headline{
        font-size: 1.3rem;
    }
    
    .smartlink-preview .social{
        margin-top: 0.7rem;
    }

    .smartlink-preview .social li{
        margin-right: 1.1rem;
    }

    .smartlink-preview .social a{
        width: 10px;
        height: 10px;
    }
    .smartlink-preview .social .twitter{
        width: 10px;
        height: 8px;
    }
    .smartlink-preview .social .youtube{
        width: 14px;
        height: 10px;
    }
    .smartlink-preview .social .soundcloud{
        width: 17px;
        height: 7px;
    }
    
    .smartlink-preview .preview-links{
        margin-top: 2rem;
    }

    .smartlink-preview .preview-links a{
        padding: .5rem;
    }

    .smartlink-preview .attribution{
        bottom: 56px;
        left: calc(50% - 55px);
    }

    .smartlink-preview .attribution a{
        width: 110px;
        height: 21px;
        background-size: 77px 13px;
    }
}

@media screen and (max-width: 1120px) and (min-width: 981px){
    .smartlink-preview .previewTheme{
        width: 160px;
        height: 348px;
        padding: 43px 9px 48px;
    }
    
    .smartlink-preview .previewTheme .preview-area-scroll{
        padding: 1.5rem 1rem 2.6rem;
        font-size: .8rem;
    }

    .smartlink-preview .nsfw-banner{
        width: 142px;
        left: calc(50% - 71px);
        top: 43px;
    }

    .smartlink-preview .nsfw-banner.overlay{
        height: calc(100% - 43px - 48px)
    }
    
    .smartlink-preview .profile-picture{
        margin-bottom: 1rem;
    }
    
    .smartlink-preview .profile-picture img{
        width: 54px;
        height: 54px;
    }
    
    .smartlink-preview .headline{
        font-size: 1.1rem;
    }
    
    .smartlink-preview .social{
        margin-top: 0.6rem;
    }

    .smartlink-preview .social li{
        margin-right: .9rem;
    }

    .smartlink-preview .social a{
        width: 8px;
        height: 8px;
    }
    .smartlink-preview .social .twitter{
        width: 9px;
        height: 7px;
    }
    .smartlink-preview .social .youtube{
        width: 12px;
        height: 8px;
    }
    .smartlink-preview .social .soundcloud{
        width: 14px;
        height: 6px;
    }
    
    .smartlink-preview .preview-links{
        margin-top: 1.6rem;
    }

    .smartlink-preview .preview-links li{
        margin-bottom: 1rem;
    }

    .smartlink-preview .preview-links a{
        padding: .4rem;
    }

    .smartlink-preview .attribution{
        bottom: 47px;
        left: calc(50% - 46px);
    }

    .smartlink-preview .attribution a{
        width: 93px;
        height: 18px;
        background-size: 65px 11px;
    }
}

@media screen and (max-width: 980px) and (min-width: 601px){
    /* offset side nav */
    .smartlink-preview .previewTheme{
        transform: translateX(100px);
    }
}

@media screen and (max-width: 980px){
    .smartlink-preview .previewTheme::before,
    .smartlink-preview .previewTheme::after{
        content: ''; 
        position: absolute; 
        top: -5px; 
        right: -10px; 
        width: 15px; 
        height: 2px; 
        background: #fff;
    }
    .smartlink-preview .previewTheme::before{
        transform: rotate(45deg)
    }
    .smartlink-preview .previewTheme::after{
        transform: rotate(-45deg)
    }
}