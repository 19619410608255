.amplify-form-container {
    text-align: center;
    margin-top: 20px;
    margin: 5% auto 50px;
}
.signin-amplify__graphic-mobile {
    display: none;
}
.signin-amplify__login-graphic {
    position: absolute;
    right: calc(50% - 514px - 40px);
    top: calc(50% - 200px);
}
.signin-amplify__wrapper {
    height: calc(100vh - 80px);
    width: 100%;
    display: flex;
    align-items: center;
    background-image: url('../assets/llama-bg-new.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.signup-amplify__wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    background: #390C60;
}
.signin-amplify__graphic-wrapper {
    width: 100%;
}
.signin-amplify__signin-form {
    width: 100%;
    padding: 2rem 3rem;
}
.signin-amplify__llama-head {
    width: 75px;
    display: block;
    margin: 0 auto 0.6rem;
}
.signin-amplify__header {
    font-size: 21px;
    font-weight: bold;
    text-align: center;
    margin-bottom:  6px;
}
.signin-amplify__subheader {
    font-size: 16px;
    text-align: center;
    opacity: 0.75;
    margin-bottom: 40px;
}
.signup-amplify__subheader {
    font-size: 16px;
    text-align: center;
    opacity: 0.75;
    margin-bottom: 40px;
}
.signin-amplify__background-color {
    background: #390C60;
}
.signin-amplify__graphic {
    position: relative;
    width: 100%;
    height: 100%;
}
.signup-amplify__graphic-wrapper {
    background-image: url('../assets/llama-bg-new.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.signin-amplify__form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 5px;
    width: 400px;
    position: absolute;
    z-index: 100;
    left: calc(50% - 400px - 40px);
}
.amplify-wrapper {
    border: 3px solid rgb(235, 232, 237);
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 1rem 2rem;
    text-align: left;
    background: rgb(255, 255, 255);
    max-width: 460px;
    padding: 3rem 4rem 2rem;
    display: block;
    margin: 4rem auto;
}

.amplify-loading-wrapper {
    height: 50vh;
}

.amplify-loading-wrapper .llama-centered-spinner{
    align-items: center;
    height: 100%;
}

.amplify-form-section {
    border: 3px solid rgb(235, 232, 237);
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 1rem 2rem;
    text-align: left;
    display: inline-block;
    max-width: 460px !important;
    background: rgb(255, 255, 255);
}

.amplify-section-header {
    color: var(--deepSquidInk);
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 500;
}

.amplify-section-body {
    margin-bottom: 16px;
}

.amplify-section-body:last-child {
    margin-bottom: 0;
}

.amplify-form-row {
    width: 100%;
    flex: 1 1;
}

.amplify-label {
    color: var(--input-color);
    font-size: 14px;
    display: inline-block;
}

.amplify-input {
    display: block;
    width: 100%;
    padding: 14px;
    font-size: 14px;
    color: var(--input-color);
    background-color: var(--input-background-color);
    background-image: none;
    border: 1px solid var(--lightGrey);
    border-radius: 3px;
    box-sizing: border-box;
}

.amplify-input[name="phone"] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.amplify-label-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 8px;
}

.amplify-password-toggle {
    color: #FB2956;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
}

.amplify-password-toggle:hover {
    cursor: pointer;
}

.amplify-section-footer {
    font-size: 14px;
    color: var(--grey);
    display: flex;
    align-items: center;
    justify-content: center;
}

.amplify-section-footer > .amplify-section-footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.amplify-button,
.amplify-button.llama-button {
    width: 100%;
    display: block;
    margin-bottom: 0;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
    white-space: nowrap;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    color: #fff;
    background-color: #fd2a54;
    padding: 1.1rem;
    border: none;
}

.amplify-button:hover {
    opacity: 0.8;
}

.amplify-button .llama-button-spinner{
    top: -3px;
    height: auto;
}

.amplify-a:hover {
    text-decoration: underline;
    cursor: pointer;
}

.amplify-select {
    padding: 14px;
    font-size: 14px;
    color: var(--deepSquidInk);
    background-color: #fff;
    background-image: none;
    border: 1px solid var(--lightGrey);
    border-right: none;
    border-radius: 3px 0 0 3px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -ms-flex-preferred-size: 22%;
    flex-basis: 22%;
    width: 1%;
    background-image: -webkit-linear-gradient(45deg,transparent 50%,gray 0),-webkit-linear-gradient(315deg,gray 50%,transparent 0),-webkit-linear-gradient(left,#ccc,#ccc);
    background-image: -o-linear-gradient(45deg,transparent 50%,gray 50%),-o-linear-gradient(315deg,gray 50%,transparent 50%),-o-linear-gradient(left,#ccc,#ccc);
    background-image: linear-gradient(45deg,transparent 50%,gray 0),linear-gradient(135deg,gray 50%,transparent 0),linear-gradient(90deg,#ccc,#ccc);
    background-position: calc(100% - 20px) calc(1em + 8px),calc(100% - 15px) calc(1em + 8px),calc(100% - 2.5em) .5em;
    background-size: 6px 5px,6px 5px,0 1.5em;
    background-repeat: no-repeat;
}

.amplify-input-wrapper {
    display: flex;
    flex-direction: row;
}

.amplify-input-wrapper[data-field="myshopify_domain"]{
    position: relative;
}

.amplify-input-wrapper[data-field="myshopify_domain"] input{
    padding-right: 25px;
}

.amplify-input-wrapper[data-field="myshopify_domain"] .suffix{
    position: absolute;
    right: 16px;
    top: calc(50% - 10px);
    pointer-events: none;
    color: #777;
}

.amplify-input-wrapper .suffix{
    display: block;
    margin-left: .5rem;
}

.amplify-form-item:not(:last-child) {
    margin-bottom: 12px;
}

.amplify-paragraph {
    line-height: 2rem;
    margin-bottom: 2rem;
}

.amplify-accent {
    color: #4A3091;
    font-weight: bold;
}

.amplify-button-wrapper {
    margin-top: 4rem;
    text-align: right;
}

.amplify-hint {
    color: #637381;
    font-size: 12px;
    margin-top: 0.6rem;
}


.amplify-email__label {
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-top: .3rem;
}

.amplify-section-footer_links {
    display: flex;
    justify-content: center;
    margin: 2rem 0 1rem;
}

.amplify__custom-link {
    font-weight: bold;
    margin-left: 0.5rem;
}



.amplify__button-wrapper {
    width: 100%;
}

.amplify-section-footer_links {
    display: flex;
    justify-content: center;
    margin: 2rem 0 1rem;
}

.amplify__custom-link {
    font-weight: bold;
    margin-left: 0.5rem;
}

.amplify-section-footer_links-code {
    font-size: 1.25rem;
    line-height: 1.6rem;
    margin-top: 1.5rem;
}

.amplify-paragraph__username {
    font-weight: bold;
}

.change-password__llama-wrapper {
    margin: 2rem 0;
}

.change-password__llama-bg {
    background-color: #4A3091;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;
}

.change-password__llama-head {
   width: 100%;
   height: 100%;
}

.change-password__button-wrapper {
    margin-top: 0.6rem;
}

.change-password__errors {
    margin-top: 10px;
}

.change-password__error {
    color: #d92f18;
    font-size: 1.25rem;
}

.change-password__success {
    color: #47af35;
    font-size: 1.25rem;
}

.social-signin__wrapper {
    display: flex;
    flex-direction: column;
}

.social-button__label {
    font-size: 16px;
    text-align: center;
    opacity: 0.75;
}

/* expanded, half and half layout */
@media only screen and (max-width: 1200px) {
    .signin-amplify__form-wrapper {
        height: calc(100vh - 80px);
        /* height: 100%; */
        border-radius: 0;
        left: 0;
        width: 50%;
        top: 80px;
    }
    .signin-amplify__signin-form {
        width: max(75%, 35rem);
    }
    .signin-amplify__login-graphic {
        width: 400px;
        right: calc(50% - 400px - 50px);
    }
    .signin-amplify__graphic-wrapper {
        height: 100%;
    }
    .signin-amplify__graphic-outer {
        height: 100%;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 691px) {
    .signin-amplify__graphic-outer {
        width: 100%;
        padding-left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .signin-amplify__login-graphic{
        width: 70%;
        position: static;
    }
}

@media only screen and (max-width: 715px) {
    .signin-amplify__signin-form {
        width: 100%;
    }
}

@media only screen and (max-width: 690px) {
    .signin-amplify__form-wrapper {
        width: 100%;
        top: 250px;
        height: auto;
    }
    .signin-amplify__form-wrapper--signup {
        top: 80px;
    }
    .signup-amplify__graphic-wrapper {
        height: 0;
    }
    .signin-amplify__graphic-mobile {
        display: block;
        width: 100%;
        height: 167px;
        position: relative;
        z-index: 100;
    }
    .signin-amplify__graphic {
        display: none;
    }
    .signin-amplify__graphic-outer {
        width: 100%;
    }
    .signup-amplify__wrapper {
        margin-top: 0.6rem;
    }

    .signin-amplify__wrapper {
        background: #fff;
        flex-wrap: wrap-reverse;
    }

    .signin-amplify__signin-form {
        width: 100%;
        padding: 0 2rem;
    }
    .signup-amplify__mobile-wrapper {
        display: flex;
        align-items: center;
        padding: 2rem 0;
    }
    .signin-amplify__mobile-wrapper {
        display: flex;
        align-items: center;
        padding: 1rem 0;
    }
    .signin-amplify__llama-head {
        width: 67px;
        margin: 0 1rem 0 0;
    }
    .signin-amplify__header {
        font-size: 16px;
        text-align: left;
        margin-bottom: 0;
    }
    .signin-amplify__subheader {
        font-size: 12px;
        line-height: 16px;
        text-align: left;
        margin-bottom: 0;
    }
    .signup-amplify__subheader {
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        margin-bottom: 0;
    }
    .signin-amplify__login-graphic {
        width: 141px;
        right: calc(50% - 78.5px);
        top: 96px;
        z-index: 200;
    }
    .signin-amplify__login-graphic--signup {
        display: none;
    }
    .amplify-wrapper {
        padding: 3rem calc((100% - 460px) / 2);
        margin: 0;
        max-width: none;
        border: none;
        box-shadow: none;
    }

    .amplify-form-container {
        margin: 0;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        height: 90vh;
    }

    .amplify-form-section {
        width: 100%;
        box-sizing: border-box;
        padding: 35px 25px;
        min-width: auto;

        border: none;
        border-radius: 0;
        box-shadow: none;
    }

    .amplify-section-footer {
        font-size: 12px;
    }

    body {
        background: #FFFFFF !important;
    }
}

@media only screen and (max-width: 515px) {
    .amplify-wrapper {
        padding: 3rem;
    }
}