.smartlink-name-and-photo{
    width: min(50rem, 100%);
    margin: 0 auto;
}

.smartlink-name-and-photo .name{
    margin-top: 3.2rem;
    display: flex;
    gap: 2rem;
}

.smartlink-name-and-photo .name > div{
    width: 50%;
}

@media screen and (max-width: 670px) and (min-width: 600px),
       screen and (max-width: 460px) {
    .smartlink-name-and-photo .name{
        flex-direction: column;
    }

    .smartlink-name-and-photo .name > div{
        width: 100%;
    }
}