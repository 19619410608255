.OfferCoupons__Wrapper {
    padding: 2rem;
}

.OfferCoupons__Heading {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2rem;
}
  
.OfferCoupons__Heading .Polaris-Heading {
    margin-bottom: 1rem;
}

.OfferCoupons__Codes {
    border: 2px solid #33165C;
    border-radius: 5px;
    margin-bottom: 2rem;
    border-spacing: 0;
    width: 100%;
}

.OfferCoupons__Codes th, 
.OfferCoupons__Codes td{
    padding: 1rem 2rem;
}

.OfferCoupons__Codes th{
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    color: #ffffff;
    background: #33165C;
}

.OfferCoupons__Codes .code{
    font-weight: bold;
}

.OfferCoupons__Codes .valid-thru,
.OfferCoupons__Codes .value{
    text-align: right;
}

.OfferCoupons__Codes tr[data-expired="true"]{
    font-style: italic;
    opacity: .5;
    position: relative;
    transform: scale(1);
}

.OfferCoupons__Codes tr[data-expired="true"]::after{
    content: '';
    width: calc(100% - 3rem);
    height: 1px;
    background: #000;
    position: absolute;
    top: 50%;
    left: 1.5rem;
}

@media only screen and (max-width: 600px) {
    .OfferCoupons__Codes {
        font-size: 1.2rem;
    }

    .OfferCoupons__Codes th, 
    .OfferCoupons__Codes td{
        padding: .8rem 1rem;
    }
}