.cc-info-wrapper{
    background: #fafafa;
    border: 1px solid #ccc;
    padding: 1.4rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cc-info-wrapper p.card-info{
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.15em;
    margin-right: 1rem;
}

.cc-info-wrapper p.card-info:not(.unknown){
    padding-left: 5.8rem;
    position: relative;
}

.cc-info-wrapper p.card-info:not(.unknown)::before{
    object-fit: contain;
    width: 41px;
    height: 26px;
    display: block;
    position: absolute;
    left: 0;
    top: calc(50% - 14px);
}

.cc-info-wrapper p.visa::before{content: url(../../assets/cc-visa.svg);}
.cc-info-wrapper p.mastercard::before{content: url(../../assets/cc-mastercard.svg);}
.cc-info-wrapper p.american-express::before{content: url(../../assets/cc-amex.svg);}
.cc-info-wrapper p.discover::before{content: url(../../assets/cc-discover.svg);}
.cc-info-wrapper p.jcb::before{content: url(../../assets/cc-jcb.svg);}
.cc-info-wrapper p.unionpay::before{content: url(../../assets/cc-unionpay.svg);}
.cc-info-wrapper p.diners-club::before{content: url(../../assets/cc-dinersclub.svg);}

.cc-info-wrapper .expiration-date{
    font-size: .8em;
    display: block;
    font-weight: normal;
    color: #637381
}

.cc-info-wrapper .edit{
    margin-right: 0;
}

.cc-update-form ul{
    list-style: none;
    padding-left: 0;
}

.cc-update-form li{
    margin-bottom: 2rem;
}

.cc-update-form li:last-child{
    margin-bottom: 0;
}

.cc-update-form .StripeElement{
    margin-top: 0.4rem;
    margin-bottom: 0;
}

@media screen and (max-width: 380px){
    .cc-info-wrapper{
        display: block;
    }

    .cc-info-wrapper p.card-info{
        margin-right: 0;
        margin-bottom: 1.5rem;
    }

    .cc-info-wrapper .edit{
        width: 100%;
        padding: 6px 12px;
    }
}