.dashboard{
    padding: 2rem 3rem 2rem 5rem;
}

.dashboard[data-has-product-sales="false"]{
    display: block
}

.dashboard h2{
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: .8rem;
}

.dashboard .info{
    color: #637381;
    margin-bottom: 1.5rem;
}

.dashboard section{
    margin-bottom: 5rem;
}

/* default table styles */
.dashboard table{
    border-collapse: collapse;
}

.dashboard table th{
    text-align: left;
    padding: 0 1rem .8rem;
    font-size: 1.6rem;
}

.dashboard table td{
    line-height: 1.4em;
    padding: .8rem 1rem;
}

.dashboard table tbody tr:nth-child(odd) td{
    background: #f5f6f7;
}

.dashboard .subcell{
    color: #637381;
    font-size: .85em;
    display: block;
}

.dashboard table .amount{
    text-align: right;
}
.dashboard table .count{
    text-align: center;
}

/* OFFER STATS */
.dashboard--offer-stats dl{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    text-align: center;
    margin-top: 0;
    background: #f2f3f7;
    padding: 1.5rem 0 2rem 0;
    font-weight: bold;
}

.dashboard--offer-stats dt{
    grid-row-start: 1;
    position: relative;
    border-right: 1px solid #c5c5c8;
    font-size: 1.6rem;
    padding-bottom: 1rem;
    padding-top: 0.5rem;
}
.dashboard--offer-stats dd{
    grid-row-start: 2;
    margin-left: 0;
    border-right: 1px solid #c5c5c8;
    font-size: 2.8rem;
    padding-bottom: 0.5rem;
}

.dashboard--offer-stats dd .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    width: 9rem;
    margin: auto;
}

.dashboard--offer-stats dt:last-of-type,
.dashboard--offer-stats dd:last-of-type{
    border-right: none;
}

/* LTV */
.dashboard--ltv{
    display: flex;
    flex-wrap: wrap;
}

.dashboard--ltv h2,
.dashboard--ltv .info{
    width: 100%;
}

.dashboard--ltv .info{
    margin-bottom: 2.2rem;
}

.dashboard--ltv .nivo-pie{
    width: 27.5rem;
    height: 30.2rem;
    margin-right: 6rem;
}

/* make sure the first slice has white text */
.dashboard--ltv .nivo-pie g g:first-child g:nth-of-type(1) text{
    fill: #fff !important
}

.dashboard--ltv table{
    width: 100%;
}
.dashboard--ltv table[data-has-pie="true"]{
    width: calc(100% - 27.5rem - 6rem);
}

.dashboard--ltv table .merchant{
    min-width: 170px;
}   

/* PRODUCT SALES */
.dashboard--product-sales h2{
    margin-bottom: 2rem;
}

.dashboard--product-sales table{
    width: 100%;
    table-layout: fixed;
}

.dashboard--product-sales .product > span{
    display: flex;
    align-items: center;
}

.dashboard--product-sales .subcell .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    margin-top: 0.6rem;
    width: 30%;
}

.dashboard--product-sales .img-wrap{
    width: 3.8rem;
    height: 3.8rem;
    margin-right: 1rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.dashboard--product-sales .Polaris-Thumbnail{
    min-width: auto;
}

.dashboard--product-sales img{
    height: 100%;
    width: auto;
}

.dashboard--product-sales .meta{
    width: calc(100% - 4.8rem)
}

.dashboard--product-sales .product-name{
    max-width: 100%;
    display: block;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dashboard--product-sales td.offer{
    cursor: pointer;
    color: #fd2a54;
}

.dashboard--product-sales .offer{
    width: 30%;
}

.dashboard--product-sales .count{
    width: 4em;
}
.dashboard--product-sales .amount{
    width: 10rem;
}

.dashboard--success-modal {
    text-align: center;
    padding: 1.5rem;
}
.dashboard--success-modal p {
    max-width: 420px;
    margin: 0 auto;
}
.dashboard--success-modal h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.6rem;
}
.dashboard--success-modal img {
    width: 90px;
    height: 90px;
    padding: 1rem;
}

.dashboard--badges-loading {
    max-width: 1050px;
}

.dashboard--badges-loading .loading-row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
}

.dashboard--badges-loading .loading-badge {
    background: #F2F3F7;
    border: 1px solid #E0E0E0;
    width: 180px;
    border-radius: 3px;
    padding: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard--badges-loading .loading-badge h1 {
    min-width: 80%;
    padding-top: 1rem;
    padding-bottom: 3rem;
}

.dashboard--badges-loading .loading-steps .loading-step {
    background-color: #F2F3F7;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding: 18px;
    margin-bottom: 16px;
}

@media screen and (max-width: 1190px){
    .dashboard section{
        font-size: 1.3rem;
    }

    .dashboard h2{
        font-size: 1.8rem;
    }
    .dashboard table th{
        font-size: 1.4rem;
    }

    .dashboard--offer-stats dt{
        font-size: 1.4rem;
        padding-bottom: .4rem
    }
    .dashboard--offer-stats dd{
        font-size: 2.6rem;
    }

    .dashboard--ltv .nivo-pie{
        width: 22.5rem;
        height: 26.2rem;
        margin-right: 4rem;
    }
    .dashboard--ltv table[data-has-pie="true"]{
        width: calc(100% - 22.5rem - 4rem);
    }

    .dashboard--product-sales .img-wrap{
        height: 2.8rem;
        width: 2.8rem;
        margin-right: 0.8rem;
    }
}

@media screen and (max-width: 1020px){
    .dashboard--ltv .nivo-pie{
        max-width: 40rem;
        width: 100%;
        height: 23.2rem;
        margin-bottom: 3rem;
    }
    .dashboard--ltv table[data-has-pie="true"]{
        width: 100%;
    }
}

@media screen and (max-width: 970px){
    .dashboard--offer-stats dl{
        padding: 1rem 0 1.2rem;
    }
    .dashboard--offer-stats dt{
        font-size: 1.2rem;
        padding-bottom: 0.1rem;
    }
    .dashboard--offer-stats dd{
        font-size: 2rem;
    }
}

@media screen and (max-width: 820px){
    .dashboard--offer-stats dl{
        grid-template-columns: 60% 40%;
        padding: 0 1rem;
        text-align: left;
    }
    .dashboard--offer-stats dt,
    .dashboard--offer-stats dd{
        grid-row-start: auto;
        border-right: none;
        border-bottom: 1px solid #c5c5c8;
        padding: 1.2rem 0;
    }
    .dashboard--offer-stats dt:last-of-type,
    .dashboard--offer-stats dd:last-of-type{
        border-bottom: none;
    }
    .dashboard--offer-stats dt{
        font-size: 1.3rem;
    }
    .dashboard--offer-stats dd{
        text-align: right;
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 770px){
    .dashboard--product-sales thead{
        display: none;
    }
    .dashboard--product-sales table td{
        display: block;
        padding: 1.5rem;
        width: 100% !important;
    }
    .dashboard--product-sales table .count,
    .dashboard--product-sales table .amount{
        text-align: left;
    }
    .dashboard--product-sales table .product{
        font-size: 1.4rem;
        font-weight: bold;
        padding-bottom: 0;
    }
    .dashboard--product-sales .img-wrap{
        height: 3.8rem;
        width: 3.8rem;
        margin-right: 1rem;
    }
    .dashboard--product-sales table .offer{
        padding-bottom: .1rem;
    }
    .dashboard--product-sales table .qty{
        padding-top: .1rem;
    }
    .dashboard--product-sales .total{
        font-weight: bold;
        font-size: 1.3rem;
        padding-top: 0;
    }
    .dashboard--product-sales td::before{
        font-weight: bold;
    }
    .dashboard--product-sales .offer::before{
        content: 'Offer: ';
        color: #212b36;
    }
    .dashboard--product-sales .qty::before{
        content: 'Quantity Sold: '
    }
    .dashboard--product-sales .total::before{
        content: 'Total: '
    }
}

@media screen and (max-width: 715px) and (min-width: 600px), screen and (max-width: 470px){
    .dashboard--ltv .nivo-pie{
        max-width: 34rem;
        height: 17.2rem;
        margin:0 auto 2rem;
    }
    .dashboard--ltv table[data-has-pie="true"]{
        width: 100%;
    }
}

@media screen and (max-width: 700px){
    .dashboard--ltv thead{
        display: none;
    }
    .dashboard--ltv table td{
        display: block;
        padding: 1.5rem;
    }
    .dashboard--ltv table .count,
    .dashboard--ltv table .amount{
        text-align: left;
    }
    .dashboard--ltv table .merchant{
        font-size: 1.4rem;
        font-weight: bold;
        padding-bottom: 0;
    }
    .dashboard--ltv table .one-time{
        padding-bottom: .1rem;
    }
    .dashboard--ltv table .repeat{
        padding-top: .1rem;
    }
    .dashboard--ltv .total{
        font-weight: bold;
        font-size: 1.3rem;
        padding-top: 0;
    }
    .dashboard--ltv td::before{
        font-weight: bold;
    }
    .dashboard--ltv .one-time::before{
        content: 'One-Time Customers: '
    }
    .dashboard--ltv .repeat::before{
        content: 'Repeat Customers: '
    }
    .dashboard--ltv .total::before{
        content: 'Total: '
    }
}

@media screen and (max-width: 599px){
    .dashboard {
        padding: 2rem;
    }
}

@media screen and (max-width: 375px){
    .dashboard--ltv .nivo-pie{
        height: 14.2rem;
        max-width: 31rem;
    }
}