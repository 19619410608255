/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

#adv-reports {
    padding: 2rem 3rem 2rem 5rem;
  }
  /* #adv-reports thead th {
    background: #eee;
    text-align: left;
  } */
  /* #adv-reports tr {
    display: table-row;
    height: auto;
  }
  #adv-reports tr td,
  #adv-reports tr th {
    padding: 10px;
  }
  #adv-reports tr td:last-child,
  #adv-reports tr th:last-child {
    text-align: right;
  }
  #adv-reports ul {
    list-style: none;
    padding-left: 0;
  }
  #adv-reports li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    overflow: hidden;
  }
  #adv-reports li span {
    flex: 1;
  }
  #adv-reports li .amount {
    text-align: right;
  }
  #adv-reports li.past-due {
    background: #ff2121;
    color: #fff;
    padding: 10px;
  }
  #adv-reports li time {
    display: block;
    font-size: .85em;
  }
  #adv-reports img {
    border-radius: 100%;
    margin-right: 10px;
    vertical-align: middle;
  }
   */

   @media screen and (max-width: 599px){
    #adv-reports {
      padding: 2rem 2rem 2rem 0;
    }
   }