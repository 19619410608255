.offer-link-parameters h2{
    font-weight: bold;
    font-size: 1.8rem;
}

.offer-link-parameters .custom-link-preview{
    margin: 10px 0px 25px 0px;
}

.offer-link-parameters .add-link-options {
    display: flex;
}

.offer-link-parameters-list{
    list-style: none;
    padding-left: 0;
    margin-top: 1.6rem;
}

.offer-link-parameters-list li{
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 1rem;
    margin-bottom: 1.2rem;
    position: relative;
}

.offer-link-parameters-list li::after{
    content: '';
    position: absolute;
    border-left: 2px solid #637381;
    border-bottom: 2px solid #637381;
    width: 8px;
    height: 8px;
    top: .7rem;
    right: 5px;
    transform: rotate(-45deg);
}

.offer-link-parameters-list li[data-expanded="true"]:after{
    transform: rotate(-225deg)
}

.offer-link-parameters-list li span{
    display: block;
}

.offer-link-parameters-list .link-name{
    border: none;
    background: none;
    padding: 0;
    font: inherit;
    width: 100%;
    text-align: left;
}

.offer-link-parameters-list .actions{
    margin-top: 1.2rem;
}

.offer-link-parameters-list .actions .llama-button{
    display: inline-block !important;
    padding: .3rem 1.5rem .3rem 1rem;
}

.offer-link-parameters-list .actions .llama-button .Polaris-Icon{
    display: inline-block;
    height: 15px;
    margin-right: 0;
    vertical-align: text-top;
}

.offer-link-parameters-list .actions .edit,
.offer-link-parameters-list .actions .remove{
    border-radius: 20px;
    border: none;
    font: inherit;
    color: #fff;
    padding: .3rem 1.5rem .3rem 3.5rem;
    cursor: pointer;
}

.offer-link-parameters-list .edit{
    background: #34135D url(../../assets/edit-white.svg) 1.5rem center no-repeat;
    background-size: 12px 12px;
    margin-right: 1rem;
}

.offer-link-parameters-list .remove{
    background: #262626 url(../../assets/delete-white.svg) 1.5rem center no-repeat;
    background-size: 11px 13px;
}

.offer-link-parameters-list .remove .Polaris-Spinner--sizeSmall svg{
    width: 1.2rem;
    height: 1.2rem;
}

.offer-link-parameters form{
    margin-top: 1.6rem;
}

.offer-link-parameters form ul{
    padding-left: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
}

.offer-link-parameters form li{
    width: calc(50% - 10px);
    position: relative;
}

.offer-link-parameters form .cancel{
    background: none;
    border: none;
    color: #fd2a54;
    position: absolute;
    top: 0;
    right: 0;
}

.offer-link-parameters form .Polaris-Labelled__HelpText{
    font-size: 1.2rem;
    line-height: 1.3em;
}

@media screen and (max-width: 490px){
    .offer-link-parameters form ul{
        flex-direction: column;
    }
    
    .offer-link-parameters form li{
        width: 100%;
        margin-bottom: 2rem;
    }
    .offer-link-parameters form li:last-child{
        margin-bottom: 0;
    }
}