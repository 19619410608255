.smartlink-subscription {
    padding: 8rem 2rem 2rem;
    max-width: 680px;
    margin: 0px auto;
}

.smartlink-subscription h2{
    font-size: 2.6rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 2.5rem;
}

.smartlink-subscription .info{
    text-align: center;
    color: #637381;
    font-size: 1.8rem;
    line-height: 1.4em;
    margin-bottom: 4rem;
}

.smartlink-subscription h3{
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.smartlink-subscription .plan{
    background: #fafafa;
    border: 1px solid #e5e5e5;
    padding: 2rem;
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 4rem;
}

.smartlink-subscription .plan p{
    font-weight: bold;
}

.smartlink-subscription .plan p span{
    font-weight: normal;
    font-size: 1.3rem;
    display: block;
}

.smartlink-subscription .plan .plan-name{
    margin-left: 1.5rem;
}

.smartlink-subscription .plan .Polaris-Choice__Control{
    width: 2rem;
    height: 2rem;
}

.smartlink-subscription .plan .plan-type{
    text-transform: uppercase;
}

.smartlink-subscription .plan .price{
    text-align: right;
    margin-left: auto;
    font-size: 2.1rem;
}

.smartlink-subscription ul{
    padding-left: 0;
    list-style: none;
}

.smartlink-subscription li{
    margin-bottom: 2rem;
}

.smartlink-subscription .terms-agreement{
    display: flex;
    position: relative;
}

.smartlink-subscription .terms-agreement label{
    margin-right: .4rem;
}

.smartlink-subscription form p.actions{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.smartlink-subscription form .Polaris-Banner--withinPage{
    margin-bottom: 2rem;
}

.smartlink-subscription form .Polaris-Button--primary{
    padding: 1.3rem 4rem;
    margin: 0 auto 1.3rem;
}

.Polaris-Button--primary.Polaris-Button--disabled {
    background: rgba(253,42,84,1) !important;
    border: none;
    box-shadow: none;
    opacity: .5 !important;
}

.smartlink-subscription form .Polaris-Button--primary .Polaris-Button__Content{
    font-weight: bold;
    font-size: 1.8rem;
}

.smartlink-subscription .terms-agreement .Polaris-Choice__Descriptions{
    position: absolute;
}

.smartlink-subscription .StripeElement{
    margin-bottom: .5rem;
}

.smartlink-subscription .promo-code{
    display: flex;
    margin-bottom: 4rem;
}

.smartlink-subscription .promo-code > div{
    margin-right: 10px;
    width: 88%;
}

/*.smartlink-subscription .content-area {
    text-align: center;
}
.smartlink-subscription .content-area .heading {
    font-size: 2.6rem;
    padding: 0rem 2.2rem;
    font-weight: 600;
    line-height: 2.4rem;
    margin: 0.75em auto 1em;
}
.smartlink-subscription .content-area p {    
    text-align: center;
    padding-left: 5vw;
    padding-right: 5vw;
    margin-bottom: 2.6em;
    font-size: 1.2em;    
    line-height: 1.5em;
}

.smartlink-subscription .plan h3 {    
    font-size: 18px;
    margin: 2em auto 1em;
    font-weight: bold;
}
.plan {
}
.plan .tree {
    background-color: #ffffff;
    border-top: 0;
    border-left: 0;
    padding: 20px;
    border-radius: 4px;
    position: relative;
    padding: 0 0 0 60px;    
    border-radius: 0.5rem;
    border: 1px solid #EBE8ED;
    box-shadow: 0rem 1rem 2rem rgba(0, 0, 0, 0.1);
    margin-bottom: 55px;
}
.plan .tree:last-of-type {
    /*margin-bottom: 0;*
}
.plan .tree-sidebar {
    width: 60px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #3d3b3c;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.plan .tree-sidebar .img-circle {
    
    position: relative;
    z-index: 100;
    width: 38px;
    height: 38px;
    -webkit-transition: visibility 0s linear 0.15s, opacity 0.15s cubic-bezier(0.08, 0.59, 0.29, 0.99);
    transition: visibility 0s linear 0.15s, opacity 0.15s cubic-bezier(0.08, 0.59, 0.29, 0.99);
    
    overflow: hidden;
    display: block;
    border-radius: 50%;
    margin: 0;
    z-index: 0;

    top: 10px;
    left: 10px;
    opacity: 1;
    visibility: visible;
    background-color: rgba(255,255,255,.3);
    border: 1px solid rgba(0,0,0,.3);
}

.plan .tree-sidebar .img-circle img {
    position: absolute;
    width: 0;
    min-width: 38px;
    min-height: 38px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
img {
    vertical-align: middle;
    border-style: none;
}
.plan .tree-section {
    position: relative;
    padding: 12px;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
}
.plan .tree-section:nth-of-type(2) {
    border-top-right-radius: 3px;
}
.plan .tree-section:last-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 3px;
}
.plan .tree-section-details .name {
    text-transform: uppercase;
    opacity: 0.8;
}
.plan .tree-section.tree-section-account {    
    font-size: 1.1em;
}
.plan .tree-section.tree-section-account .username {    
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    max-width: calc(100% - 1px);
}
.plan .tree-section-details .price {
    font-size: 1.4em;
    margin-top: 5px;
}
.plan .tree-section-details .price {
    font-size: 1.4em;
    margin-top: 5px;
}
.plan .tree-section-details .price {
    font-size: 1.4em;
    margin-top: 5px;
}
.plan .tree-section-details .frequency {
    opacity: 0.5;
    font-size: 0.8em;
    margin-left: 5px;
}


.smartlink-subscription .form-wrap h3 {    
    font-size: 18px;
    margin: 2em auto 1em;
    font-weight: bold;
}

.form-wrap .section-inner {
    border-top: 0;
    border-left: 0;
    padding: 20px;
    border-radius: 4px;
    position: relative;
    background-color: #ffffff;    
    border-radius: 0.5rem;
    border: 1px solid #EBE8ED;
    box-shadow: 0rem 1rem 2rem rgba(0, 0, 0, 0.1);
    margin-bottom: 35px;
}
.form-wrap .section-inner h5 {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.03em;
    text-align: left;
    margin-top: 34px;
    margin-bottom: 5px;
}
.form-wrap .section-inner h5:first-of-type {
    margin-top: 0;
}
.form-wrap .section-inner .Polaris-TextField {
    margin-bottom: 15px;
}
.form-wrap .section-inner .form-lastrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.form-wrap .section-inner .form-lastrow > div {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-right: 20px;
}
.form-wrap .section-inner .form-lastrow > div:last-of-type {
    margin-right: 0;
}
.form-wrap .section-inner .billing-small {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.form-wrap .section-inner .billing-small > div {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-right: 20px;
}
.form-wrap .section-inner .billing-small > div:last-of-type {
    margin-right: 0;
}
.form-wrap .section-inner .Polaris-Choice {
    margin-bottom: 20px;
}
.submitBTN {
    margin-top: 15px;
    margin-bottom: 15px;
    float: right;
    width: 100%;
}
.submitBTN .paypalbtn {
    float: right;
    padding-left: 25px; 
    padding-right: 25px;
}
.customecheckbox .Polaris-Choice {
    top: 3px;
    position: relative;
}

.errorMsg {
    color: #cc0000;
    margin-bottom: 12px;
}

.checkout {
    height: 25px;
}

.checkout iframe {
    min-height: unset !important;
}

@media (max-width:800px) { 
    .smartlink-subscription {
        padding: 10px 25px 100px;
    }
    .billing-address-city {
        flex: none!important;
        width: 100%;
        display: block;
        margin-right: 0;
    }
    .form-wrap .section-inner .form-lastrow {
        display: block;
    }
}

*/