.DiscoverMerchantsLink p {
    color: #637381;
    cursor: pointer;
    font-size: 1.1rem;
    /* margin-left: 28px; */
}

.DiscoverMerchantsLink {
    display: flex;
}

.DiscoverMerchantsLink .offers-page-link {
    color: #fd2a54;
    margin-left: 4px;
}

.DiscoverMerchantsLink .offers-page-link:hover{
    text-decoration: underline;
}