/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

 .add__account__card__button {
    text-align: center;
    color: #1877F2;
    background-color: unset;
    border: none;
    cursor: pointer;
    font-size: 15px;
    white-space: nowrap;
    font-weight: 600;
 }

 .add__account__button {
   text-align: center;
   color: white;
   border: white;
   cursor: pointer;
   font-size: 15px;
   white-space: nowrap;
   width: 100px;
   height: 35px;
   border-radius: 3px;
   margin-top: 25px;
   background: linear-gradient(to bottom, #6371c7, #5563c1);
   border-color: #3f4eae;
}

.modal__button {
   margin-top: 25px;
   display: flex;
   flex-direction: row-reverse;
}

.empty__social__button {
   width: 140px;
   height: 40px;
   text-align: center;
   border-radius: 2px;
   cursor: pointer;
   display: inline-block;
   font-size: 15px;
   font-weight: bold;
   white-space: nowrap;
   margin-top: 55px;
}

.facebook__button {
   color: #fff;
   background-color: #1877F2;
   border: solid #1877F2;
}





.facebook-integration__account-select {
   width: 100%;
   padding: 2rem;
   /* margin-bottom: 3rem; */
}

.facebook-integration__accounts-groups {
   display: flex;
   flex-direction: column;
}

.facebook-integration__section-header {
   font-size: 1.5rem;
   font-weight: bold;
}

.facebook-integration__unlink {
   display: flex;
   align-items: center;
   color: #ff2754;
}

.facebook-integration__button {
   color: #fff;
   background: #1877F2 url(../../../assets/fb_white-circle.svg) no-repeat 1rem center;
   background-size: auto calc(100% - 1.6rem);
   cursor: pointer;
   padding: 0.7rem 1rem .7rem 4rem;
   margin: 2rem 0 1rem 0;
   font-weight: bold;
   border-radius: 3px;
   display: flex;
   align-items: center;
   justify-content: center;
   border: none;
   position: relative;
}

/* .facebook-integration__button::after {
   content: '';
   position: absolute;
   top: .6rem;
   left: 2.5rem;
   width: 1px;
   height: calc(100% - 1.2rem);
   background: rgba(255, 255, 255, .3);
} */

.facebook-integration__groups-pages {
   width: 100%;
   margin: 2rem 4rem;
}

.facebook-integration__list-accounts {
   padding-left: 0;
   list-style: none;
}

.facebook-integration__list-accounts li{
   display: flex;
   align-items: center;
   padding: 1.5rem 0;
   border-bottom: 1px solid #E0E0E0;
}

.facebook-integration__list-accounts img{
   width: 40px;
   height: 40px;
   border-radius: 100%;
   margin-right: 1.2rem;
}

.facebook-integration__list-accounts .account-info{
   display: flex;
   align-items: center;
   font-size: 1.3rem;
   color: #637381;
   line-height: 1.4em;
   margin-right: auto;
}

.facebook-integration__list-accounts .account-info b{
   display: block;
   font-size: 1.15em;
   color: #212B36;
}

.facebook-integration__list-accounts .account-info span > .Polaris-Badge{
   font-size: 1rem;
   line-height: 1.3rem;
   padding: .1rem 1rem;
}

.facebook-integration__list-accounts .account-info + button{
   margin-left: 3rem;
}

.facebook-integration__list-accounts button{
   white-space: nowrap;
   font-size: 1.2rem;
   font-weight: bold;
   padding-left: 3.7rem;
}

.facebook-integration__list-accounts button.unlink{
   background: #000 url(../../../assets/unlink-white.svg) no-repeat 1.5rem .5rem;
   border-color: #000;
}

.facebook-integration__list-accounts button.edit{
   background:#FD2856 url(../../../assets/edit-white.svg) no-repeat 1.5rem .5rem;
   background-size: 12px 12px;
   padding-left: 3.6rem;
}

.facebook-integration__list-account {
   display: flex;
   justify-content: space-between;
}

.facebook-integration__list-groups {
   margin: 1rem 0;
}


.facebook-integration__unlink-image {
   height: 1.25rem;
   margin-right: 0.5rem;
}

.facebook-integration__header {
   font-weight: bold;
   font-size: 1.75rem;
   margin-bottom: 0.6rem;
}

.facebook-integration__paragraph {
   color: #404040;
}

.facebook-integration__resource-item {
   border-bottom: 1px solid #cdcdcd;
}

.facebook-integration__name-photo {
   display: flex;
   align-items: center;
}

.facebook-integration__photo-avatar {
   width: 30px;
   height: 30px;
   border-radius: 50px;
   background-color: #1877F2;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 1.2rem;
}

.facebook-integration__photo {
   height: 16px;
}

.facebook-integration__name {
   font-weight: bold;
}

@media screen and (max-width: 1000px) {
   .facebook-integration__list-accounts li{
      flex-wrap: wrap;
   }
   .facebook-integration__list-accounts .account-info{
      width: 100%;
      margin-bottom: 0.5rem;
   }
   .facebook-integration__list-accounts button.edit{
      margin-left: 5.2rem;
   }
}


/* .facebook-integration__grade-section {
   background: #f2f2f2;
   padding: 2rem;
   min-height: 150px;
}

.facebook-integration__grade-heading {
   font-size: 15px;
   font-weight: bold;
}

.facebook-integration__grade-subheading {
   font-size: 12px;
   opacity: 0.75;
   margin-bottom: 1rem;
}

.facebook-integration__grade-wrapper {
   display: flex;
}

.facebook-integration__grade-title {
   font-size: 12px;
   font-weight: bold;
}

.facebook-integration__grade-desc {
   font-size: 10px;
   line-height: 12px;
   opacity: 0.75;
}

.facebook-integration__grade {
   border-radius: 4px;
   font-size: 26px;
   display: flex;
   justify-content: center;
   align-items: center;
   min-width: 50px;
   width: 50px;
   height: 50px;
   font-weight: bold;
}

.facebook-integration__grade--A {
   background: #c3e3b8;
}
.facebook-integration__grade--B {
   background: #bce0f8;
}
.facebook-integration__grade--C {
   background: #fcea97;
}
.facebook-integration__grade--D {
   background: #f7c793;
}
.facebook-integration__grade--F {
   background: #f7c793;
}

.facebook-integration__grade-text {
   margin-left: 1rem;
} */



@media screen and (max-width: 599px) {

   .affiliate-social__section-title {
      margin-left: 2rem;
   }

   .Polaris-ResourceList-Item__Container {
      padding: 0 !important;
   }

   .facebook-integration__name {
      font-size: 1.2rem;
   }

   .facebook-integration__unlink {
      font-size: 1.2rem;
   }


}

@media screen and (max-width: 405px) {
   .facebook-integration__list-accounts button.unlink,
   .facebook-integration__list-accounts button.edit{
      margin: .5rem 0 .3rem 0;
      width: calc(50% - .5rem);
   }

   .facebook-integration__list-accounts button.edit{
      margin-right: 1rem;
   }
}