
.settings-notifications__section-label {
    width: 100%;
    background-color: #f3f4f7;
    margin-top: 3rem;
}

.settings-notifications__section-title {
    color: #7e8f9f;
    text-transform: uppercase;
    margin-left: 4rem;
}

.settings-notifications__card-wrapper {
    padding: 0 2rem 2rem;
}

.settings-notifications__submit_btn{
    padding: 20px;
}
.settings-notifications__wrapper {
    /* s */
}

.settings-notifications__header-section {
    max-width: 700px;
    padding: 20px;
}

.settings-notifications__h1 {
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    padding-bottom: 8px;
}

.settings-notifications__p {
    font-size: 12px;
    line-height: 18px;
    color: #828282;
}

.settings-notifications__marketing-section {
    padding-top: 12px;
    padding: 20px;
}

.settings-notifications__input-col {
    display: flex;
    flex-direction: column;
}

.settings-notifications--label-title {
    color: #000000;
    margin: 0;
    padding: 0;
}

.settings-notifications--label-note {
    font-size: 10px;
    color: #828282;
    line-height: 10px;
}

.settings-notifications__h2 {
    font-size: 14px;
    font-weight: bold;
}

.settings-notifications__marketing-selections {
    padding-left: 20px;
}

.settings-notifications__newsletter-section {
    padding-top: 16px;
}

.settings-notifications__newsletter-selections {
    padding-left: 20px;
}

.settings-notifications__unsubscribe-section {
    padding-top: 16px;
}

.settings-notifications--input-label {
    color: #828282;
}

.settings-notifications__input-selection {
    display: flex;
    align-items: center;
    padding-top: 12px;
}

.settings-notifcations__input-wrapper {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.settings-notifcations__input-wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.settings-notifications__input-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    /*background-color: #eee;*/
    background-color: #ffffff;
    border: 1px solid #828282;
}

.settings-notifcations__input-wrapper input:checked ~ .settings-notifications__input-checkmark {
    background: #448B46;
    border: none;
    border: 1px solid #448B46;
}

.settings-notifications__input-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.settings-notifcations__input-wrapper input:checked ~ .settings-notifications__input-checkmark:after {
    display: block;
}

.settings-notifcations__input-wrapper .settings-notifications__input-checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

@media screen and (max-width: 599px) {
    .settings-notifications__section-title {
        margin-left: 1.25rem;
        font-size: 1.2rem;
    }
}