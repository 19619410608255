.billing-history{
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1.5rem;
}

.billing-history th{
    text-align: left;
    padding: 0 0 .6rem;
    border-bottom: 2px solid #dfe3e8;
}

.billing-history td{
    background: #fff;
    font-size: 1.6rem;
    padding: 1.5rem 0;
    border-bottom: 1px solid #dfe3e8;
}

.billing-history .date{
    font-weight: bold;
}

.billing-history .order-number{
    color: #637381;
    font-size: 0.875em;
    font-weight: normal;
    display: block;
}

.billing-history .amount{
    text-align: right;
}

.billing-history .unpaid{
    color: #f00;
}

.billing-history tbody tr:last-child td{
    border-bottom: 2rem solid #e2e2e2;
}

.billing-history tbody:last-child tr:last-child td{
    border-bottom: 1px solid #dfe3e8;
}

@media screen and (max-width: 840px){
    .billing-history thead{
        display: none;
    }
    .billing-history tr{
        display: flex;
        flex-direction: column;
    }
    .billing-history td{
        display: block;
        padding: .5rem 0;
    }

    .billing-history .date{
        display: flex;
        justify-content: space-between;
        order: 1;
        padding-top: 2rem;
    }
    .billing-history .description{
        order: 2;
        padding-bottom: 0.1rem;
    }
    .billing-history .amount{
        text-align: left;
        order: 3;
    }
    .billing-history .amount::before{
        content: 'Amount: ';
    }
    .billing-history .status{
        order: 4;
        padding-bottom: 2rem;
    }

    .billing-history td, 
    .billing-history tbody tr:last-child td, 
    .billing-history tbody:last-child tr:last-child td{
        border-bottom: none;
    }
    .billing-history .status,
    .billing-history tbody:last-child tr:last-child .status{
        border-bottom: 1px solid #dfe3e8;
    }
    .billing-history tbody tr:last-child .status{
        border-bottom: 2rem solid #e2e2e2;
    }
}

@media screen and (max-width: 599px){
    .billing-history td{
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 320px){
    .billing-history .date{
        display: block;
        padding-top: 2rem;
        padding-bottom: 0.5rem;
    }
}