.smartlinks-url-actions{
    margin: -3rem -5.5rem 3rem;
    padding: 1.5rem 5.5rem;
    background: #f7fbff;
    border-bottom: 1px solid #e0e0e0;
    position: sticky;
    top: 0;
    z-index: 110;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.smartlinks-url-actions .avatar{
    margin-right: 0.8rem;
}

.smartlinks-url-actions .avatar img{
    width: 30px;
    height: 30px;
    border-radius: 100%;
    vertical-align: middle;
}

.smartlinks-url-actions .url-heading{
    text-transform: none;
    font-size: 1.35rem;
    font-weight: bold;
    margin-right: 0.7rem;
}
.smartlinks-url-actions .url-heading::after{
    content: ':';
}

.smartlinks-url-actions .url-wrapper,
.smartlinks-url-actions .url{
    display: flex;
    align-items: center;
}

.smartlinks-url-actions .url{
    margin: 0 0 0 .2rem;
    position: relative;
}

.smartlinks-url-actions .url .connected{
    border: 1px solid var(--llama-border);
    background: #fff;
    display: flex;
    border-radius: 100px;
    align-items: center;
}

.smartlinks-url-actions .url a{
    color: var(--llama-main);
    border-radius: 100px 0 0 100px;
    font-weight: bold;
    text-decoration: none;
    word-wrap: break-word;
    font-size: 1.3rem;
    padding: 0.3rem 1.2rem;
    display: inline-block;
    vertical-align: middle;
}

.smartlinks-url-actions .url .button-group{
    display: flex;
    gap: 0;
}

.smartlinks-url-actions .url .button-group .llama-button{
    margin-right: 0;
    font-weight: bold;
    font-size: 1.2rem;
    padding-left: 3.6rem;
    padding-right: 1.2rem;
}

.smartlinks-url-actions .url .button-group .llama-button:first-child{
    border-radius: 0;
    border-right: 1px solid #fff;
}

.smartlinks-url-actions .url .button-group .llama-button:last-child{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.smartlinks-url-actions .url .llama-button.edit{
    background: var(--llama-pink) url('../../assets/edit-white-thicker.svg') no-repeat 1.2rem center;
    background-size: 14px 14px;
}

.smartlinks-url-actions .url .llama-button.copy{
    background: var(--llama-pink) url('../../assets/edit-white-thicker.svg') no-repeat 1.2rem center;
    background-size: 14px 14px;
}

.smartlinks-url-actions .upgrade-message{
    flex-basis: 100%;
    margin-left: 4rem;
    color: #637381;
    font-size: 1.3rem;
}

.smartlinks-url-actions .upgrade-message button{
    background: none;
    border: none;
    color: #fd2a54;
    padding: 0;
    font-size: 1em;
}

.smartlinks-url-actions .copy{
    margin: 0;
}

.smartlinks-url-actions .copy button{
    font-size: 1.2rem;
    padding: .5rem 1.2rem;
    margin-right: 0.8rem;
}

.smartlinks-url-actions .copy button .Polaris-Icon{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: .4rem;
}

/* smaller container padding */
@media screen and (max-width: 980px){
    .smartlinks-url-actions{
        margin: 2.6rem -4rem 3rem;
        padding: 1.5rem 4rem;
    }
}

@media screen and (max-width: 1080px){
    .smartlinks-url-actions .url-wrapper{
        flex-direction: column;
        align-items: flex-start;
        line-height: 1.3em;
    }

    .smartlinks-url-actions .url-heading{
        margin-bottom: 0.3rem;
        margin-left: 1rem;
    }

    .smartlinks-url-actions .upgrade-message{
        margin-top: .8rem;
    }
}

@media screen and (max-width: 980px){
    .smartlinks-url-actions .load-preview{
        margin-top: 2rem;
    }

    .smartlinks-url-actions .url{
        margin-right: 1rem;
    }

    .smartlinks-url-actions .load-preview button{
        color: #fd2a54;
        border: 2px solid #fd2a54;
        background: transparent;
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 740px){
    .smartlinks-url-actions .url .edit{
        border: none;
        background: #fd2a54;
        color: #fff;
        border-radius: 100px;
        padding: .4rem 1.5rem;
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 790px) and (min-width: 600px),
       screen and (max-width: 580px){
    .smartlinks-url-actions{
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .smartlinks-url-actions .url-wrapper{
        width: calc(100% - 38px);
    }

    .smartlinks-url-actions .url{
        margin-right: 0;
    }

    .smartlinks-url-actions .avatar,
    .smartlinks-url-actions .url-wrapper{
        margin-bottom: 1.2rem;
    }

    .smartlinks-url-actions .load-preview{
        margin-left: 38px;
        margin-top: 0;
    }
}

@media screen and (max-width: 680px) and (min-width: 599px),
       screen and (max-width: 480px) {
    .smartlinks-url-actions .avatar{
        display: none;
    }
    .smartlinks-url-actions .load-preview{
        margin-left: 0;
    }
}

@media screen and (max-width: 660px) and (min-width: 599px),
       screen and (max-width: 440px){
    .smartlinks-url-actions .url a{
        padding-left: 0;
        color: var(--llama-pink);
        margin-bottom: .5rem;
    }

    .smartlinks-url-actions .url-heading{
        margin-left: 0;
    }

    .smartlinks-url-actions .url .connected{
        flex-wrap: wrap;
        border: none;
        background: none;
    }

    .smartlinks-url-actions .url .button-group .llama-button:first-child{
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
    }

    .smartlinks-url-actions .load-preview{
        position: absolute;
        top: 1.5rem;
        right: 3rem;
    }
}

@media screen and (max-width: 640px) and (min-width: 599px),
       screen and (max-width: 470px){
    .smartlinks-url-actions div.url{
        flex-wrap: wrap;
    }

    .smartlinks-url-actions .avatar{
        align-self: flex-start;
        margin-top: 1.2rem;
    }

    .smartlinks-url-actions .url .llama-button{
        flex-basis: 100%;
    }
}

@media screen and (max-width: 599px){
    .smartlinks-url-actions .url-wrapper{
        margin-right: auto;
    }
}

@media screen and (max-width: 530px){
    .smartlinks-url-actions div.url{
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 490px){
    .smartlinks .smartlinks-url-actions{
        margin: 2.6rem -3rem 3rem;
        padding: 1.5rem 3rem;
    }
}

@media screen and (max-width: 390px){
    .smartlinks-url-actions .avatar{
        display: none;
    }

    .smartlinks-url-actions .copy,
    .smartlinks-url-actions .upgrade-message{
        margin-left: 0;
    }
}