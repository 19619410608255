.NotificationsSideDrawer {
    position: fixed;
    max-width: 34rem;
    width: 100%;
    bottom: 0;
    right: 0;
    top: 0;
    z-index: 2002;
    background-color: #FFFFFF;
    padding: 2rem 0;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto; 
}

.NotificationsSideDrawer--open {
    transform: translateX(0);
}

.NotificationsSideDrawer--close {
    transform: translateX(100%);
}

.NotificationsSideDrawer__Overlay {
    position: fixed;
    z-index: 2001;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color:rgba(0, 0, 0,0.5);
    display: none;
    transition: all 300ms ease-out;
    opacity: 0;
}

.NotificationsSideDrawer__Overlay--show {
    display: block;
    opacity: 1;
}

.NotificationsSideDrawer .close button{
    width: 2rem;
    height: 2rem;
    background: none;
    border: none;
    text-indent: -99999px;
    position: relative;
    cursor: pointer;
}

.NotificationsSideDrawer .close button::before,
.NotificationsSideDrawer .close button::after{
    content: '';
    width: 1.5rem;
    height: 0.2rem;
    position: absolute;
    left: calc(50% - .7rem);
    top: calc(50% - .1rem);
    background: #000;
}
.NotificationsSideDrawer .close button::before{
    transform: rotate(45deg);
}
.NotificationsSideDrawer .close button::after{
    transform: rotate(-45deg);
}

.NotificationsSideDrawer__Header {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 2rem;
    margin-bottom: 1.8rem;
}

.NotificationsSideDrawer h5 {
    font-weight: bold;
    font-size: 1.8rem;
}

@media screen and (max-width: 599px) {

    .NotificationsSideDrawer {
        top: 6rem;
        min-height: calc(100vh - 6rem);
        max-width: 80vw;
    }
}

@media screen and (max-width: 480px) {
    .NotificationsSideDrawer {
        max-width: 90vw;
    }
}