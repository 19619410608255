.smartlinks-url-form{
    display: flex;
    align-items: center;
    margin-left: .2rem;
}

.smartlinks-url-form .Polaris-TextField__Input{
    width: 13.3rem;
}

.smartlinks-url-form .textfield-wrapper{
    display: flex;
    align-items: center;
    gap: .9rem;
    position: relative;
}

.smartlinks-url-form .textfield-wrapper,
.smartlinks-url-form .Polaris-Spinner,
.smartlinks-url-form .availability{
    margin-right: 0.9rem;
}

.smartlinks-url-form .availability{
    font-weight: bold;
    position: relative;
}

.smartlinks-url-form .availability[data-is-available="true"]{
    color: #009e22;
    padding-left: 1.6rem;
}
.smartlinks-url-form .availability[data-is-available="true"]::before{
    content: '';
    width: 11px;
    height: 6px;
    border-left: 2px solid #009e22;
    border-bottom: 2px solid #009e22;
    transform: rotate(-45deg);
    position: absolute;
    top: 5px;
    left: 0;
}

.smartlinks-url-form .availability[data-is-available="false"]{
    color: #df0000;
    padding-left: 1.8rem;
}
.smartlinks-url-form .availability[data-is-available="false"]::before,
.smartlinks-url-form .availability[data-is-available="false"]::after{
    content: '';
    width: 12px;
    height: 2px;
    background: #df0000;
    position: absolute;
    top: 8px;
    left: 0;
}
.smartlinks-url-form .availability[data-is-available="false"]::before{
    transform: rotate(45deg);
}
.smartlinks-url-form .availability[data-is-available="false"]::after{
    transform: rotate(-45deg);
}

.App__Main .smartlinks-url-form .Polaris-Select__Content{
    background: #F4F6F8;
    padding-left: .8rem;
}

/* shrink availability to just the icon */
@media screen and (max-width: 1180px) and (min-width: 1061px),
       screen and (max-width: 770px) and (min-width: 599px),
       screen and (max-width: 575px){
    .smartlinks-url-form .availability{
        position: absolute;
        z-index: 200;
        text-indent: -99999px;
        right: -.5rem;
    }
}

@media screen and (max-width: 650px) and (min-width: 600px),
       screen and (max-width: 520px){
    .smartlinks-url-form{
        flex-direction: column;
        align-items: flex-start
    }

    .smartlinks-url-form .textfield-wrapper{
        margin-bottom: 1rem;
    }
}

/* @media screen and (max-width: 640px) and (min-width: 599px),
       screen and (max-width: 470px){
    .smartlinks-url-form .availability{
        left: 280px;
        top: 1rem;
    }

    .smartlinks-url-form .textfield-wrapper{
        margin-bottom: 1rem;
    }
} */

@media screen and (max-width: 640px){
    .smartlinks-url-form .Polaris-TextField__Input,
    .smartlinks-url-form .Polaris-Select__Content{
        font-size: 1.4rem;
    }
}

/* @media screen and (max-width: 530px){
    .smartlinks-url-form .textfield-wrapper{
        margin-bottom: 1rem;
    }

    .smartlinks-url-form .availability{
        margin-right: 1rem;
        margin-top: -.5rem;
    }
} */

@media screen and (max-width: 470px) and (min-width: 455px){
    .smartlinks-url-form .availability{
        text-indent: 0;
        position: relative;
        right: auto;
    }
}