.smartlinks--custom-theme .custom-theme-heading{
    display: flex;
    justify-content: space-between;
}

.smartlinks--custom-theme .custom-theme-heading button{
    border: none;
    padding: 0;
    text-indent: -9999px;
    width: 24px;
    height: 24px;
    position: relative;
    cursor: pointer;
}

.smartlinks--custom-theme .custom-theme-heading button[disabled]{
    opacity: .5;
    cursor: default;
}

.smartlinks--custom-theme .undo{
    background: url(../../../assets/undo-pink.svg) no-repeat;
    margin-right: 1rem;
}
.smartlinks--custom-theme .redo{
    background: url(../../../assets/undo-pink.svg) no-repeat;
    transform: scaleX(-1)
}

.smartlinks--custom-theme button::after{
    text-indent: 0;
    position: absolute;
    left: 0;
    bottom: -.8rem;
    font-size: .9rem;
    color: #fd2a54;
}

.smartlinks--custom-theme .undo::after{
    content: 'Undo';
}
.smartlinks--custom-theme .redo::after{
    content: 'Redo';
    transform: scaleX(-1);
}

#smartlinks__customize-panel .smartlinks--custom-theme ul{
    margin-bottom: 2rem;
}

.smartlinks--custom-theme li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding: 2rem 0;
}

#smartlinks__customize-panel .smartlinks--custom-theme li{
    margin-bottom: 0;
}

.smartlinks--custom-theme .Polaris-Select{
    min-width: 22rem
}

.smartlinks--custom-theme .color-picker-wrap{
    position: relative;
}

.smartlinks--custom-theme .color-picker-wrap .Polaris-TextField__Input{
    padding-left: 3.8rem;
    max-width: 130px;
}

.smartlinks--custom-theme .color-swatch{
    position: absolute;
    border-radius: 3px;
    border: 1px solid #c4cdd5;
    width: 2.4rem;
    height: 2.4rem;
    z-index: 30;
    top: 6px;
    left: 6px;
}

.smartlinks--custom-theme .chrome-picker{
    position: absolute;
    right: 0;
    top: 4rem;
    z-index: 200;
    margin-bottom: 2rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px !important;
}

/* hide color picker mode selection */
.smartlinks--custom-theme .chrome-picker .flexbox-fix:nth-child(2){
    display: none !important;
}
/* hide swatch circle */
.smartlinks--custom-theme .chrome-picker .flexbox-fix:nth-child(1) > div:nth-child(1){
    display: none !important;
}
/* decrease margin bottom from hue slider */
.smartlinks--custom-theme .chrome-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:nth-child(1){
    margin-bottom: 4px !important;
}
/* round out hue slider */
.smartlinks--custom-theme .chrome-picker .hue-horizontal{
    border-radius: 20px;
}

.smartlinks--custom-theme .Polaris-RangeSlider-SingleThumb__Input{
    --progress-upper: #dedede !important;
    --progress-lower: #33185c !important;
    width: 222px;
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-runnable-track{
    border-radius: .5rem !important;
    height: 1rem !important;
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb{
    margin-top: -.3rem !important;
    background: #fff !important;
    border: 2px solid #33185c !important;
}

.smartlinks--custom-theme .llama-button:first-child{
    margin-left: auto;
}

.Polaris-Modal-Footer .Polaris-Button--primary.Polaris-Button--disabled {
    background: rgba(253,42,84,1);
    border: none;
    box-shadow: none;
    opacity: .5;
}

@media screen and (max-width: 1050px) and (min-width: 981px),
       screen and (max-width: 830px) and (min-width: 781px),
       screen and (max-width: 650px) and (min-width: 600px),
       screen and (max-width: 435px) and (min-width: 421px){
    .smartlinks--custom-theme .Polaris-RangeSlider-SingleThumb__Input{
        width: 150px;
    }
}

@media screen and (max-width: 420px){
    .smartlinks--custom-theme ul{
        margin-top: 0;
    }
    /*.smartlinks--custom-theme .custom-theme-heading,*/
    .smartlinks--custom-theme li{
        flex-direction: column;
        align-items: flex-start;
    }

    .smartlinks--custom-theme li label{
        margin-bottom: .8rem;
    }
}