.layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
}

.layout__main {
    width: 100%;
}

@media screen and (max-width: 599px) {

    .layout {
        flex-direction: column;
        width: 100%;
    }

    .layout__sidebar {
        width: 100%;
    }

    .layout__header {
        display: none;
    }

    /* .layout__notifications {
        display: none;
    } */

}