.offer-detail__offer-wrapper{
    display: flex;
}

.offer-detail__offer-wrapper aside + div{
    width: calc(100% - 30rem);
}

@media screen and (max-width: 999px){
    .offer-detail__offer-wrapper aside + div{
        width: calc(100% - 25rem);
    }
}

@media screen and (max-width: 930px){
    .offer-detail__offer-wrapper{
        display: block;
    }
    .offer-detail__offer-wrapper aside + div{
        width: 100%;
    }
}