#smartlinks__customize-panel .custom-url{
    position: relative;
}

#smartlinks__customize-panel .custom-url .Polaris-Spinner{
    position: absolute;
    right: 0;
    top: 3.2rem;
    right: 1rem;
    display: block;
    z-index: 30;
}

#smartlinks__customize-panel .availability{
    margin-top: .5rem;
    font-weight: bold;
    position: relative;
    display: block;
}

#smartlinks__customize-panel .availability[data-is-available="true"]{
    color: #009e22;
    padding-left: 1.6rem;
}
#smartlinks__customize-panel .availability[data-is-available="true"]::before{
    content: '';
    width: 11px;
    height: 6px;
    border-left: 2px solid #009e22;
    border-bottom: 2px solid #009e22;
    transform: rotate(-45deg);
    position: absolute;
    top: 5px;
    left: 0;
}

#smartlinks__customize-panel .availability[data-is-available="false"]{
    color: #df0000;
    padding-left: 1.8rem;
}
#smartlinks__customize-panel .availability[data-is-available="false"]::before,
#smartlinks__customize-panel .availability[data-is-available="false"]::after{
    content: '';
    width: 12px;
    height: 2px;
    background: #df0000;
    position: absolute;
    top: 8px;
    left: 0;
}
#smartlinks__customize-panel .availability[data-is-available="false"]::before{
    transform: rotate(45deg);
}
#smartlinks__customize-panel .availability[data-is-available="false"]::after{
    transform: rotate(-45deg);
}