.smartlinks .tab-content .smartlink--plans .heading{
    text-align: center;
    font-size: 2.2rem;
    margin-bottom: 2rem;
}

.smartlinks .tab-content .smartlink--plans .info{
    text-align: center;
    font-size: 1.8rem;
}

.smartlink--plan-definitions{
    display: flex;
    align-items: center;
    font-size: 10px
}

.smartlink--plan-definitions .smartlink--plan-pro{
    border-color: #5d6cc1;
    margin-left: -1.2rem;
}

.smartlink--plan-definitions figure{
    background: #fafafa;
    border: 2px solid #e5e5e5;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(1, 2, 1, .13);
    padding: 3rem 4rem;
    width: 50%;
    margin: 0;
}

.smartlink--plan-definitions figcaption{
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 2.3rem;
}

.smartlink--plan-definitions figcaption span{
    font-size: .5em;
    font-weight: normal;
    vertical-align: middle;
    background: #dcdff2;
    padding: .4rem 1.8rem .4rem 3rem;
    border-radius: 2rem;
    margin-left: 1rem;
    position: relative;
    display: inline-block;
}

.smartlink--plan-definitions figcaption span::before,
.smartlink--plan-definitions li::before{
    content: '';
    position: absolute;
    width: 1rem;
    height: 0.5rem;
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    transform: rotate(-45deg);
    left: 1.5rem;
    top: 1rem;
}

.smartlink--plan-definitions .plan-info{
    font-size: 1.8em;
    line-height: 1.3em;
    color: #637381;
    margin-bottom: 1.5em;
}

.smartlink--plan-definitions .price{
    font-size: 5em;
    font-weight: bold;
    margin-bottom: .7em;
    position: relative;
    padding-left: 1.6rem;
    line-height: .8em;
}

.smartlink--plan-definitions .currency-symbol{
    font-size: 0.5em;
    position: absolute;
    top: -6px;
    left: 0;
}

.smartlink--plan-definitions .currency{
    font-size: 0.5em;
}

.smartlink--plan-definitions .feature-intro{
    font-size: 1.8em;
    font-weight: bold;
    line-height: 1.3em;
}

.smartlink--plan-definitions ul{
    padding-left: 0;
    list-style: none;
    font-size: 1.4em;
}

.smartlink--plan-definitions li{
    position: relative;
    padding-left: 2.2rem;
    color: #54626e;
    margin-bottom: 1.2rem;
    line-height: 1.3em;
}

.smartlink--plan-definitions li::before{
    border-color: #637381;
    left: 0;
    top: .4rem;
}

.smartlink--plan-definitions .cta{
    margin-top: 4rem;
}

.smartlink--plan-definitions .cta button{
    margin: 0 auto;
    font-size: 1.8em;
    font-weight: bold;
    padding: 1.1rem 4rem;
}

.smartlink--plan-expiry {
    color: red;
    font-size: 1.4em;
    line-height: 1.3em;
    margin-top: -1.5rem;
    margin-bottom: 2.5rem;
}

@media screen and (max-width: 1625px) and (min-width: 1331px),
       screen and (max-width: 1160px) and (min-width: 971px),
       screen and (max-width: 690px) and (min-width: 600px),
       screen and (max-width: 490px){
    .smartlink--plan-free figcaption{
        display: flex;
        flex-direction: column;
    }
    .smartlink--plan-free figcaption span{
        margin-left: 0;
        margin-top: 1rem;
        margin-right: auto;
    }
}

@media screen and (max-width: 1580px) and (min-width: 1331px),
       screen and (max-width: 1140px) and (min-width: 971px),
       screen and (max-width: 680px) and (min-width: 600px),
       screen and (max-width: 480px){
    .smartlink--plan-pro figcaption{
        display: flex;
        flex-direction: column;
    }
    .smartlink--plan-pro figcaption span{
        margin-left: 0;
        margin-top: 1rem;
        margin-right: auto;
    }
}

@media screen and (max-width: 1460px){
    .smartlink--plan-definitions figure{
        padding: 3rem;
    }
}

@media screen and (max-width: 1400px) and (min-width: 1331px), screen and (max-width: 1069px){
    .smartlinks .tab-content .smartlink--plans .heading{
        font-size: 2rem;
    }
    .smartlinks .tab-content .smartlink--plans .info{
        font-size: 1.6rem;
    }
    .smartlink--plan-definitions{
        font-size: 9px;
    }
}

@media screen and (max-width: 970px){
    .smartlink--plan-definitions{
        flex-direction: column;
    }
    .smartlink--plan-definitions figure{
        width: 100%;
        margin-bottom: 2rem;
    }

    .smartlink--plan-definitions .smartlink--plan-free{
        order: 1;
    }

    .smartlink--plan-definitions .smartlink--plan-pro{
        margin-left: 0;
    }
}

@media screen and (max-width: 710px){
    .smartlinks .tab-content .smartlink--plans .info{
        text-align: left;
    }
}

@media screen and (max-width: 450px){
    .smartlink--plan-definitions .price{
        font-size: 4.5em;
    }
}

@media screen and (max-width: 380px){
    .smartlink--plan-definitions .currency{
        display: block;
    }
}