#become-ambassador{
    --fs-title: 4rem;
    --fs-subheading: calc(var(--fs-title) / 1.6); /* 2.5rem; */
    --fs-heading: 4.5rem;
    --fs-body: calc(var(--fs-heading) / 1.9); /* 2.3rem; */
    --fs-button: calc(var(--fs-body) * 1.17); /* 2.7rem; */
    --inner-spacing: 4rem;
    --section-spacing: 12rem;
    --content-width: 82rem;
    --paragraph-spacing: 2.6rem;

    margin-bottom: -11rem;
    margin-top: 5rem;
}

#become-ambassador * {
    line-height: 1.3em;
}

#become-ambassador .llama-button{
    font-size: var(--fs-button);
    font-weight: bold;
    padding: 2rem 5.5rem;
    margin: 0 auto;
}

#become-ambassador section p{
    font-size: var(--fs-body);
}

#become-ambassador section h2,
#become-ambassador section h3{
    font-size: var(--fs-heading);
    font-weight: bold;
}

#become-ambassador .intro{
    text-align: center;
    max-width: var(--content-width);
    padding: 0 3rem;
    margin: 0 auto;
    margin-bottom: calc(var(--section-spacing) - 5rem);
}

#become-ambassador h1{
    font-size: var(--fs-title);
    font-weight: bold;
}

#become-ambassador .subheading{
    color: var(--llama-subdued);
    font-size: var(--fs-subheading);
    font-weight: normal;
    display: block;
    margin-bottom: var(--inner-spacing);
}

#become-ambassador video{
    width: 100%;
    max-width: 69rem;
    margin-bottom: var(--inner-spacing);
    cursor: pointer;
}

#become-ambassador .intro p{
    margin-bottom: var(--inner-spacing);
}

#become-ambassador .ltv{
    background: url(../../assets/become-ambassador/ltv-top.svg) no-repeat center top,
    url(../../assets/become-ambassador/ltv-bottom.svg) no-repeat center bottom;
    background-size: 100%, 100%;
    padding-top: calc(117 / 1324 * (100vw - 20rem));
    padding-bottom: calc(167 / 1242 * (100vw - 20rem));
    margin-bottom: calc(var(--section-spacing) - 8rem);
}

#become-ambassador .ltv .content{
    background: #522A97;
    color: #fff;
    text-align: center;
    padding: 8.2rem 3rem 10rem;
}

#become-ambassador .ltv .charts{
    margin-top: -8rem;
    margin-bottom: var(--inner-spacing);
}

#become-ambassador .ltv .charts img{
    max-width: 95%;
}

#become-ambassador .ltv .description{
    max-width: var(--content-width);
    margin: 0 auto;
    text-align: left;
}

#become-ambassador .ltv .description p:not(:last-child){
    margin-bottom: var(--paragraph-spacing);
}

#become-ambassador .subfeature{
    display: flex;
    margin-bottom: var(--section-spacing);
    align-items: center;
    gap: 10%;
}

#become-ambassador .subfeature h3 {
    color: var(--llama-purple);
    margin-bottom: var(--paragraph-spacing);
}

#become-ambassador .subfeature .content p {
    margin-bottom: var(--paragraph-spacing);
}

#become-ambassador .search{
    --graphic-width: 59.9rem;
}

#become-ambassador .search .content{
    width: min(55rem, calc(100% - var(--graphic-width)));
    padding-right: 3rem;
}

#become-ambassador .subfeature img{
    width: var(--graphic-width);
}

#become-ambassador .smartlinks{
    flex-direction: row-reverse;
    --graphic-width: 52rem;
}

#become-ambassador .smartlinks .content{
    width: min(58.6rem, calc(100% - var(--graphic-width)));
    padding-left: 3rem;
}

#become-ambassador .outro{
    background: #522A97;
    color: #fff;
    text-align: center;
    padding: 6.8rem 2rem;
}

#become-ambassador .outro p,
#become-ambassador .outro h3{
    margin-bottom: var(--inner-spacing);
}

#become-ambassador .outro p:not(:last-child){
    max-width: 101.7rem;
    margin: 0 auto var(--inner-spacing);
}

/* smaller gap in subfeatures */
@media screen and (max-width: 1518px){
    #become-ambassador .subfeature{
        gap: 4rem;
    }
}

/* smaller font sizes */
@media screen and (max-width: 1440px){
    #become-ambassador{
        --fs-title: 3.5rem;
        --fs-heading: 4rem;
    }
}

@media screen and (max-width: 1310px){
    #become-ambassador{
        --fs-heading: 3.5rem;
        --paragraph-spacing: 2.2rem;
    }

    #become-ambassador .subfeature{
        --graphic-width: 40rem;
        padding: 0;
    }

    #become-ambassador .search .content{
        width: calc(100% - var(--graphic-width));
    }

    #become-ambassador .smartlinks .content{
        width: calc(100% - var(--graphic-width));
    }
}

@media screen and (max-width: 1200px){
    #become-ambassador{
        --inner-spacing: 3rem;
    }
}

@media screen and (max-width: 1020px){
    #become-ambassador{
        --fs-title: 3.2rem;
        --fs-heading: 3.2rem;
        --paragraph-spacing: 2rem;
    }

    #become-ambassador .subfeature{
        --graphic-width: 30rem;
    }
}

/* decrease negative offset for chart */
@media screen and (max-width: 1005px){
    #become-ambassador .ltv .charts{
        margin-top: -4rem;
    }

    #become-ambassador .ltv .charts img{
        max-width: 100%;
    }
}

/* 1 column subfeatures */
@media screen and (max-width: 870px){
    #become-ambassador{
        --fs-title: 3.2rem;
        --fs-heading: 3.2rem;
        --paragraph-spacing: 2rem;
        --section-spacing: 7rem;
    }

    #become-ambassador .subfeature{
        flex-direction: column;
    }

    #become-ambassador .subfeature .graphic{
        width: 100%;
    }

    #become-ambassador .subfeature .content{
        width: 100%;
        padding: 0 3rem;
    }

    #become-ambassador .search .graphic{
        align-self: flex-start;
    }
    
    #become-ambassador .smartlinks .graphic{
        align-self: flex-end;
        text-align: right;
    }

    #become-ambassador .subfeature{
        --graphic-width: 60%;
    }

    #become-ambassador .intro{
        margin-bottom: calc(var(--section-spacing) - 2rem);
    }

    #become-ambassador .outro{
        padding: 4.5rem 2rem;
    }
}

/* remove negative offset for chart */
@media screen and (max-width: 730px){
    #become-ambassador .ltv .charts{
        margin-top: var(--inner-spacing);
    }
}

@media screen and (max-width: 630px) and (min-width: 600px),
       screen and (max-width: 420px){
    #become-ambassador .subfeature{
        --graphic-width: 80%;
    }
}

/* remove nav width from calc for ltv borders */
@media screen and (max-width: 599px){
    #become-ambassador .ltv{
        padding-top: calc(117 / 1324 * 100vw);
        padding-bottom: calc(167 / 1242 * 100vw);
    }
}

/* smaller fonts */
@media screen and (max-width: 420px){
    #become-ambassador{
        --fs-title: 3rem;
        --fs-heading: 2.9rem;
        --section-spacing: 4rem;
    }

    #become-ambassador .intro,
    #become-ambassador .ltv{
        margin-bottom: var(--section-spacing);
    }

    #become-ambassador .ltv .content{
        padding: 3.2rem 3rem 4rem;
    }

    #become-ambassador .llama-button{
        padding: 2rem 0;
        width: 100%;
    }
}